import React, {Component} from 'react';
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";
import {SelectBox, TagBox, Button} from "devextreme-react";
import LDH from "../helpers/LeopardDataHelper";

class LeopardMenuConfigItemBox extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.interactiveFeatures = [{
            id: "rowlink",
            name: "Row Link"
        }];
    }

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeConfigItemBox = () => {
        let that = this;
        let clonedRelationship = LDH.DeepClone(this.props.relationships);
        let relationshipsWithColor = LDH.SetRelationshipColorBar(clonedRelationship);

        return this.props.relationships.map(function (item, i) {
            let color = null;
            let level = null;
            for (let v = 0; v < relationshipsWithColor.length; v++) {
                if (relationshipsWithColor[v].relationshipId === item.relationshipId &&
                    !LDH.IsObjectNull(relationshipsWithColor[v].color)) {
                    color = relationshipsWithColor[v].color;
                    level = relationshipsWithColor[v].level;
                }
            }

            return (
                <div className={"leopard-menuoption-configbox-container"} key={item.relationshipId}
                     style={{marginBottom: "15px"}} relationshipid={item.relationshipId}>
                    {
                        level === null ? "" : <div className={"leopard-level-bar"} style={{
                            backgroundColor: color
                        }}>Level {level}</div>
                    }
                    <div className="leopard-option-panel-block">
                        <div>
                            <span>Select a data view as Parent</span>
                            <span style={{marginLeft: "5px"}}>
                          <LeopardTooltipWithIcon
                              elementId={"selectDataViewAsParent_" + i + "_help"}
                              title={"Select a data view as Parent"}
                              text={"Select a data view in the workspace as a Parent View."}/>
                          </span>
                        </div>
                        <div>
                            <SelectBox dataSource={that.props.dataSource} className={"leopard-grouped-selectbox"}
                                       displayExpr={'selectedValue'} valueExpr={'combinedId'}
                                       disabled={that.props.showLoadingProgress}
                                       defaultValue={item.parentDataViewId}
                                       id={"selectDataViewAsParent_" + i}
                                       ref={(e) => that.props.setInputFieldInstance({
                                           e, input: "selectDataViewAsParent_" + i
                                       })}
                                       onValueChanged={(e) => that.props.configItemBoxValueOnChange({
                                           e, index: i, item, inputField: "parent",
                                           instanceName: "selectDataViewAsParent_" + i
                                       })}>
                            </SelectBox>
                        </div>
                    </div>
                    <div className="leopard-option-panel-block">
                        <div>
                            <span>Select a data view as Child</span>
                            <span style={{marginLeft: "5px"}}>
                          <LeopardTooltipWithIcon
                              elementId={"selectDataViewAsChild_" + i + "_help"}
                              title={"Select a data view as Child"}
                              text={"Select a data view in the workspace as a Child View."}/>
                          </span>
                        </div>
                        <div>
                            <SelectBox dataSource={that.props.dataSource} className={"leopard-grouped-selectbox"}
                                       displayExpr={'selectedValue'} valueExpr={'combinedId'}
                                       disabled={that.props.showLoadingProgress}
                                       defaultValue={item.childDataViewId}
                                       id={"selectDataViewAsChild_" + i}
                                       ref={(e) => that.props.setInputFieldInstance({
                                           e, input: "selectDataViewAsChild_" + i
                                       })}
                                       onValueChanged={(e) => that.props.configItemBoxValueOnChange({
                                           e, index: i, item, inputField: "child",
                                           instanceName: "selectDataViewAsChild_" + i
                                       })}>
                            </SelectBox>
                        </div>
                    </div>
                    <div className="leopard-option-panel-block">
                        <div>
                            <span>Interactive features</span>
                            <span style={{marginLeft: "5px"}}>
                          <LeopardTooltipWithIcon
                              elementId={"interactiveFeatures_" + i + "_help"}
                              title={"Interactive features"}
                              text={"Select interactive features between the Parent and Child View."}/>
                          </span>
                        </div>
                        <div>
                            <TagBox dataSource={that.interactiveFeatures}
                                    showSelectionControls={true} displayExpr={'name'}
                                    valueExpr={'id'}
                                    className={"leopard-grouped-selectbox"}
                                    id={"interactiveFeatures_" + i} multiline={false}
                                    value={item.interactiveFeatures}
                                    maxDisplayedTags={2} showMultiTagOnly={false}
                                    disabled={that.props.showLoadingProgress}
                                    onValueChanged={(e) => that.props.configItemBoxValueOnChange({
                                        e, index: i, item, inputField: "features"
                                    })}
                                    applyValueMode={'useButtons'}/>
                        </div>
                    </div>
                    <div className={"leopard-option-panel-block error panel_error_" + item.relationshipId}
                         style={{display: "none"}}>
                        Circular relationship detected
                    </div>
                    <div style={{marginTop: "10px", textAlign: "right"}}>
                        <span>
                            <Button className="leopard-button" text={'Delete'} disabled={that.props.showLoadingProgress}
                                    onClick={(e) => that.props.onDelete({e, index: i, item})}/>
                         </span>
                    </div>
                </div>
            );
        })
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return <React.Fragment>{this.initializeConfigItemBox()}</React.Fragment>;
    }
}

export default LeopardMenuConfigItemBox;
