import React from 'react';
import {Column} from 'devextreme-react/data-grid';
import {Button} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import {HeaderFilter} from 'devextreme-react/tree-list';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardDesignerCountdownSwitch from '../datashaping/LeopardDesignerCountdownSwitch';
import LeopardTooltipWithLink from "./LeopardTooltipWithLink";
import LRH from "../helpers/LeopardReactHelper";
import LPH from "../helpers/LeopardPermissionHelper";

export const LeopardGridViewColumnBuilder = (gridDefinition, columnFieldList, thisComp) => {
    let columnComponent = [];
    if (columnFieldList === undefined || columnFieldList === null || columnFieldList.length === 0) {
        return null;
    }
    for (let colIndex = 0; colIndex < columnFieldList.length; colIndex++) {
        let columnName = columnFieldList[colIndex];
        let columnDef = null;

        columnDef = gridDefinition.columnDefinition.filter(c => {
            return c.columnName === columnName;
        });
        if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
            columnDef = columnDef[0];
        }

        let isCustomColumn = false;
        if (!LDH.IsObjectNull(gridDefinition.customComputedColumns)) {
            let customColumns = gridDefinition.customComputedColumns;
            for (let v = 0; v < customColumns.length; v++) {
                let cName = customColumns[v].name.toLowerCase().trim();
                if (cName === columnName.toLowerCase().trim()) {
                    isCustomColumn = true;
                    break;
                }
            }
        }

        let columnType = "string";
        if (columnDef !== null && columnDef.columnType !== undefined &&
            columnDef.columnType !== null && columnDef.columnType !== "") {
            columnType = columnDef.columnType;
        }

        let columnCustomHeader = columnDef.columnName;
        if (columnDef !== null && columnDef.columnCustomHeader !== undefined &&
            columnDef.columnCustomHeader !== null && columnDef.columnCustomHeader !== "") {
            columnCustomHeader = columnDef.columnCustomHeader;
        }

        let allowSorting = !isCustomColumn;
        if (columnDef !== null && columnDef.allowSorting !== undefined && !isCustomColumn &&
            columnDef.allowSorting !== null && columnDef.allowSorting !== "") {
            allowSorting = columnDef.allowSorting;
        }

        let allowFiltering = !isCustomColumn;
        if (columnDef !== null && columnDef.allowFiltering !== undefined && !isCustomColumn &&
            columnDef.allowFiltering !== null && columnDef.allowFiltering !== "") {
            allowFiltering = columnDef.allowFiltering;
        }

        let allowGrouping = !isCustomColumn;
        if (columnDef !== null && columnDef.allowGrouping !== undefined && !isCustomColumn &&
            columnDef.allowGrouping !== null && columnDef.allowGrouping !== "") {
            allowGrouping = columnDef.allowGrouping;
        }

        let allowReordering = true;
        if (columnDef !== null && columnDef.allowReordering !== undefined &&
            columnDef.allowReordering !== null && columnDef.allowReordering !== "") {
            allowReordering = columnDef.allowReordering;
        }

        let allowResizing = true;
        if (columnDef !== null && columnDef.allowResizing !== undefined &&
            columnDef.allowResizing !== null && columnDef.allowResizing !== "") {
            allowResizing = columnDef.allowResizing;
        }

        let isFixed = false;
        if (columnDef !== null && columnDef.isFixed !== undefined &&
            columnDef.isFixed !== null && columnDef.isFixed !== "") {
            isFixed = columnDef.isFixed;
        }

        let columnAlign = "left";
        if (columnDef !== null && columnDef.columnAlign !== undefined &&
            columnDef.columnAlign !== null && columnDef.columnAlign !== "") {
            columnAlign = columnDef.columnAlign;
        }

        let minimumWidth = "50";
        if (columnDef !== null && columnDef.minimumWidth !== undefined &&
            columnDef.minimumWidth !== null && columnDef.minimumWidth !== "") {
            minimumWidth = columnDef.minimumWidth;
        }

        let width = null;
        if (columnDef !== null && columnDef.width !== undefined &&
            columnDef.width !== null && columnDef.width !== "") {
            width = columnDef.width;
        }

        let groupIndex = null;
        if (columnDef !== null && columnDef.groupIndex !== undefined &&
            columnDef.groupIndex !== null && columnDef.groupIndex !== "") {
            groupIndex = columnDef.groupIndex;
        }

        let allowHeaderFilter = !isCustomColumn;
        if (columnDef !== null && columnDef.allowHeaderFilter !== undefined && !isCustomColumn &&
            columnDef.allowHeaderFilter !== null && columnDef.allowHeaderFilter !== "") {
            allowHeaderFilter = columnDef.allowHeaderFilter;
        }

        let sortOrder = null;
        if (columnDef !== null && columnDef.sortOrder !== undefined &&
            columnDef.sortOrder !== null && columnDef.sortOrder !== "") {
            sortOrder = columnDef.sortOrder;
        }

        let filterOperations = [];
        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForString;
        } else if (columnType === "guid") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForString;
        } else if (columnType === "number") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "date") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "datetime") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "boolean") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForBoolean;
        }
        if (columnDef !== null && columnDef.filterOperations !== undefined &&
            columnDef.filterOperations !== null && columnDef.filterOperations !== "") {
            filterOperations = columnDef.filterOperations;
        }

        let hidingPriority = columnFieldList.length - (colIndex + 1);
        if (gridDefinition.adaptiveColumn === undefined ||
            gridDefinition.adaptiveColumn === null ||
            gridDefinition.adaptiveColumn === false) {
            hidingPriority = null;
        }

        let cssClass = "leopard_gridview_column_template";
        if (allowSorting === true) {
            cssClass += " allow-sorting";
        } else {
            cssClass += " disallow-sorting";
        }

        let domain = LDH.APIEndpointAdapter();
        let url = thisComp.props.GetDataFromUrl.replace("?tableName=", "");

        if (columnType === "date") {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        format="dd/MM/yyyy" cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        sortOrder={sortOrder} hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true} dataSource={
                        LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)
                    }/>
                </Column>
            );
        } else if (columnType === "datetime") {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        format="dd/MM/yyyy HH:mm" cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        sortOrder={sortOrder} hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true} dataSource={
                        LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)
                    }/>
                </Column>
            );
        } else {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        sortOrder={sortOrder} hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true} dataSource={
                        LRH.InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition)
                    }/>
                </Column>
            );
        }
    }

    return columnComponent;
};

export const LeopardGridViewToolbar =
    ({
         addRowButtonOnClick, clearFilterButtonOnClick, viewOptionsButtonOnClick, minHeight,
         gridViewId, exportButtonOnClick, allowEditing, refreshButtonOnClick, applyFilterButtonOnClick,
         showAddButton, explicitFilterOption, gridDefinition, autoRefreshInterval, enableAutoRefresh,
         autoRefreshCountdownOnEnd, showAutoRefreshSwitch, importButtonOnClick, importDataCallback
     }) => {
        let hideAddRowButton = LPH.IsEnableAdminUserOnlyForCommandLink(gridDefinition, "add-row");

        return (
            <div className={"leopard-gridview-toolbar"} style={{minHeight}}>
                {
                    enableAutoRefresh === false ? "" :
                        <span id={"autoRefresh_" + gridViewId} className={"leopard-autorefresh-button_wrapper"}
                              style={{display: showAutoRefreshSwitch ? "block" : "none"}}>
                         <div id={"autoRefreshCountdownControl_" + gridViewId}>
                            <LeopardDesignerCountdownSwitch
                                autoRefreshCountdownOnEnd={autoRefreshCountdownOnEnd}
                                tooltip={"The grid view will be refreshed automatically when timer counts down to 0."}
                                autoRefreshInterval={autoRefreshInterval}
                                fieldValue={enableAutoRefresh} gridViewId={gridViewId}/>
                         </div>
                    </span>
                }
                <span id={"gridViewToobar_" + gridViewId} className="leopard-gridview-dataloading">
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "rgb(255, 128, 0)", fontSize: "18px"
                    }}></i>
                    <span className={"toolbar-warming-up-text"}>Warming up backend process...</span>
                </span>
                <span style={{padding: "0 2px 0 0", float: "right"}}>
                     <LeopardTooltipWithLink
                         elementId={"GridView_TopBar_ClearFilter_" + gridViewId}
                         labelText={"Reset filter"} width={250} title={"Reset Filter"}
                         onClick={(e) => clearFilterButtonOnClick({e, gridViewId})}
                         text={"The Reset Filter button allows you to reset the filter criteria typed in the gridview's filter boxes."}/>
                </span>
                {
                    explicitFilterOption === "auto" ? "" :
                        <span style={{padding: "0 2px 0 0", float: "right"}}>
                            <LeopardTooltipWithLink elementId={"GridView_TopBar_ApplyFilter_" + gridViewId}
                                                    labelText={"Apply filter"}
                                                    width={250} title={"Apply Filter"}
                                                    onClick={(e) => applyFilterButtonOnClick({
                                                        e,
                                                        gridViewId
                                                    })}
                                                    text={"The Apply Filter button allows you to apply the filter criteria typed in the data view's filter boxes."}/>
                        </span>
                }
                <span style={{padding: "0 2px 0 0", float: "right"}}>
                    <LeopardTooltipWithLink elementId={"GridView_TopBar_Refresh_" + gridViewId} labelText={"Refresh"}
                                            width={250} title={"Refresh"}
                                            onClick={(e) => refreshButtonOnClick({
                                                e,
                                                gridViewId
                                            })}
                                            text={"The Refresh button allows you to refresh data and repaint the data view."}/>
                </span>
                {
                    !LDH.IsObjectNull(gridDefinition.urlForDataImportField) && !LDH.IsValueEmpty(gridDefinition.urlForDataImportField) &&
                    window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin ?
                        <span>
                           <span style={{padding: "0 2px 0 2px", float: "right"}}>
                            <LeopardTooltipWithLink elementId={"GridView_TopBar_Import_" + gridViewId}
                                                    labelText={"Import"}
                                                    width={350} title={"Import"}
                                                    onClick={(e) => importButtonOnClick({e, gridViewId})}
                                                    text={"You can import data from a CSV file to the grid view."}/>
                            </span>
                            <input type={"file"} accept={".csv"} id={"GridView_TopBar_ImportWidget_" + gridViewId}
                                   style={{display: "none"}} onChange={
                                (e) => LDH.ReadTextFile(e, function (data) {
                                    importDataCallback({data});
                                })}
                            />
                       </span> : ""
                }
                {
                   gridDefinition.enablePagination && gridDefinition.optimizePagerForLargeDataset === false ?
                        <span style={{padding: "0 2px 0 2px", float: "right"}}>
                            <LeopardTooltipWithLink
                                elementId={"GridView_TopBar_Export_" + gridViewId}
                                labelText={"Export"} width={350} title={"Export"}
                                onClick={(e) => exportButtonOnClick({
                                    e,
                                    gridViewId
                                })}
                                text={"The data view makes it easy to export your data to an Excel file. Data is exported as it is " +
                                "displayed in the data view: sorting, filtering and grouping are applied."}/>
                        </span> : ""
                }
                <span style={{padding: "0 2px 0 2px", float: "right"}}>
                    <LeopardTooltipWithLink
                        elementId={"GridView_TopBar_ViewOptions_" + gridViewId}
                        labelText={"Columns"} width={250} title={"Columns"}
                        onClick={(e) => viewOptionsButtonOnClick({
                            e,
                            gridViewId
                        })}
                        text={"This feature allows a user to hide or show columns in grid view at runtime."}/>
                </span>
                {
                    allowEditing === false || showAddButton === false || hideAddRowButton ? "" :
                        <span style={{padding: "0 2px 0 2px", float: "right"}}>
                            <Button text={'Add Row'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_AddRecord_" + gridViewId}
                                    onClick={(e) => addRowButtonOnClick({e, gridViewId})}/>
                        </span>
                }
                <div className={"drag-and-drop-selection-count"} id={gridViewId + "_DragAndDropSelectionCount"}></div>
            </div>
        );
    };
