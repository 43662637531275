import React, {Component} from 'react';
import {Button, Popup, TileView} from 'devextreme-react';
import TabPanel from 'devextreme-react/tab-panel';
import $ from "jquery";
import LRH from "../helpers/LeopardReactHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LDH from "../helpers/LeopardDataHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardAttachmentPopup extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            tileViewItems: [],
            popupVisible: false
        };

        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
    }

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    tileViewItemImageOnClick = (data) => {
        $(".leopard-tileviewitem-image").removeClass("selected");
        $("." + data.id).addClass("selected");
        $(".leopard-photogallery-enlarged-image").attr("src", data.originalImageUrl);
    };

    photoGalleryImageOnError = () => {
        LRH.ShowToast("Your request has expired or photo could not be found, " +
            "please close this window and try again.", "error", 5000);
    };

    photoGalleryFullScreenOnClick = () => {
        window.open($(".leopard-photogallery-enlarged-image").attr("src"));
    };

    addUIObjectInstance = (data) => {
        this.uiObjectInstances[data.name] = data.instance;
    };

    popupOnHide = () => {
        this.setState({popupVisible: false, tileViewItems: []});
        $(".leopard-photogallery-enlarged-image").removeAttr("src");
        $(".leopard-photocallery-container-nophoto").hide();
    };

    popupOnShowing = () => {
        $(".leopard-popupwindow-loading-progress").show();
    };

    popupOnShown = () => {
        let that = this;
        let sourceId = LeopardStaticUIConfig.Global_PopupCustomColumnData.data.dataSourceId;
        let itemId = LeopardStaticUIConfig.Global_PopupCustomColumnData.e.row.data[sourceId];

        LeopardAjaxHelper.GetAttachmentsByItemId(itemId, true, function (result) {
            let items = LDH.GetImageListFromResult(result, itemId);

            if (items.length === 0) {
                $(".leopard-popupwindow-loading-progress").hide();
                $(".leopard-photocallery-container-nophoto").show();
            } else {
                that.setState({tileViewItems: items}, function () {
                    setTimeout(function () {
                        let firstItem = that.state.tileViewItems[0];
                        let firstOriginalUrl = firstItem.originalImageUrl;
                        $(".leopard-photogallery-enlarged-image").attr("src", firstOriginalUrl);
                        $(".leopard-photogallery-downloadimage-url").attr("url", firstOriginalUrl);
                        $(".leopard-photogallery-downloadimage-url").attr("itemid", itemId);
                        $(".leopard-popupwindow-loading-progress").hide();
                        $(".leopard-photocallery-container-nophoto").hide();
                    }, 100);
                });
            }
        }, function () {
            LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
            $(".leopard-popupwindow-loading-progress").hide();
            $(".leopard-photocallery-container-nophoto").show();
        });
        if (that.instanceInitialized === false) {
            that.instanceInitialized = true;
            LRH.AddDisposablePopupInstances(that);
        }
    };

// =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializePhotoGalleryTileViewItem = (data) => {
        let isSelected = "";
        if (data.id === this.state.tileViewItems[0].id) {
            isSelected = "selected";
        }
        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"} style={{width: "100%", height: "100%", padding: "5px"}}
                     onClick={() => this.tileViewItemImageOnClick({
                         originalImageUrl: data.originalImageUrl, id: data.id
                     })}>
                    <div className={data.id + " leopard-tileviewitem-image " + isSelected}
                         style={{backgroundImage: `url(${data.imageUrl})`}}/>
                </div>
            </React.Fragment>
        );
    };

    initializePhotoGalleryViewContainer() {
        return (
            <div style={{display: "flex", height: "565px"}}>
                <div className={"leopard-photocallery-container"}>
                    <TileView items={this.state.tileViewItems} width={210} height={"100%"}
                              baseItemHeight={140} direction={"vertical"} baseItemWidth={210}
                              itemMargin={0} showScrollbar={false}
                              ref={(e) => this.addUIObjectInstance({name: "tileview", instance: e})}
                              itemComponent={this.initializePhotoGalleryTileViewItem}>
                    </TileView>
                </div>
                <div style={{width: "100%", padding: "10px 10px 5px 5px"}}>
                    <img className={"leopard-photogallery-enlarged-image"}
                         onError={(e) => this.photoGalleryImageOnError(e)}
                         alt={"Attachments"}/>
                         <div className={"leopard-photocallery-container-largephoto-text"}>
                             Right click the photo to copy or download
                         </div>
                </div>
            </div>
        );
    }

    initializePhotoGalleryAccessPanel = () => {
        return (
            <div style={{padding: "10px 0px 10px 5px", clear: "both", height: "46px"}}>
                <span style={{padding: "0px 10px 0 0", float: "right"}}>
                    <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Full Screen"}
                            ref={(e) => this.addUIObjectInstance({name: "buttonFullScreen", instance: e})}
                            onClick={(e) => this.photoGalleryFullScreenOnClick(e)}>
                    </Button>
                </span>
            </div>
        );
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        if (data.e.tabId === 0) {
            return (
                <React.Fragment>
                    <div className={"leopard-popupwindow-loading-progress"}>
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                    </div>
                    <div className={"leopard-photocallery-container-nophoto"}>
                        <div style={{textAlign: "center", height: "100%", marginTop: "25%", fontSize: "14px"}}>
                            Sorry, there are no photos attached to this item.
                        </div>
                    </div>
                    {this.initializePhotoGalleryViewContainer()}
                    {this.initializePhotoGalleryAccessPanel()}
                </React.Fragment>
            );
        } else if (data.e.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{height: "611px"}}>
                        <div style={{padding: "10px"}}>
                            This feature is currently under development.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };


    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        if (this.disposingAllInstances) return null;
        return (
            <Popup className={'leopard-popup-window'} visible={this.state.popupVisible} dragEnabled={true}
                   closeOnOutsideClick={false} onHiding={this.popupOnHide} onShown={this.popupOnShown}
                   showTitle={true} title={this.props.popupTitle} width={this.props.popupWidth}
                   ref={this.props.popupPhotoGalleryInstance} height={this.props.popupHeight}
                   onShowing={this.popupOnShowing}>
                <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionAttachmentTabPanel}
                          animationEnabled={true} swipeEnabled={false} showNavButtons={true}
                          itemTitleRender={this.tabItemTitleRender}
                          itemComponent={(e) => this.tabItemContent({e})}>
                </TabPanel>
            </Popup>
        )
    }
}

export default LeopardAttachmentPopup;
