import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import momenttz from 'moment-timezone';
import DataSource from 'devextreme/data/data_source';
import {RequiredRule, Validator} from 'devextreme-react/validator';
import LeopardMenuDataViewList from '../components/LeopardMenuDataViewList';
import LeopardMenuTemplateList from '../components/LeopardMenuTemplateList';
import {KeepChartDataById, KeepGridViewDataById, SetLeftMenuItem} from '../foundation/LeopardActionCreators';
import List from 'devextreme-react/list';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {Button, FileUploader, NumberBox, SelectBox, Switch, TagBox, TextArea, TextBox} from 'devextreme-react';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import LeopardMenuConfigItemBox from '../components/LeopardMenuConfigItemBox';
import LPH from "../helpers/LeopardPermissionHelper";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardMasterLeftMenu extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);
        this.state = this.initializeDefaultState(false, false, [], [], [],
            true, false, false, false, false);

        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.defaultViewMenuItems = null;
        this.reportMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.uiObjectInstance = [];
        this.isDataSourceLoaded = false;
        this.numberOfSeriesForChartText = "";
        this.customComputedColumnsText = "";
        this.scheduledReportDisabled = true;
        this.isConfigItemBoxValueOnChangeDisabled = false;
        this.batchProcessCompletedForWorkspaces = true;
        this.batchProcessCompletedForDataViews = true;
        this.timezoneDropdownList = [];
        this.guessedLocalTimezone = "";
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    componentDidUpdate = () => {
        let that = this;
        let userProfile = window.userProfile;

        if (userProfile !== undefined && userProfile !== null &&
            this.state.isUserProfileLoaded === false) {
            this.setState({isUserProfileLoaded: true}, function () {
                that.loadLeftMenuItems(true, null, false);
            });
        }
        this.setLeftMenuItemToBeSelected();
    };

    componentDidMount = () => {
        this.timezoneDropdownList = LeopardDropdownHelper.DropdownSelectionTimeZone;
        this.guessedLocalTimezone = momenttz.tz.guess();
        if (LDH.IsValueEmpty(this.guessedLocalTimezone)) {
            this.guessedLocalTimezone = "Australia/Sydney";
        }
    }

    componentWillUnmount = () => {
        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.defaultViewMenuItems = null;
        this.reportMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.resetOptionPanelStates(false, false, [], [], [], true,
            false, false, false, false);
    };

    initializeDefaultState = (
        leftMenuItemsInitialized, isUserProfileLoaded,
        availableDataViewListForCreate, availableDataViewListForEdit,
        availableReportListForEdit, categoryWorkspacesVisibility,
        categorySystemViewsVisibility, categoryDataViewsVisibility,
        categoryDefaultViewsVisibility, categoryReportsVisibility) => {
        return {
            showMenuOptionPanel: null,
            showChartTypeOption: false,
            showLoadingProgress: false,
            availableColumnNamesFromDataSourceWithoutBlank: [],
            validationInput: "",
            createDataViewType: "datagrid",
            dataViewExportMethod: "individual",
            createDataViewDataSourceUrl: 0,
            dataSourceCustomQueryInput: "",
            dataSourceCustomQueryTableSchemaInput: "",
            dataViewNamePrefixForImport: "Imported_",
            createDataViewDataSourceId: 0,
            createDataViewName: "",
            createDataViewChartType: "bar-chart",
            createDataViewChartValueField: null,
            createDataViewChartArgumentField: null,
            createDashboardName: "",
            availableDataSourceList: [],
            selectedDataSourceObject: null,
            createDataViewDataSourceURI: null,
            selectDateRangeForCreateChart: null,
            createDataViewChartDateRangeField: null,
            customQueryAttributesForChart: null,
            heightOfThumbnailForPhotoGallery: 140,
            widthOfThumbnailForPhotoGallery: 211,
            parentDataSourceIdForPhotoGallery: "",
            topLevelFilteringField: "",
            oDataParametersForNormalReport: "",
            oDataParametersForScheduledReport: "",
            timezoneForReport: "",
            timezoneNameForReport: "",
            daylightSavingForReport: 0,
            clientSideQueryForReport: "",
            clientSideUILogicForReport: "",
            scriptShapingForReport: "",
            cronExpressionForReportPart1: "",
            cronExpressionForReportPart2: "",
            cronExpressionForReportPart3: "",
            cronExpressionForReportPart4: "",
            cronExpressionForReportPart5: "",
            cronExpressionForReportPart6: "",
            cronExpressionForReport: "",
            recipientsForReport: "",
            disableScheduledReportOption: true,
            dataViewMenuCategorySelect: "",
            dataViewCustomComments: "",
            numberOfDataViewsToImportVisible: false,

            editDataViewType: "",
            editDataViewName: "",
            clientSideQueryEditor: "",
            dataViewPersistentId: "",
            editDataViewId: "",
            disableEditingConfigurationFields: true,
            dataViewJSONToExport: null,
            dataViewListToImport: [],
            numberOfSeriesOnChart: [],
            numberOfDataViewsToImport: [],
            customComputedColumns: [],
            dashboardEditManageRelationship: [],
            availableDataViewsOnDashboard: [],
            isResetDashboardRelationshipsOnSave: false,
            categoryWorkspacesVisibility,
            categorySystemViewsVisibility,
            categoryDataViewsVisibility,
            categoryDefaultViewsVisibility,
            categoryReportsVisibility,

            editDashboardId: "",
            editDashboardInitialized: false,
            selectedDataViewsForDashboard: [],
            isUserProfileLoaded,
            leftMenuItemsInitialized,
            availableDataViewListForCreate,
            availableDataViewListForEdit,
            availableReportListForEdit
        };
    };

    resetOptionPanelStates = (leftMenuItemsInitialized, isUserProfileLoaded,
                              availableDataViewListForCreate, availableDataViewListForEdit,
                              availableReportListForEdit, categoryWorkspacesVisibility,
                              categorySystemViewsVisibility, categoryDataViewsVisibility,
                              categoryDefaultViewsVisibility, categoryReportsVisibility) => {
        this.isDataSourceLoaded = false;
        this.setState(this.initializeDefaultState(leftMenuItemsInitialized, isUserProfileLoaded,
            availableDataViewListForCreate, availableDataViewListForEdit, availableReportListForEdit,
            categoryWorkspacesVisibility, categorySystemViewsVisibility, categoryDataViewsVisibility,
            categoryDefaultViewsVisibility, categoryReportsVisibility));
    };

    loadLeftMenuItems = (selectDefaultMenuItem, callbackSuccess, forceNotSelectMenu, userProfileCache) => {
        let that = this;
        let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
        if (LDH.IsObjectNull(userProfileCache) === false) {
            window.userProfileForInitWebApp = LDH.DeepClone(userProfileCache);
        }
        LeopardAjaxHelper.GetLeftMenuItems(userId, function (items) {
            if (!LDH.IsObjectNull(window.userProfileForInitWebApp)) {
                window.userProfileForInitWebApp = null;
            }
            let filteredDataViewsList = [];
            let filteredSystemViewsList = [];
            let filteredDefaultViewsList = [];
            let filteredTemplateList = [];
            let filteredReportsList = [];
            let availableDataViewListForCreate = [{id: 0, name: "--- Empty ---", group: "Blank View"}];
            let availableDataViewListForEdit = [];
            let availableReportListForEdit = [];

            for (let i = 0; i < items.length; i++) {
                if (LDH.IsValueEmpty(items[i].menuItemType) ||
                    items[i].menuItemType === "data-view") {
                    filteredDataViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Data Views",
                        category: "data-view",
                        type: items[i].dataViewType,
                        index: 2
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Data Views",
                        category: "data-view",
                        type: items[i].dataViewType,
                        index: 2
                    });
                } else if (items[i].menuItemType === "system-view") {
                    filteredSystemViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "System Views",
                        category: "system-view",
                        type: items[i].dataViewType,
                        index: 3
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "System Views",
                        category: "system-view",
                        type: items[i].dataViewType,
                        index: 3
                    });
                } else if (items[i].menuItemType === "default-view") {
                    filteredDefaultViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Default Views",
                        category: "default-view",
                        type: items[i].dataViewType,
                        index: 4
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Default Views",
                        category: "default-view",
                        type: items[i].dataViewType,
                        index: 4
                    });
                } else if (items[i].menuItemType === "report") {
                    filteredReportsList.push(items[i]);

                    availableReportListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Reports",
                        category: "report",
                        type: items[i].dataViewType,
                        index: 5
                    });
                } else if (items[i].menuItemType === "dashboard") {
                    filteredTemplateList.push(items[i]);

                    availableDataViewListForEdit.push({
                        id: items[i].dashboardId,
                        name: items[i].menuItemName,
                        group: "Workspaces",
                        category: "dashboard",
                        type: "dashboard",
                        index: 1
                    });
                }
            }

            that.setState({
                availableDataViewListForCreate,
                availableDataViewListForEdit,
                availableReportListForEdit,
                leftMenuItemsInitialized: true
            });

            that.dataViewMenuItems = filteredDataViewsList;
            that.systemViewMenuItems = filteredSystemViewsList;
            that.defaultViewMenuItems = filteredDefaultViewsList;
            that.reportMenuItems = filteredReportsList;
            that.templateMenuItems = filteredTemplateList;

            // ------------------------------------------------------------------------------------------
            // Delete the following code if want to load a default data view after logged in.
            let firstDefaultItem = Object.create(null);

            // Uncomment the following code.
            // let firstDefaultItem = null;
            // if (!LDH.IsObjectNull(filteredTemplateList) && filteredTemplateList.length > 0) {
            //     firstDefaultItem = filteredTemplateList[0];
            // } else if (!LDH.IsObjectNull(filteredDataViewsList) && filteredDataViewsList.length > 0) {
            //     firstDefaultItem = filteredDataViewsList[0];
            // } else if (!LDH.IsObjectNull(filteredSystemViewsList) && filteredSystemViewsList.length > 0) {
            //     firstDefaultItem = filteredSystemViewsList[0];
            // } else if (!LDH.IsObjectNull(filteredReportsList) && filteredReportsList.length > 0) {
            //     firstDefaultItem = filteredReportsList[0];
            // }
            // ------------------------------------------------------------------------------------------

            if (LDH.IsObjectNull(forceNotSelectMenu) || forceNotSelectMenu === false) {
                if (selectDefaultMenuItem === undefined || selectDefaultMenuItem) {
                    if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                        that.props.SetLeftMenuItem(firstDefaultItem);
                    } else {
                        localStorage.setItem("LoadFirstMenuItem", "true");
                        that.props.SetLeftMenuItem(Object.create(null));
                    }
                } else {
                    let selectedItem = that.props.state.selectedLeftMenuItem;
                    if (selectedItem !== undefined && selectedItem !== null) {
                        that.props.SetLeftMenuItem(selectedItem);
                    } else {
                        if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                            LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                            LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                            that.props.SetLeftMenuItem(firstDefaultItem);
                        } else {
                            localStorage.setItem("LoadFirstMenuItem", "true");
                            that.props.SetLeftMenuItem(Object.create(null));
                        }
                    }
                }
            }
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess();
            }
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to get left menu items.", "error", 5000);
            }
            that.setState({leftMenuItemsInitialized: true});
        }, window["userProfileForInitWebApp"]);
    };

    setLeftMenuItemToBeSelected = () => {
        let item = this.leftMenuItemToBeSelected;
        if (this.setLeftMenu && this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null && this.leftMenuItemToBeSelected !== null &&
            this.props.state.selectedLeftMenuItem.menuItemId === null) {
            this.setLeftMenu = false;
            this.props.SetLeftMenuItem(item);
            this.leftMenuItemToBeSelected = null;
        }
    };

    menuItemSettingsButtonOnClick_GridEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote;
                let dataViewPersistentId = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.gridViewDefinition) &&
                    !LDH.IsObjectNull(definition.gridViewDefinition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.gridViewDefinition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.gridViewDefinition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.gridViewDefinition) &&
                    !LDH.IsObjectNull(definition.gridViewDefinition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.gridViewDefinition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.gridViewDefinition.dataViewCustomComments;
                }

                if (!LDH.IsObjectNull(definition.gridViewDefinition.clientSideQuery) &&
                    !LDH.IsValueEmpty(definition.gridViewDefinition.clientSideQuery)) {
                    that.setState({
                        clientSideQueryEditor: definition.gridViewDefinition.clientSideQuery,
                        showLoadingProgress: false,
                        dataViewPersistentId,
                        dataViewCustomComments,
                        dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                } else {
                    that.setState({
                        showLoadingProgress: false,
                        dataViewPersistentId,
                        dataViewCustomComments,
                        dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_ChartEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote;
                let dataViewPersistentId = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.chartDefinition) &&
                    !LDH.IsObjectNull(definition.chartDefinition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.chartDefinition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.chartDefinition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.chartDefinition) &&
                    !LDH.IsObjectNull(definition.chartDefinition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.chartDefinition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.chartDefinition.dataViewCustomComments;
                }

                if (!LDH.IsObjectNull(definition.chartDefinition.clientSideQueryEditor) &&
                    !LDH.IsValueEmpty(definition.chartDefinition.clientSideQueryEditor)) {
                    that.setState({
                        clientSideQueryEditor: definition.chartDefinition.clientSideQueryEditor,
                        showLoadingProgress: false,
                        dataViewPersistentId,
                        dataViewCustomComments,
                        dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                } else {
                    that.setState({
                        showLoadingProgress: false,
                        dataViewPersistentId,
                        dataViewCustomComments,
                        dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_ReportEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

            LeopardAjaxHelper.GetDataSourceList(option.item.menuItemType, function (result) {
                let list = [];
                for (let j = 0; j < result.length; j++) {
                    list.push(result[j]);
                }
                LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                    let definition = result.dataViewNote;
                    let stateHasBeenSet = false;

                    if (!LDH.IsObjectNull(definition.cronExpression) && !LDH.IsValueEmpty(definition.cronExpression)) {
                        let cronExp = definition.cronExpression.split(" ");
                        if (!LDH.IsObjectNull(cronExp) && cronExp.length === 6) {
                            stateHasBeenSet = true;
                            that.scheduledReportDisabled = !definition.scheduledReportEnabled;
                            that.setState({
                                oDataParametersForNormalReport: definition.oDataParametersForNormalReport,
                                oDataParametersForScheduledReport: definition.oDataParametersForScheduledReport,
                                timezoneForReport: LDH.IsObjectNull(definition.timezone) ||
                                LDH.IsValueEmpty(definition.timezone) ? 0 : definition.timezone,
                                timezoneNameForReport: LDH.IsObjectNull(definition.timezoneName) ||
                                LDH.IsValueEmpty(definition.timezoneName) ? that.guessedLocalTimezone :
                                    definition.timezoneName,
                                daylightSavingForReport: LDH.IsObjectNull(definition.daylightSaving) ||
                                LDH.IsValueEmpty(definition.daylightSaving) ? 0 : definition.daylightSaving,
                                clientSideQueryForReport: definition.clientSideQueryForReport,
                                clientSideUILogicForReport: definition.clientSideUILogicForReport,
                                scriptShapingForReport: definition.scriptShapingForReport,
                                cronExpressionForReport: definition.cronExpression,
                                recipientsForReport: definition.recipients,
                                createDataViewDataSourceUrl: definition.tableName,
                                availableDataSourceList: list,
                                disableScheduledReportOption: !definition.scheduledReportEnabled,
                                showLoadingProgress: false,
                                cronExpressionForReportPart1: cronExp[0],
                                cronExpressionForReportPart2: cronExp[1],
                                cronExpressionForReportPart3: cronExp[2],
                                cronExpressionForReportPart4: cronExp[3],
                                cronExpressionForReportPart5: cronExp[4],
                                cronExpressionForReportPart6: cronExp[5],
                                dataViewCustomComments: definition.dataViewCustomComments
                            }, function () {
                                that.props.updateWindowDimensionsRequired();
                            });
                        }
                    }

                    if (stateHasBeenSet === false) {
                        that.setState({
                            oDataParametersForNormalReport: definition.oDataParametersForNormalReport,
                            oDataParametersForScheduledReport: definition.oDataParametersForScheduledReport,
                            timezoneForReport: LDH.IsObjectNull(definition.timezone) ||
                            LDH.IsValueEmpty(definition.timezone) ? 0 : definition.timezone,
                            timezoneNameForReport: LDH.IsObjectNull(definition.timezoneName) ||
                            LDH.IsValueEmpty(definition.timezoneName) ? that.guessedLocalTimezone :
                                definition.timezoneName,
                            daylightSavingForReport: LDH.IsObjectNull(definition.daylightSaving) ||
                            LDH.IsValueEmpty(definition.daylightSaving) ? 0 : definition.daylightSaving,
                            clientSideQueryForReport: definition.clientSideQueryForReport,
                            clientSideUILogicForReport: definition.clientSideUILogicForReport,
                            scriptShapingForReport: definition.scriptShapingForReport,
                            cronExpressionForReport: definition.cronExpression,
                            recipientsForReport: definition.recipients,
                            createDataViewDataSourceUrl: definition.tableName,
                            availableDataSourceList: list,
                            disableScheduledReportOption: !definition.scheduledReportEnabled,
                            dataViewCustomComments: definition.dataViewCustomComments,
                            showLoadingProgress: false
                        }, function () {
                            that.props.updateWindowDimensionsRequired();
                        });
                    }
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
            });
        });
    };

    menuItemSettingsButtonOnClick_PhotoEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.photoDefinition;
                let dataViewPersistentId = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    heightOfThumbnailForPhotoGallery: definition.heightOfThumbnail,
                    widthOfThumbnailForPhotoGallery: definition.widthOfThumbnail,
                    parentDataSourceIdForPhotoGallery: definition.parentDataSourceId,
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    displayMenuOptionByType(data) {
        let that = this;
        if (data !== undefined && data !== null && data.option === "add_template") {
            return this.buildMenuOptionPanelCreateTemplate();
        }

        if (data !== undefined && data !== null && (data.option === "edit_template" ||
            data.option === "manage_dashboard_relationship")) {
            if (that.state.editDashboardInitialized === false) {
                let profile = window.userProfile;
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(profile);
                let dashboardId = that.state.editDashboardId;

                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    let dataViewList = [];
                    if (dashboard === [] || dashboard.length === 0) {
                        that.setState({
                            selectedDataViewsForDashboard: dataViewList,
                            editDashboardInitialized: true,
                            dashboardEditManageRelationship: []
                        }, function () {
                            that.props.updateWindowDimensionsRequired();
                        });
                        return;
                    }
                    if (LDH.IsObjectNull(dashboard.relationships)) {
                        dashboard.relationships = [];
                    }

                    for (let i = 0; i < dashboard.dashboardLayout.length; i++) {
                        let definition = dashboard.dashboardLayout[i].definition;
                        let menuItem = null;

                        for (let j = 0; j < that.dataViewMenuItems.length; j++) {
                            if (that.dataViewMenuItems[j].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.dataViewMenuItems[j];
                                break;
                            }
                        }

                        for (let v = 0; v < that.systemViewMenuItems.length; v++) {
                            if (that.systemViewMenuItems[v].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.systemViewMenuItems[v];
                                break;
                            }
                        }

                        for (let x = 0; x < that.defaultViewMenuItems.length; x++) {
                            if (that.defaultViewMenuItems[x].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.defaultViewMenuItems[x];
                                break;
                            }
                        }

                        for (let w = 0; w < that.reportMenuItems.length; w++) {
                            if (that.reportMenuItems[w].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.reportMenuItems[w];
                                break;
                            }
                        }

                        let selectedId = (menuItem === null) ? "empty" : menuItem.dataViewId;
                        let combinedId = definition.dashboardItemId + ":" + ((menuItem === null)
                            ? "empty" : menuItem.dataViewPersistentId);

                        let dataViewItem = {
                            selectedValue: definition.dataViewName,
                            selectedId,
                            dashboardItemId: definition.dashboardItemId,
                            combinedId,
                            customDataViewTitle: definition.customDataViewTitle,
                            dataViewPersistentId: definition.dataViewPersistentId,
                            index: parseInt(dashboard.dashboardLayout[i].i),
                            item: menuItem
                        };
                        dataViewList.push(dataViewItem);
                    }

                    that.setState({
                        selectedDataViewsForDashboard: dataViewList,
                        editDashboardInitialized: true,
                        dashboardEditManageRelationship: dashboard.relationships
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }, function (error, sessionTimeout) {
                    if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                        !LDH.IsValueEmpty(error.message) &&
                        error.message.indexOf("Cannot read property '") !== -1 &&
                        error.message.indexOf("' of undefined") !== -1) {
                        that.createDashboardDeleteButtonOnClick("Your workspace could not be found. <br/>" +
                            "Do you want to delete the reference(s) to it from the database?");
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to initialize workspace settings. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                        that.state.categoryReportsVisibility);
                });
            }

            if (data.option === "edit_template") {
                return this.buildMenuOptionPanelEditTemplate();
            }
            if (data.option === "manage_dashboard_relationship") {
                return this.buildMenuOptionPanelManageDashboardRelationship();
            }
            return null;
        }

        if (data !== undefined && data !== null && data.option === "add_dataview_options") {
            return this.buildMenuOptionPanelDataViewOptions();
        }

        if (data !== undefined && data !== null && data.option === "edit_dashboard_options") {
            return this.buildMenuOptionPanelDashboardOptions();
        }

        if (data !== undefined && data !== null && data.option === "export_dataview") {
            return this.buildMenuOptionPanelExportDataView();
        }

        if (data !== undefined && data !== null && data.option === "import_dataview") {
            return this.buildMenuOptionPanelImportDataView();
        }

        if (data !== undefined && data !== null && data.option === "clone_dataview") {
            return this.buildMenuOptionPanelCloneDataView();
        }

        if (data !== undefined && data !== null && data.option === "add_dataview") {
            if (that.isDataSourceLoaded === false) {
                that.isDataSourceLoaded = true;
                setTimeout(function () {
                    that.setState({showLoadingProgress: true}, function () {
                        let category = that.state.showMenuOptionPanel.dataViewCategory;
                        LeopardAjaxHelper.GetDataSourceList(category, function (result) {
                            let list = [];
                            if (category === "default-view" || category === "report") {
                                for (let j = 0; j < result.length; j++) {
                                    list.push(result[j]);
                                }
                            } else {
                                for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                                    let view = that.state.availableDataViewListForEdit[i];
                                    if (view.category === "default-view" &&
                                        view.type === that.state.createDataViewType) {
                                        list.push(that.state.availableDataViewListForEdit[i]);
                                    }
                                }
                            }
                            that.setState({availableDataSourceList: list, showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve data sources from the database. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                    });
                }, 100);
            }
            return this.buildMenuOptionPanelCreateDataView();
        }

        if (data !== undefined && data !== null && data.option === "edit_dataview") {
            return this.buildMenuOptionPanelEditDataView();
        }
        return null;
    }

    showMenuOptionPanelStatusOnChange = (option) => {
        let that = this;
        if (!LDH.IsObjectNull(option.option) && option.option === "edit_template") {
            if (option.currentOption === option.option) return;

            this.setState({showMenuOptionPanel: option}, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "manage_dashboard_relationship") {
            if (option.currentOption === option.option) return;

            this.setState({showMenuOptionPanel: option}, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        let dataViewType = option.dataViewCategory === "report" ? "report" : "datagrid";
        if (LDH.IsValueEmpty(this.state.createDataViewType) ||
            option.dataViewCategory === "report") {
            this.setState({
                showMenuOptionPanel: option,
                selectedDataViewsForDashboard: [{
                    selectedValue: "none", index: 0, item: null,
                    selectedId: null, dashboardItemId: null,
                    dataViewPersistentId: null,
                    customDataViewTitle: ""
                }],
                createDataViewType: dataViewType
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        this.setState({
            showMenuOptionPanel: option,
            selectedDataViewsForDashboard: [{
                selectedValue: "none", index: 0, item: null,
                selectedId: null, dashboardItemId: null,
                dataViewPersistentId: null,
                customDataViewTitle: ""
            }]
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick = (option) => {
        let that = this;

        if (option.option === "edit_dataview") {
            this.setState({
                showMenuOptionPanel: option,
                editDataViewId: option.item.dataViewId,
                editDataViewName: option.item.menuItemName,
                editDataViewType: option.item.dataViewType,
                heightOfThumbnailForPhotoGallery: null,
                widthOfThumbnailForPhotoGallery: null,
                parentDataSourceIdForPhotoGallery: ""
            }, function () {
                that.props.updateWindowDimensionsRequired();

                if (option.item.dataViewType === "photo") {
                    this.menuItemSettingsButtonOnClick_PhotoEvent(that, option);
                } else if (option.item.dataViewType === "report") {
                    this.menuItemSettingsButtonOnClick_ReportEvent(that, option);
                } else if (option.item.dataViewType === "chart") {
                    this.menuItemSettingsButtonOnClick_ChartEvent(that, option);
                } else if (option.item.dataViewType === "datagrid") {
                    this.menuItemSettingsButtonOnClick_GridEvent(that, option);
                }
            });
        } else if (option.option === "edit_dashboard_options") {
            this.setState({
                showMenuOptionPanel: option,
                editDashboardId: option.item.dashboardId,
                editDataViewName: option.item.menuItemName
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        }
    };

    dataViewOrTemplateMenuItemOnClick = (data) => {
        let that = this;

        that.leftMenuItemToBeSelected = data.item;
        that.setLeftMenu = true;

        let emptyItem = {
            dataSourceUrl: null, contentPageType: null,
            menuItemId: null, dataViewType: null
        };

        that.props.SetLeftMenuItem(emptyItem);

        if (data.item !== undefined && data.item !== null) {
            let id = data.item.menuItemId;
            that.props.KeepGridViewDataById(id);
            that.props.KeepChartDataById(id);
        } else {
            that.props.KeepGridViewDataById("");
            that.props.KeepChartDataById("");
        }
    };

    selectDataViewCustomTitleOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        for (let i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].selectedId === data.item.selectedId) {
                clonedDataViewList[i].customDataViewTitle = data.e.value;
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedDataViewsForDashboard: clonedDataViewList
        });
    };

    selectDataViewValueOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        let addNewEntry = true;

        for (let i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].index === data.index) {
                clonedDataViewList[i].selectedValue = "none";
                clonedDataViewList[i].selectedId = null;
                clonedDataViewList[i].item = null;

                for (let j = 0; j < this.dataViewMenuItems.length; j++) {
                    if (this.dataViewMenuItems[j].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.dataViewMenuItems[j];
                        clonedDataViewList[i].selectedId = this.dataViewMenuItems[j].dataViewId;
                        clonedDataViewList[i].selectedValue = this.dataViewMenuItems[j].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.dataViewMenuItems[j].dataViewPersistentId;
                        break;
                    }
                }

                for (let v = 0; v < this.systemViewMenuItems.length; v++) {
                    if (this.systemViewMenuItems[v].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.systemViewMenuItems[v];
                        clonedDataViewList[i].selectedId = this.systemViewMenuItems[v].dataViewId;
                        clonedDataViewList[i].selectedValue = this.systemViewMenuItems[v].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.systemViewMenuItems[v].dataViewPersistentId;
                        break;
                    }
                }

                for (let x = 0; x < this.defaultViewMenuItems.length; x++) {
                    if (this.defaultViewMenuItems[x].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.defaultViewMenuItems[x];
                        clonedDataViewList[i].selectedId = this.defaultViewMenuItems[x].dataViewId;
                        clonedDataViewList[i].selectedValue = this.defaultViewMenuItems[x].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.defaultViewMenuItems[x].dataViewPersistentId;
                        break;
                    }
                }

                for (let w = 0; w < this.reportMenuItems.length; w++) {
                    if (this.reportMenuItems[w].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.reportMenuItems[w];
                        clonedDataViewList[i].selectedId = this.reportMenuItems[w].dataViewId;
                        clonedDataViewList[i].selectedValue = this.reportMenuItems[w].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.reportMenuItems[w].dataViewPersistentId;
                        break;
                    }
                }
            }
            if (clonedDataViewList[i].selectedValue === "none") {
                addNewEntry = false;
            }
        }

        if (addNewEntry === false || this.state.showMenuOptionPanel.option === "edit_template") {
            this.setState({
                ...this.state,
                selectedDataViewsForDashboard: clonedDataViewList,
                isResetDashboardRelationshipsOnSave: true
            });
        } else {
            clonedDataViewList.push({
                selectedValue: "none",
                index: clonedDataViewList.length,
                item: null,
                selectedId: null
            });
            this.setState({
                ...this.state,
                selectedDataViewsForDashboard: clonedDataViewList,
                isResetDashboardRelationshipsOnSave: true
            });
        }
    };

    dataViewExportMethodOnValueChanged = (e) => {
        this.setState({dataViewExportMethod: e.value});
    };

    createDataViewSelectDataViewTypeOnValueChanged = (e) => {
        let that = this;
        let show = false;
        if (e.value !== null && e.value !== "" && e.value === "chart") {
            show = true;
        }

        let list = [];
        if (that.state.showMenuOptionPanel.dataViewCategory !== "default-view") {
            for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                let view = that.state.availableDataViewListForEdit[i];
                if (view.type === e.value && view.category === "default-view") {
                    list.push(that.state.availableDataViewListForEdit[i]);
                }
            }
            this.setState({
                createDataViewType: e.value,
                showChartTypeOption: show,
                availableDataSourceList: list
            });
        } else {
            this.setState({
                createDataViewType: e.value,
                showChartTypeOption: show
            });
        }

        // ---------- Re-initialize DevExtreme validation -------------
        let random = LDH.GenerateGuid();
        this.uiObjectInstance.validationInput.option("value", random);
        // ------------------------------------------------------------
    };

    createDataViewSelectChartTypeOnValueChanged = (e) => {
        this.setState({createDataViewChartType: e.value});
    };

    createDataViewValueFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartValueField: e.value});
    };

    createDataViewChartDateRangeFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartDateRangeField: e.value});
    };

    selectDateRangeForCreateChartOnValueChanged = (e) => {
        this.setState({selectDateRangeForCreateChart: e.value});
    };

    createDataViewArgumentFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartArgumentField: e.value});
    };

    dataViewMenuCategorySelectOnValueChanged = (e) => {
        this.setState({dataViewMenuCategorySelect: e.value});
    };

    createDataViewDataSourceUrlOnValueChanged = (e) => {
        let that = this;
        let selectedDataSource = null;

        for (let i = 0; i < this.state.availableDataSourceList.length; i++) {
            if (this.state.availableDataSourceList[i].id === e.value) {
                let source = this.state.availableDataSourceList[i];
                selectedDataSource = source;
                this.setState({selectedDataSourceObject: source});
            }
        }

        if (!LDH.IsObjectNull(selectedDataSource) && selectedDataSource.category === "data-table") {
            this.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true});
            let tableName = e.value;

            LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, [], function (data) {
                let dataSourceIds = [];
                for (let i = 0; i < data.fullColumns.length; i++) {
                    dataSourceIds.push({id: data.fullColumns[i], name: data.fullColumns[i]});
                }
                that.setState({
                    availableColumnNamesFromDataSourceWithoutBlank: dataSourceIds,
                    showLoadingProgress: false,
                    dataSourceCustomQueryInput: "",
                    dataSourceCustomQueryTableSchemaInput: ""
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve data for your data source. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            }, null, null);
        } else {
            that.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true}, function () {
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

                LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            });
        }
    };

    createDataViewNameOnValueChanged = (e) => {
        this.setState({createDataViewName: e.value});
    };

    oDataParametersForNormalReportOnValueChanged = (e) => {
        this.setState({oDataParametersForNormalReport: e.value});
    };

    dataViewCustomCommentsOnValueChanged = (e) => {
        this.setState({dataViewCustomComments: e.value});
    };

    clientSideQueryForReportOnValueChanged = (e) => {
        this.setState({clientSideQueryForReport: e.value});
    };

    scriptShapingForReportOnValueChanged = (e) => {
        this.setState({scriptShapingForReport: e.value});
    };

    clientSideUILogicForReportOnValueChanged = (e) => {
        this.setState({clientSideUILogicForReport: e.value});
    };

    dataSourceCustomQueryInputOnValueChanged = (e) => {
        this.setState({dataSourceCustomQueryInput: e.value});
    };

    dataSourceCustomQueryTableSchemaInputOnValueChanged = (e) => {
        this.setState({dataSourceCustomQueryTableSchemaInput: e.value});
    };

    dataViewNamePrefixForImportOnValueChanged = (e) => {
        this.setState({dataViewNamePrefixForImport: e.value});
    };

    oDataParametersForScheduledReportOnValueChanged = (e) => {
        this.setState({oDataParametersForScheduledReport: e.value});
    };

    recipientsForReportInputOnValueChanged = (e) => {
        this.setState({recipientsForReport: e.value});
    };

    timezoneForReportSelectOnValueChanged = (e) => {
        this.setState({timezoneForReport: e.value});
    };

    timezoneNameForReportSelectOnValueChanged = (e) => {
        this.setState({timezoneNameForReport: e.value});
    };

    daylightSavingForReportInputOnValueChanged = (e) => {
        this.setState({daylightSavingForReport: e.value});
    };

    cronExpressionForReportOnValueChanged = () => {
        let part1 = this.uiObjectInstance.cronExpressionForReportPart1Input.option("value");
        let part2 = this.uiObjectInstance.cronExpressionForReportPart2Input.option("value");
        let part3 = this.uiObjectInstance.cronExpressionForReportPart3Input.option("value");
        let part4 = this.uiObjectInstance.cronExpressionForReportPart4Input.option("value");
        let part5 = this.uiObjectInstance.cronExpressionForReportPart5Input.option("value");
        let part6 = this.uiObjectInstance.cronExpressionForReportPart6Input.option("value");
        let value = part1 + " " + part2 + " " + part3 + " " + part4 + " " + part5 + " " + part6;

        this.setState({
            cronExpressionForReport: value,
            cronExpressionForReportPart1: part1,
            cronExpressionForReportPart2: part2,
            cronExpressionForReportPart3: part3,
            cronExpressionForReportPart4: part4,
            cronExpressionForReportPart5: part5,
            cronExpressionForReportPart6: part6
        });
    };

    heightOfThumbnailOnValueChanged = (e) => {
        this.setState({heightOfThumbnailForPhotoGallery: e.value});
    };

    widthOfThumbnailOnValueChanged = (e) => {
        this.setState({widthOfThumbnailForPhotoGallery: e.value});
    };

    parentDataSourceIdOnValueChanged = (e) => {
        this.setState({parentDataSourceIdForPhotoGallery: e.value});
    };

    topLevelFilteringFieldValueChanged = (e) => {
        this.setState({topLevelFilteringField: e.value});
    };

    customQueryAttributesOnValueChanged = (e) => {
        this.setState({customQueryAttributesForChart: e.value});
    };

    numberOfSeriesForChartOnValueChanged = (e) => {
        this.numberOfSeriesForChartText = e.value;
    };

    customComputedColumnsOnValueChanged = (e) => {
        this.customComputedColumnsText = e.value;
    };

    createDashboardNameInputOnValueChanged = (e) => {
        this.setState({createDashboardName: e.value});
    };

    validationInputOnValueChanged = (e) => {
        this.setState({validationInput: e.value});
    };

    editDataViewNameOnValueChanged = (e) => {
        this.setState({editDataViewName: e.value});
    };

    clientSideQueryEditorOnValueChanged = (e) => {
        this.setState({clientSideQueryEditor: e.value});
    };

    dataViewPersistentIdOnValueChanged = (e) => {
        this.setState({dataViewPersistentId: e.value});
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    disableScheduledReportOptionOnValueChanged = (e) => {
        this.setState({disableScheduledReportOption: !e.value});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    numberOfSeriesOnChartTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    numberOfDataViewsToImportTemplate = (item) => {
        if (!LDH.IsObjectNull(item.dashboardName)) {
            return (<div>{item.dashboardName}</div>);
        } else {
            return (<div>{item.dataViewName}</div>);
        }
    };

    numberOfDataViewsToImportOnDeleted = (e) => {
        let dataList = LDH.DeepClone(this.state.dataViewListToImport);
        for (let i = 0; i < dataList.length; i++) {
            let dataToDelete = JSON.parse(dataList[i]);
            if (LDH.IsObjectNull(dataToDelete)) continue;

            if (!LDH.IsObjectNull(e.itemData.dataViewId) &&
                !LDH.IsObjectNull(dataToDelete.dataViewId) &&
                e.itemData.dataViewId === dataToDelete.dataViewId) {
                dataList[i] = null;
                break;
            }
            if (!LDH.IsObjectNull(e.itemData.dashboardId) &&
                !LDH.IsObjectNull(dataToDelete.dashboardId) &&
                e.itemData.dashboardId === dataToDelete.dashboardId) {
                dataList[i] = null;
                break;
            }
        }
        let visible = this.state.numberOfDataViewsToImportVisible;
        let foundData = false;
        for (let i = 0; i < dataList.length; i++) {
            if (!LDH.IsValueEmpty(dataList[i])) {
                foundData = true;
            }
        }
        if (foundData === false) visible = false;
        this.setState({
            dataViewListToImport: dataList,
            numberOfDataViewsToImportVisible: visible
        });
    };

    customComputedColumnsTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    // ==================== Data Views =======================

    cloneDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            (LDH.IsValueEmpty(this.state.dataViewJSONToExport.dataViewName) &&
                LDH.IsValueEmpty(this.state.dataViewJSONToExport.dashboardName))) {
            LRH.ShowToast("Please select at least one Data View to export.", "error", 5000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
                let dataToImport = that.state.dataViewJSONToExport;
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    if (dataToImport.contentPageType === "dashboard-view") {
                        let relationships = dataToImport.relationships;
                        if (LDH.IsObjectNull(relationships)) relationships = [];

                        dataToImport.dashboardId = LDH.GenerateGuid();
                        LeopardAjaxHelper.CreateDashboard(organizationId, that.state.createDataViewName, dataToImport.dashboardNote,
                            dataToImport.dashboardId, null, dataToImport.dashboardLayout, relationships, function () {
                                // Then create menu item for that workspace.
                                LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                                    that.state.createDataViewName, dataToImport.dashboardId, function () {
                                        LRH.ShowToast("Your Workspace has been successfully cloned.", "success", 5000);
                                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                            that.state.categoryReportsVisibility);

                                        that.loadLeftMenuItems(false, null, false, profile);
                                        that.setState({showLoadingProgress: false});
                                    }, function () {
                                        LRH.ShowToast("Failed to import your Workspace. " +
                                            "Please check your network connection status and try again.", "error", 5000);
                                        that.setState({showLoadingProgress: false});
                                    }, profile);
                            });
                    } else {
                        let dataViewPersistentId = LDH.GenerateGuid();
                        if (!LDH.IsObjectNull(dataToImport.dataViewNote.gridViewDefinition)) {
                            dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId = dataViewPersistentId;
                        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.photoDefinition)) {
                            dataToImport.dataViewNote.photoDefinition.dataViewPersistentId = dataViewPersistentId;
                        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.chartDefinition)) {
                            dataToImport.dataViewNote.chartDefinition.dataViewPersistentId = dataViewPersistentId;
                        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.reportDefinition)) {
                            dataToImport.dataViewNote.reportDefinition.dataViewPersistentId = dataViewPersistentId;
                        }
                        dataToImport.dataViewId = LDH.GenerateGuid();
                        LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName,
                            dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
                            dataToImport.dataViewId, dataViewPersistentId, function () {
                                // Then create menu item for that data view.
                                LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId,
                                    LDH.GenerateGuid(), that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                                    dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl, dataToImport.dataViewId,
                                    dataViewPersistentId, function () {
                                        LRH.ShowToast("Your Data View has been successfully cloned.", "success", 5000);
                                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                            that.state.categoryReportsVisibility);

                                        that.loadLeftMenuItems(false, null, false, profile);
                                        that.setState({showLoadingProgress: false});
                                    }, function () {
                                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                            "Please check your network connection status and try again.", "error", 5000);
                                        LeopardAjaxHelper.DeleteDataView(organizationId, dataToImport.dataViewId, function () {

                                        });
                                        that.setState({showLoadingProgress: false});
                                    }, profile);
                            }, function () {
                                LRH.ShowToast("Failed to clone your Data View. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                                that.setState({showLoadingProgress: false});
                            });
                    }
                }, function () {
                    LRH.ShowToast("Failed to retrieve user profile.", "success", 5000);
                });
            }
        );
    };

    exportIndividualDataViewProcess = () => {
        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            this.state.dataViewJSONToExport.length === 0) {
            LRH.ShowToast("Please select at least one Data View to export.", "error", 5000);
            return;
        }
        let dataViewJSONToExportList = [];
        for (let i = 0; i < this.state.dataViewJSONToExport.length; i++) {
            let data = this.state.dataViewJSONToExport[i].data;
            dataViewJSONToExportList.push(data);
        }
        let jsonData = JSON.stringify(dataViewJSONToExportList);
        LRH.DownloadStringToFile("DataViewExport.json", jsonData);
        if (!LPH.IsLeopardAdminModeEnabled()) {
            this.createDataViewCancelButtonOnClickWithoutConfirm();
        }
        LRH.ShowToast("Your data has been successfully exported.", "success", 5000);
    };

    exportAllDataViewsProcess = () => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

            let dataViewsToExport = [];
            if (this.state.showMenuOptionPanel.dataViewCategory !== "report") {
                for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                    let dataView = that.state.availableDataViewListForEdit[i];
                    dataViewsToExport.push({id: dataView.id, data: dataView});
                }
            }

            if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
                for (let j = 0; j < that.state.availableReportListForEdit.length; j++) {
                    let dataView = that.state.availableReportListForEdit[j];
                    dataViewsToExport.push({id: dataView.id, data: dataView});
                }
            }

            if (LDH.IsObjectNull(dataViewsToExport) || dataViewsToExport.length === 0) {
                LRH.ShowToast("There is no data view available to export.", "error", 5000);
                return;
            }

            let dataViewJSONToExportList = [];
            let forceStop = false;

            LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                let userProfileCache = profile;

                for (let i = 0; i < dataViewsToExport.length; i++) {
                    if (forceStop === true) return;

                    let viewObject = dataViewsToExport[i].data;
                    let dataViewId = dataViewsToExport[i].id;

                    if (viewObject.category !== "dashboard") {
                        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (result) {
                            dataViewJSONToExportList.push(result);
                            if (i === dataViewsToExport.length - 1) {
                                let jsonData = JSON.stringify(dataViewJSONToExportList);
                                LRH.DownloadStringToFile("BulkExport.json", jsonData);
                                that.setState({showLoadingProgress: false}, function () {
                                    if (!LPH.IsLeopardAdminModeEnabled()) {
                                        that.createDataViewCancelButtonOnClickWithoutConfirm();
                                    }
                                    LRH.ShowToast("Your data has been successfully exported.", "success", 5000);
                                });
                            }
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve Data View details. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            forceStop = true;
                            that.setState({showLoadingProgress: false});
                        }, userProfileCache);
                    } else {
                        LeopardAjaxHelper.GetDashboardById(userId, dataViewId, function (result) {
                            dataViewJSONToExportList.push(result);

                            if (i === dataViewsToExport.length - 1) {
                                let jsonData = JSON.stringify(dataViewJSONToExportList);
                                LRH.DownloadStringToFile("BulkExport.json", jsonData);
                                that.setState({showLoadingProgress: false}, function () {
                                    if (!LPH.IsLeopardAdminModeEnabled()) {
                                        that.createDataViewCancelButtonOnClickWithoutConfirm();
                                    }
                                    LRH.ShowToast("Your data has been successfully exported.", "success", 5000);
                                });
                            }
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve Data View details. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            forceStop = true;
                            that.setState({showLoadingProgress: false});
                        }, userProfileCache);
                    }
                }
            }, function () {
                LRH.ShowToast("Failed to retrieve user profile information.", "error", 5000);
                that.setState({showLoadingProgress: false});
            }, null);
        });
    };

    exportDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();

        if (this.state.dataViewExportMethod === "individual") {
            this.exportIndividualDataViewProcess();
        } else {
            this.exportAllDataViewsProcess();
        }
    };

    importDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(that.state.dataViewListToImport) ||
            that.state.dataViewListToImport.length === 0) {
            LRH.ShowToast("There is no Data View or Workspace to import.", "error", 5000);
            return;
        }

        let foundDataView = false;
        for (let i = 0; i < that.state.dataViewListToImport.length; i++) {
            if (!LDH.IsValueEmpty(that.state.dataViewListToImport[i])) {
                foundDataView = true;
                break;
            }
        }
        if (foundDataView === false) {
            LRH.ShowToast("There is no Data View or Workspace to import.", "error", 5000);
            return;
        }
        that.setState({showLoadingProgress: true}, function () {
            try {
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                let dataViewsToImport = [];
                let workspacesToImport = [];
                that.batchProcessCompletedForWorkspaces = true;
                that.batchProcessCompletedForDataViews = true;

                for (let i = 0; i < that.state.dataViewListToImport.length; i++) {
                    let dataToImport = JSON.parse(that.state.dataViewListToImport[i]);
                    if (LDH.IsObjectNull(dataToImport)) continue;

                    if (dataToImport.contentPageType === "dashboard-view") {
                        if (LDH.IsValueEmpty(dataToImport.dashboardName) || LDH.IsValueEmpty(dataToImport.dashboardId)) {
                            LRH.ShowToast("Unable to import Data View. The data you specified is invalid.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                            return;
                        }
                        workspacesToImport.push(that.state.dataViewListToImport[i]);
                    } else {
                        if (LDH.IsValueEmpty(dataToImport.dataViewName) || LDH.IsValueEmpty(dataToImport.dataViewType) ||
                            LDH.IsValueEmpty(dataToImport.dataSourceUrl) || LDH.IsValueEmpty(dataToImport.dataViewId)) {
                            LRH.ShowToast("Unable to import Data View. The data you specified is invalid.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                            return;
                        }
                        dataViewsToImport.push(that.state.dataViewListToImport[i]);
                    }
                }

                if (dataViewsToImport.length > 0) that.batchProcessCompletedForDataViews = false;
                if (workspacesToImport.length > 0) that.batchProcessCompletedForWorkspaces = false;

                if (!that.batchProcessCompletedForDataViews || !that.batchProcessCompletedForWorkspaces) {
                    if (!that.batchProcessCompletedForDataViews) {
                        that.recurrsiveDataViewImportProcess(userId, organizationId, 0, dataViewsToImport, true, function () {
                            if (!that.batchProcessCompletedForWorkspaces) {
                                that.recurrsiveWorkspaceImportProcess(userId, organizationId, 0,
                                    workspacesToImport, false, null);
                            }
                        });
                    }
                    else{
                        if (!that.batchProcessCompletedForWorkspaces) {
                            that.recurrsiveWorkspaceImportProcess(userId, organizationId, 0,
                                workspacesToImport, false, null);
                        }
                    }
                }
            } catch (error) {
                LRH.ShowToast("Unable to import Data View. Please check for the data format before proceeding.", "error", 5000);
                that.setState({showLoadingProgress: false});
            }
        });
    };

    recurrsiveDataViewImportProcess = (userId, organizationId, processedIndex, dataViewListToImport, checkDependency,
                                       callbackComplete, userProfileCache) => {
        let that = this;
        let dataToImport = JSON.parse(dataViewListToImport[processedIndex]);
        dataToImport.dataViewId = LDH.GenerateGuid();

        let dataViewPersistentId = "";
        if (!LDH.IsObjectNull(dataToImport.dataViewNote) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.gridViewDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.photoDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.photoDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.photoDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.photoDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.chartDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.chartDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.chartDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.chartDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.reportDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.reportDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.reportDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.reportDefinition.dataViewPersistentId;
        }

        LeopardAjaxHelper.CreateDataView(organizationId, dataToImport.dataViewName,
            dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
            dataToImport.dataViewId, dataViewPersistentId, function () {
                LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                    that.state.dataViewNamePrefixForImport + dataToImport.dataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                    dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl,
                    dataToImport.dataViewId, dataViewPersistentId, function () {
                        if (dataViewListToImport.length === processedIndex + 1) {
                            that.batchProcessCompletedForDataViews = true;

                            if ((LDH.IsObjectNull(checkDependency) || checkDependency === false) || (checkDependency &&
                                that.batchProcessCompletedForWorkspaces && that.batchProcessCompletedForDataViews)) {
                                LRH.ShowToast("Your Data Views / Workspaces have been successfully imported.", "success", 5000);

                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility);

                                that.loadLeftMenuItems(false, null, false, userProfileCache);
                                that.setState({showLoadingProgress: false}, function () {
                                    that.batchProcessCompletedForWorkspaces = true;
                                    that.batchProcessCompletedForDataViews = true;
                                });
                            }
                            if (!LDH.IsObjectNull(callbackComplete)) callbackComplete();
                        }
                        if (dataViewListToImport.length > processedIndex + 1) {
                            that.recurrsiveDataViewImportProcess(userId, organizationId, processedIndex + 1,
                                dataViewListToImport, checkDependency, callbackComplete, userProfileCache);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        LeopardAjaxHelper.DeleteDataView(organizationId, dataToImport.dataViewId, function () {
                        });
                        that.setState({showLoadingProgress: false});
                    }, userProfileCache);
            }, function () {
                LRH.ShowToast("Failed to import your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
    };

    recurrsiveWorkspaceImportProcess = (userId, organizationId, processedIndex, dataViewListToImport, checkDependency,
                                        callbackComplete, userProfileCache) => {
        let that = this;
        let dataToImport = JSON.parse(dataViewListToImport[processedIndex]);
        dataToImport.dashboardId = LDH.GenerateGuid();

        let relationships = dataToImport.relationships;
        if (LDH.IsObjectNull(relationships)) relationships = [];

        LeopardAjaxHelper.CreateDashboard(organizationId, dataToImport.dashboardName, dataToImport.dashboardNote,
            dataToImport.dashboardId, null, dataToImport.dashboardLayout, relationships, function () {
                // Then create menu item for that workspace.
                LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                    that.state.dataViewNamePrefixForImport + dataToImport.dashboardName, dataToImport.dashboardId, function () {
                        if (dataViewListToImport.length === processedIndex + 1) {
                            that.batchProcessCompletedForWorkspaces = true;

                            if ((LDH.IsObjectNull(checkDependency) || checkDependency === false) || (checkDependency &&
                                that.batchProcessCompletedForWorkspaces && that.batchProcessCompletedForDataViews)) {
                                LRH.ShowToast("Your Data Views / Workspaces have been successfully imported.", "success", 5000);
                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility);

                                that.loadLeftMenuItems(false, null, false, userProfileCache);
                                that.setState({showLoadingProgress: false}, function () {
                                    that.batchProcessCompletedForWorkspaces = true;
                                    that.batchProcessCompletedForDataViews = true;
                                });
                            }
                            if (!LDH.IsObjectNull(callbackComplete)) callbackComplete();
                        }
                        if (dataViewListToImport.length > processedIndex + 1) {
                            that.recurrsiveWorkspaceImportProcess(userId, organizationId, processedIndex + 1,
                                dataViewListToImport, checkDependency, callbackComplete, userProfileCache);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        LeopardAjaxHelper.DeleteDashboard(organizationId, dataToImport.dashboardId, function () {
                        });
                        that.setState({showLoadingProgress: false});
                    }, userProfileCache);
            }, function () {
                LRH.ShowToast("Failed to import your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
    };

    createDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if ((this.state.createDataViewType === "chart" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
            (that.state.selectedDataSourceObject !== null && that.state.selectedDataSourceObject.category === "data-table" &&
                this.state.createDataViewType === "datagrid") || this.state.showMenuOptionPanel.dataViewCategory === "report" ||
            this.state.createDataViewType === "photo") {
            that.setState({showLoadingProgress: true}, function () {
                let dataViewId = LDH.GenerateGuid();
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                let definition = null;
                let devExtremeChartType = null;
                let dataViewPersistentId = LDH.GenerateGuid();

                let dataSourceUrl = that.state.createDataViewDataSourceUrl;
                if (LDH.IsValueEmpty(that.state.dataSourceCustomQueryInput) === false) {
                    dataSourceUrl = that.state.dataSourceCustomQueryInput;
                }

                if (that.state.createDataViewChartType === "bar-chart") {
                    devExtremeChartType = "bar";
                }
                if (that.state.createDataViewChartType === "line-chart") {
                    devExtremeChartType = "line";
                }
                if (that.state.createDataViewChartType === "spline-chart") {
                    devExtremeChartType = "spline";
                }
                if (that.state.createDataViewChartType === "scatter-chart") {
                    devExtremeChartType = "scatter";
                }
                if (that.state.createDataViewChartType === "area-chart") {
                    devExtremeChartType = "area";
                }

                if (that.state.createDataViewType === "chart") {
                    definition = {
                        chartType: that.state.createDataViewChartType,
                        chartDefinition: {
                            ...LeopardStaticUIConfig.ChartDefaultDefinition,
                            chartTitle: that.state.createDataViewName,
                            seriesArgumentField: that.state.createDataViewChartArgumentField,
                            seriesValueField: that.state.createDataViewChartValueField,
                            dateRangeValue: that.state.selectDateRangeForCreateChart,
                            dateRangeField: that.state.createDataViewChartDateRangeField,
                            customQueryAttributes: that.state.customQueryAttributesForChart,
                            seriesTypeOfSeries: devExtremeChartType,
                            numberOfSeriesOnChart: that.state.numberOfSeriesOnChart,
                            dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "datagrid") {
                    definition = {
                        gridViewDefinition: LeopardStaticUIConfig.GridViewDefaultDefinition,
                        styleDefinition: null
                    };

                    let userProfile = window.userProfile;
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                    let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

                    if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                        defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
                    }
                    definition.gridViewDefinition.filterBuilderValue = defaultFilter;
                    definition.gridViewDefinition.dataSourceCustomQueryTableSchema =
                        that.state.dataSourceCustomQueryTableSchemaInput;

                    definition.gridViewDefinition.customComputedColumns = that.state.customComputedColumns;
                    definition.gridViewDefinition.dataSourceId = that.state.createDataViewDataSourceId;
                    definition.gridViewDefinition.dataSourceURI = that.state.createDataViewDataSourceURI;
                    definition.gridViewDefinition.topLevelFilteringField = that.state.topLevelFilteringField;
                    definition.gridViewDefinition.dataViewPersistentId = dataViewPersistentId;
                }

                if (that.state.createDataViewType === "report") {
                    definition = {
                        reportLayout: [],
                        reportName: that.state.createDataViewName,
                        operation: that.state.disableScheduledReportOption ? "DELETE" : "MODIFY",
                        reportFormat: "pdf",
                        dataViewId,
                        tableName: dataSourceUrl,
                        oDataParametersForNormalReport: that.state.oDataParametersForNormalReport,
                        oDataParametersForScheduledReport: that.state.oDataParametersForScheduledReport,
                        timezone: that.state.timezoneForReport,
                        timezoneName: that.state.timezoneNameForReport,
                        daylightSaving: that.state.daylightSavingForReport,
                        clientSideQueryForReport: that.state.clientSideQueryForReport,
                        clientSideUILogicForReport: that.state.clientSideUILogicForReport,
                        scriptShapingForReport: that.state.scriptShapingForReport,
                        cronExpression: that.state.cronExpressionForReport,
                        recipients: that.state.recipientsForReport,
                        sender: LeopardStaticUIConfig.SourceEmailAddress,
                        scheduledReportEnabled: !that.state.disableScheduledReportOption
                    };
                }

                if (that.state.createDataViewType === "photo") {
                    definition = {
                        photoDefinition: {
                            heightOfThumbnail: that.state.heightOfThumbnailForPhotoGallery,
                            widthOfThumbnail: that.state.widthOfThumbnailForPhotoGallery,
                            parentDataSourceId: that.state.parentDataSourceIdForPhotoGallery,
                            dataViewPersistentId
                        }
                    };
                }

                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName, that.state.createDataViewType,
                        definition, dataSourceUrl, dataViewId, dataViewPersistentId, function () {
                            LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                                that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                                that.state.createDataViewType, {chartType: that.state.createDataViewChartType},
                                dataSourceUrl, dataViewId, dataViewPersistentId, function () {
                                    LRH.ShowToast("Your Data View has been successfully created.", "success", 5000);

                                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                        that.state.categoryReportsVisibility);

                                    that.loadLeftMenuItems(false, null, false, profile);
                                    that.setState({showLoadingProgress: false});
                                }, function () {
                                    LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                        "Please check your network connection status and try again.", "error", 5000);

                                    LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                                        that.setState({showLoadingProgress: false});
                                    });
                                }, profile);
                        }, function () {
                            LRH.ShowToast("Failed to create your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                        });
                }, function () {
                    LRH.ShowToast("Failed to retrieve user profile.", "error", 5000);
                    that.setState({showLoadingProgress: false});
                });
            });
        } else {
            that.cloneDataViewApplyButtonOnClick(e);
        }
    };

    createDataViewDeleteButtonOnClick = () => {
        let that = this;
        if (that.scheduledReportDisabled === false) {
            LRH.ShowToast("The data view cannot be deleted. Please disable the Scheduled Report " +
                "option and save the settings, then try again.", "error", 5000);
            return false;
        }

        LRH.ShowDialog("Are you sure you want to delete this Data View? <br/>This will impact any Workspaces that are linked to it.",
            "Confirm Delete", function () {
                that.setState({showLoadingProgress: true}, function () {
                    let dataViewId = that.state.editDataViewId;
                    let userProfile = window.userProfile;
                    let userId = LDH.GetUserIdFromUserProfile(userProfile);
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                    LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                        LeopardAjaxHelper.DeleteLeftMenuItemForDataView(userId, organizationId, dataViewId, function () {
                            LRH.ShowToast("Your Data View has been successfully deleted.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                that.state.categoryReportsVisibility);

                            that.loadLeftMenuItems(true, null, false, profile);
                            that.dataViewOrTemplateMenuItemOnClick({item: null});
                            that.setState({showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to delete your Data View. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        }, profile);
                    }, function () {
                        LRH.ShowToast("Failed to load user profile.", "error", 5000);
                    });
                });
            });
    };

    manageDashboardRelationshipApplyOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let isValid = true;

        $(".leopard-option-panel-block.error").each(function () {
            if ($(this).is(":visible")) isValid = false;
        });
        if (isValid === false) {
            LRH.ShowToast("Failed to save your data. Please correct the errors before proceeding.", "error", 5000);
            return;
        }

        for (let i = 0; i < that.state.dashboardEditManageRelationship.length; i++) {
            let relationship = that.state.dashboardEditManageRelationship[i];
            if (LDH.IsValueEmpty(relationship.parentDataViewId) ||
                LDH.IsValueEmpty(relationship.childDataViewId)) {
                LRH.ShowToast("Failed to save your data. Please check for your configuration settings.", "error", 5000);
                return;
            }
        }

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let relationship = that.state.dashboardEditManageRelationship;
            let dashboardId = that.state.editDashboardId;

            LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                LeopardAjaxHelper.UpdateDashboardRelationship(userId, organizationId, dashboardId, relationship, function () {
                    LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                        that.state.editDashboardId, function (menuItem) {
                            LRH.ShowToast("Your workspace has been successfully updated.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                that.state.categoryReportsVisibility);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            }, true, profile);
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to update your workspace. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        }, profile);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your workspace. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                }, profile);
            }, function () {
                LRH.ShowToast("Failed to retrieve user profile.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
        });
    };

    editDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        this.setState({showLoadingProgress: true}, function () {
            let dataViewId = that.state.editDataViewId;
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let dataViewPersistentId = that.state.dataViewPersistentId;
            if (LDH.IsObjectNull(dataViewPersistentId) || LDH.IsValueEmpty(dataViewPersistentId)) {
                dataViewPersistentId = LDH.GenerateGuid();
            }

            let definition = null;
            if (that.state.editDataViewType === "photo") {
                definition = {
                    photoDefinition: {
                        heightOfThumbnail: that.state.heightOfThumbnailForPhotoGallery,
                        widthOfThumbnail: that.state.widthOfThumbnailForPhotoGallery,
                        parentDataSourceId: that.state.parentDataSourceIdForPhotoGallery,
                        dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "report") {
                definition = {
                    reportName: that.state.createDataViewName,
                    operation: that.state.disableScheduledReportOption ? "DELETE" : "MODIFY",
                    tableName: that.state.createDataViewDataSourceUrl,
                    reportFormat: "pdf",
                    oDataParametersForNormalReport: that.state.oDataParametersForNormalReport,
                    oDataParametersForScheduledReport: that.state.oDataParametersForScheduledReport,
                    timezone: that.state.timezoneForReport,
                    timezoneName: that.state.timezoneNameForReport,
                    daylightSaving: that.state.daylightSavingForReport,
                    clientSideQueryForReport: that.state.clientSideQueryForReport,
                    clientSideUILogicForReport: that.state.clientSideUILogicForReport,
                    scriptShapingForReport: that.state.scriptShapingForReport,
                    cronExpression: that.state.cronExpressionForReport,
                    recipients: that.state.recipientsForReport,
                    sender: LeopardStaticUIConfig.SourceEmailAddress,
                    scheduledReportEnabled: !that.state.disableScheduledReportOption,
                    dataViewCustomComments: that.state.dataViewCustomComments
                };
            } else if (that.state.editDataViewType === "chart") {
                definition = {
                    chartDefinition: {
                        clientSideQueryEditor: that.state.clientSideQueryEditor,
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            } else if (that.state.editDataViewType === "datagrid") {
                definition = {
                    gridViewDefinition: {
                        clientSideQuery: that.state.clientSideQueryEditor,
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            }

            LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                LeopardAjaxHelper.UpdateDataView(userId, organizationId, that.state.editDataViewName, dataViewId,
                    definition, function () {
                        LeopardAjaxHelper.UpdateLeftMenuItemForDataView(userId, organizationId,
                            that.state.editDataViewName, dataViewId, dataViewPersistentId, function (menuItem) {
                                LRH.ShowToast("Your Data View has been successfully updated.", "success", 5000);

                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility);

                                that.loadLeftMenuItems(false, function () {
                                    that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                    that.setState({showLoadingProgress: false});
                                }, false, profile);
                            }, function () {
                                LRH.ShowToast("Failed to update your Data View. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                                that.setState({showLoadingProgress: false});
                            }, that.state.dataViewMenuCategorySelect, profile);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to update your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    }, profile);
            }, function () {
                LRH.ShowToast("Failed to retrieve user profile.", "success", 5000);
                that.setState({showLoadingProgress: false});
            });
        });
    };

    createDataViewCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                    that.state.categoryReportsVisibility);
            });
    };

    createDataViewCancelButtonOnClickWithoutConfirm = () => {
        let that = this;
        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
            that.state.categoryReportsVisibility);
    };

// ==================== Dashboards =======================

    createDashboardCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                    that.state.categoryReportsVisibility);
            });
    };

    createDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let dashboardId = LDH.GenerateGuid();
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            let dataViewIds = [];
            let dashboardLayout = [];
            let xValue = -72;
            let yValue = 0;

            for (let i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.selectedValue === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.selectedValue, index: dataView.index});

                if (xValue + 72 > 144) {
                    xValue = 0;
                    yValue += 1;
                } else {
                    xValue += 72;
                }

                dashboardLayout.push({
                    i: i.toString(), x: xValue, y: yValue, w: 72, h: 36, minW: 18, minH: 12, definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewPersistentId: dataView.item.dataViewPersistentId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: LDH.GenerateGuid(),
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        customDataViewTitle: dataView.customDataViewTitle,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0 || dashboardLayout.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your workspace.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                LeopardAjaxHelper.CreateDashboard(organizationId, that.state.createDashboardName, null,
                    dashboardId, dataViewIds, dashboardLayout, [], function () {
                        // Then create menu item for that data view.
                        LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                            that.state.createDashboardName, dashboardId, function () {
                                LRH.ShowToast("Your workspace has been successfully created.", "success", 5000);
                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility);

                                that.loadLeftMenuItems(false, null, false, profile);
                                that.setState({showLoadingProgress: false});
                            }, function (error, sessionTimeout) {
                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                } else {
                                    LRH.ShowToast("Failed to create your workspace. " +
                                        "Please check your network connection status and try again.", "error", 5000);
                                }
                                LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                                    that.setState({showLoadingProgress: false});
                                }, function () {
                                    that.setState({showLoadingProgress: false});
                                });
                            }, profile);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to create your workspace. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    });
            }, function () {
                LRH.ShowToast("Failed to retrieve user profile.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
        });
    };

    editDashboardSaveSettings = () => {
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let dataViewIds = [];
            let dashboardLayout = [];

            for (let i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.selectedValue === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.selectedValue, index: dataView.index});

                if (LDH.IsObjectNull(dataView.dashboardItemId) || LDH.IsValueEmpty(dataView.dashboardItemId)) {
                    dataView.dashboardItemId = LDH.GenerateGuid();
                }

                dashboardLayout.push({
                    i: i.toString(), definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: dataView.dashboardItemId,
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        customDataViewTitle: dataView.customDataViewTitle,
                        dataViewPersistentId: dataView.item.dataViewPersistentId,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0 || dashboardLayout.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your workspace.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                LeopardAjaxHelper.UpdateDashboard(userId, organizationId, that.state.editDashboardId, dashboardLayout,
                    that.state.editDataViewName, function () {
                        LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                            that.state.editDashboardId, function (menuItem) {
                                LRH.ShowToast("Your workspace has been successfully updated.", "success", 5000);
                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility);

                                that.loadLeftMenuItems(false, function () {
                                    that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                    that.setState({showLoadingProgress: false});
                                }, true, profile);
                            }, function (error, sessionTimeout) {
                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                } else {
                                    LRH.ShowToast("Failed to update your workspace. " +
                                        "Please check your network connection status and try again.", "error", 5000);
                                }
                                that.setState({showLoadingProgress: false});
                            }, profile);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to update your workspace. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    }, that.state.isResetDashboardRelationshipsOnSave, profile);
            }, function () {
                LRH.ShowToast("Failed to retrieve user profile.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
        });
    };

    editDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (this.state.isResetDashboardRelationshipsOnSave) {
            LRH.ShowDialog("You have changed the structure of your workspace, this will reset all the relationships of the workspace." +
                "<br/>Are you sure you want to continue?", "Confirm Update", function () {
                that.editDashboardSaveSettings(e);
            });
        } else {
            this.editDashboardSaveSettings(e);
        }
    };

    createDashboardDeleteButtonOnClick = (dialogMessage, dashboardIdToDelete) => {
        let that = this;

        if (LDH.IsValueEmpty(dialogMessage) === true) {
            dialogMessage = "Are you sure you want to delete this workspace?";
        }
        if (LDH.IsValueEmpty(dashboardIdToDelete) === true) {
            dashboardIdToDelete = that.state.editDashboardId;
        }

        LRH.ShowDialog(dialogMessage, "Confirm Delete", function () {
            that.setState({showLoadingProgress: true}, function () {
                let dashboardId = dashboardIdToDelete;
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    LeopardAjaxHelper.DeleteLeftMenuItemForDashboard(userId, organizationId, dashboardId, function () {
                        LRH.ShowToast("Your workspace has been successfully deleted.", "success", 5000);
                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                            that.state.categoryReportsVisibility);

                        that.loadLeftMenuItems(true, null, false, profile);
                        that.dataViewOrTemplateMenuItemOnClick({item: null});
                        that.setState({showLoadingProgress: false});
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to delete your Dashbaord. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    }, profile);
                }, function () {
                    LRH.ShowToast("Failed to load user profile.", "error", 5000);
                });
            });
        });
    };

    cloneDataViewSelectInputOnChanged = (e) => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            let dataViewId = e.value;
            let viewObject = null;
            for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                let dataView = that.state.availableDataViewListForEdit[i];
                if (dataView.id === e.value) {
                    dataViewId = dataView.id;
                    viewObject = dataView;
                }
            }
            for (let j = 0; j < that.state.availableReportListForEdit.length; j++) {
                let dataView = that.state.availableReportListForEdit[j];
                if (dataView.id === e.value) {
                    dataViewId = dataView.id;
                    viewObject = dataView;
                }
            }
            if (LDH.IsObjectNull(viewObject) || LDH.IsValueEmpty(dataViewId)) {
                LRH.ShowToast("Failed to retrieve Data View details. " +
                    "Your Data View could not be found.", "error", 5000);
                return;
            }

            if (viewObject.category !== "dashboard") {
                LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            } else {
                LeopardAjaxHelper.GetDashboardById(userId, dataViewId, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            }
        });
    };

    exportDataViewSelectInputOnChanged = (e) => {
        let that = this;
        let dataViewList = e.value;
        let dataViewsToExport = [];
        if (LDH.IsValueEmpty(dataViewList) || dataViewList.length === 0) {
            that.setState({dataViewJSONToExport: null});
            return;
        }
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

            LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                let dataViewProcessedCount = 0;

                for (let v = 0; v < dataViewList.length; v++) {
                    let dataViewId = dataViewList[v];
                    let viewObject = null;
                    for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                        let dataView = that.state.availableDataViewListForEdit[i];
                        if (dataView.id === dataViewId) {
                            viewObject = dataView;
                        }
                    }
                    for (let j = 0; j < that.state.availableReportListForEdit.length; j++) {
                        let dataView = that.state.availableReportListForEdit[j];
                        if (dataView.id === dataViewId) {
                            viewObject = dataView;
                        }
                    }
                    if (LDH.IsObjectNull(viewObject) || LDH.IsValueEmpty(dataViewId)) {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Your Data View could not be found.", "error", 5000);
                        that.setState({showLoadingProgress: false});
                        return;
                    }
                    if (viewObject.category !== "dashboard") {
                        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (result) {
                            dataViewProcessedCount++;
                            dataViewsToExport.push({id: dataViewId, data: result});

                            if (dataViewProcessedCount >= dataViewList.length) {
                                that.setState({showLoadingProgress: false, dataViewJSONToExport: dataViewsToExport});
                            }
                        }, function (error, sessionTimeout) {
                            dataViewProcessedCount++;
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve Data View details. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            if (dataViewProcessedCount >= dataViewList.length) {
                                that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                            }
                        }, profile);
                    } else {
                        LeopardAjaxHelper.GetDashboardById(userId, dataViewId, function (result) {
                            dataViewProcessedCount++;
                            dataViewsToExport.push({id: dataViewId, data: result});

                            if (dataViewProcessedCount >= dataViewList.length) {
                                that.setState({showLoadingProgress: false, dataViewJSONToExport: dataViewsToExport});
                            }
                        }, function (error, sessionTimeout) {
                            dataViewProcessedCount++;
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve Data View details. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            if (dataViewProcessedCount >= dataViewList.length) {
                                that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                            }
                        }, profile);
                    }
                }
            }, function () {
                LRH.ShowToast("Failed to retrieve user profile.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuCategoryOnClick = (data) => {
        if (data.category === "workspaces") {
            let visible = this.state.categoryWorkspacesVisibility;
            this.setState({categoryWorkspacesVisibility: !visible});
        } else if (data.category === "default-view") {
            let visible = this.state.categoryDefaultViewsVisibility;
            this.setState({categoryDefaultViewsVisibility: !visible});
        } else if (data.category === "data-view") {
            let visible = this.state.categoryDataViewsVisibility;
            this.setState({categoryDataViewsVisibility: !visible});
        } else if (data.category === "system-view") {
            let visible = this.state.categorySystemViewsVisibility;
            this.setState({categorySystemViewsVisibility: !visible});
        } else if (data.category === "report") {
            let visible = this.state.categoryReportsVisibility;
            this.setState({categoryReportsVisibility: !visible});
        }
    };

// =======================================================================
// ============================ UI Rendering =============================
// =======================================================================
    buildRecurrenceSettingFields = () => {
        return (
            <div className="leopard-option-panel-block">
                <div className={"leopard-leftmenu-settings-section"}>
                    <div style={{paddingLeft: "24px"}}>
                        <span>Recurrence settings</span>
                        <span style={{marginLeft: "5px"}}>
                        <LeopardTooltipWithIcon
                            elementId={"cronExpressionForReportInput_help"}
                            title={"Recurrence settings"}
                            text={"Specify a cron expression for setting up a recurring scheduled report."}/>
                        </span>
                    </div>
                </div>
                <div>
                    <div style={{paddingLeft: "24px", display: "flex"}}>
                        <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart1Input"}
                                     placeholder={"Min"} width={45}
                                     value={this.state.cronExpressionForReportPart1}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart1Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart1Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart2Input"}
                                     placeholder={"Hr"} width={45}
                                     value={this.state.cronExpressionForReportPart2}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart2Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart2Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart3Input"}
                                     placeholder={"DoM"} width={45}
                                     value={this.state.cronExpressionForReportPart3}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart3Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart3Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart4Input"}
                                     placeholder={"Mth"} width={45}
                                     value={this.state.cronExpressionForReportPart4}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart4Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart4Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                        <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart5Input"}
                                     placeholder={"DoW"} width={45}
                                     value={this.state.cronExpressionForReportPart5}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart5Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart5Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                        <span style={{float: "left"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart6Input"}
                                     placeholder={"Yr"} width={60}
                                     value={this.state.cronExpressionForReportPart6}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart6Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart6Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                                <TextBoxButton
                                    name={"cronExpressionForReportPart6Input_MiniButton"}
                                    location={'after'}
                                    options={{
                                        icon: "fas fa-question",
                                        type: 'default',
                                        onClick: () => {
                                            window.open(LeopardStaticUIConfig.AWSCronExpressionUrl);
                                        }
                                    }}
                                />
                            </TextBox>
                        </span>
                    </div>
                </div>
            </div>
        )
    };

    buildLeopardMenuDataViews = (dataViewMenuItems, dataViewCategory) => {
        if (dataViewMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            let categoryVisible = false;
            if (dataViewCategory === "default-view") {
                categoryVisible = this.state.categoryDefaultViewsVisibility;
            } else if (dataViewCategory === "data-view") {
                categoryVisible = this.state.categoryDataViewsVisibility;
            } else if (dataViewCategory === "system-view") {
                categoryVisible = this.state.categorySystemViewsVisibility;
            } else if (dataViewCategory === "report") {
                categoryVisible = this.state.categoryReportsVisibility;
            }

            return (
                <React.Fragment>
                    <div className={"dataViewCategory_" + dataViewCategory}>
                        <LeopardMenuDataViewList menuItems={dataViewMenuItems} categoryVisible={categoryVisible}
                                                 dataViewCategory={dataViewCategory}
                                                 menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                                 selectedMenuItem={this.props.state.selectedMenuItem}
                                                 menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                                 selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    buildLeopardMenuTemplates = (dataViewCategory) => {
        if (this.templateMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            let categoryVisible = this.state.categoryWorkspacesVisibility;
            return (
                <React.Fragment>
                    <div
                        className={"dataViewCategory_" + dataViewCategory}>
                        <LeopardMenuTemplateList menuItems={this.templateMenuItems} categoryVisible={categoryVisible}
                                                 menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                                 selectedMenuItem={this.props.state.selectedMenuItem}
                                                 menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                                 selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    buildMenuOptionPanelEditDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Edit Data View
                </div>
                <form onSubmit={this.editDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        {
                            this.state.showMenuOptionPanel.dataViewCategory !== "report" ? "" :
                                <React.Fragment>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">1.</i>
                                            <span>Select data source</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon elementId={"selectChartTypeInput_help"}
                                                                        title={"Select data source"}
                                                                        text={"Select a data source for the data view to access, so the user will be able to view the data from that data source."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"selectDataSourceInput"}
                                                       grouped={true}
                                                       value={this.state.createDataViewDataSourceUrl}
                                                       dataSource={new DataSource({
                                                           store: this.state.availableDataSourceList,
                                                           key: 'id',
                                                           group: 'group'
                                                       })}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectDataSourceInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewDataSourceUrlOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataSourceInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>OData parameters</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersForNormalReportInput_help"}
                                                    title={"OData parameters"}
                                                    text={"Specify OData parameters for retrieving data from the database."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"oDataParametersForNormalReportInput"}
                                                      value={this.state.oDataParametersForNormalReport}
                                                      placeholder={"$filter=...&$apply=..."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "oDataParametersForNormalReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.oDataParametersForNormalReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex" style={{float: "left"}}>3.</i>
                                            <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                <span>Scheduled report</span>
                                                <span style={{float: "right"}}>
                                                    <Switch value={!this.state.disableScheduledReportOption}
                                                            width={"60px"}
                                                            switchedOffText={"OFF"} switchedOnText={"ON"}
                                                            id={"disableScheduledReportOption"}
                                                            disabled={this.state.showLoadingProgress}
                                                            onValueChanged={(e) => this.disableScheduledReportOptionOnValueChanged(e)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            this.state.disableScheduledReportOption === true ? "" :
                                                <React.Fragment>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>OData parameters for scheduled report</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParametersForScheduledReportInput_help"}
                                                                        title={"OData parameters"}
                                                                        text={"Specify OData parameters for retrieving data from the database."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"oDataParametersForScheduledReportInput"}
                                                                          value={this.state.oDataParametersForScheduledReport}
                                                                          placeholder={"$filter=...&$apply=..."}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "oDataParametersForScheduledReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.oDataParametersForScheduledReportOnValueChanged(e)}>
                                                                    <Validator>
                                                                        <RequiredRule type="custom" validationCallback={
                                                                            (e) => this.customValidationRuleCallback({
                                                                                e,
                                                                                input: "oDataParametersForScheduledReportInput",
                                                                                rules: [
                                                                                    {rule: "safeinput"}
                                                                                ]
                                                                            })
                                                                        }/>
                                                                    </Validator>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.buildRecurrenceSettingFields()
                                                    }
                                                    <div className="leopard-option-panel-block" style={{display: "none"}}>
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Timezone</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"timezoneForReportInput_help"}
                                                                    title={"Timezone"}
                                                                    text={"Specify a timezone for the scheduled report."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <SelectBox displayExpr={'name'}
                                                                           id={"timezoneForReportSelect"}
                                                                           value={this.state.timezoneForReport}
                                                                           dataSource={LeopardDropdownHelper.DropdownSelectionTimeZone}
                                                                           ref={(e) => this.setInputFieldInstance({
                                                                               e,
                                                                               input: "timezoneForReportSelect"
                                                                           })}
                                                                           valueExpr={'id'}
                                                                           disabled={this.state.showLoadingProgress}
                                                                           onValueChanged={
                                                                               (e) => this.timezoneForReportSelectOnValueChanged(e)
                                                                           }>
                                                                </SelectBox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block" style={{display: "none"}}>
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Daylight saving offset</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"daylightSavingForReportInput_help"}
                                                                    title={"Daylight saving offset"}
                                                                    text={"Specify a daylight saving offset for the scheduled report."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <NumberBox disabled={this.state.showLoadingProgress}
                                                                           min={-10} max={10}
                                                                           value={this.state.daylightSavingForReport}
                                                                           id={"daylightSavingForReportInput"}
                                                                           showSpinButtons={true}
                                                                           ref={(e) => this.setInputFieldInstance({
                                                                               e,
                                                                               input: "daylightSavingForReportInput"
                                                                           })}
                                                                           onValueChanged={(e) => this.daylightSavingForReportInputOnValueChanged(e)}>
                                                                </NumberBox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Timezone</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"timezoneNameForReportInput_help"}
                                                                    title={"Timezone"}
                                                                    text={"Specify a timezone for the scheduled report."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <SelectBox displayExpr={'name'}
                                                                           id={"timezoneNameForReportSelect"}
                                                                           value={this.state.timezoneNameForReport}
                                                                           dataSource={this.timezoneDropdownList}
                                                                           ref={(e) => this.setInputFieldInstance({
                                                                               e,
                                                                               input: "timezoneNameForReportSelect"
                                                                           })}
                                                                           valueExpr={'id'}
                                                                           disabled={this.state.showLoadingProgress}
                                                                           onValueChanged={
                                                                               (e) => this.timezoneNameForReportSelectOnValueChanged(e)
                                                                           }>
                                                                    <Validator>
                                                                        <RequiredRule type="custom" validationCallback={
                                                                            (e) => this.customValidationRuleCallback({
                                                                                e,
                                                                                input: "timezoneNameForReportSelect",
                                                                                rules: [
                                                                                    {rule: "required"}
                                                                                ]
                                                                            })
                                                                        }/>
                                                                    </Validator>
                                                                </SelectBox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Specify recipients</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"recipientsForReportInput_help"}
                                                                    title={"Specify recipients"}
                                                                    text={"Specify a list of recipients (email addresses) for receiving the scheduled report."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"recipientsForReportInput"}
                                                                          value={this.state.recipientsForReport}
                                                                          placeholder={"email1@company.com.au ; email2@company.com.au ;"}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "recipientsForReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.recipientsForReportInputOnValueChanged(e)}>
                                                                    <Validator>
                                                                        <RequiredRule type="custom" validationCallback={
                                                                            (e) => this.customValidationRuleCallback({
                                                                                e,
                                                                                input: "recipientsForReportInput",
                                                                                rules: [
                                                                                    {rule: "safeinput"},
                                                                                    {rule: "required"},
                                                                                    {rule: "emailaddress-list"}
                                                                                ]
                                                                            })
                                                                        }/>
                                                                    </Validator>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        }
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Query shaping</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"scriptShapingForReportInput_help"}
                                                    title={"Query shaping"}
                                                    text={"Write JavaScript code to manipulate query before it sends to the server."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"scriptShapingForReportInput"}
                                                      value={this.state.scriptShapingForReport}
                                                      placeholder={"Use the variable \"odataParams\", \"parameters\" and \"window.report_input_variables\" to access data and user inputs."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "scriptShapingForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.scriptShapingForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">5.</i>
                                            <span>Post data processing</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideQueryForReportInput_help"}
                                                    title={"Post data processing"}
                                                    text={"Write JavaScript code to manipulate data on the client-side."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"clientSideQueryForReportInput"}
                                                      value={this.state.clientSideQueryForReport}
                                                      placeholder={"Use the variable \"data\" to access the data."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "clientSideQueryForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.clientSideQueryForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">6.</i>
                                            <span>UI component processing</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideUILogicForReportInput_help"}
                                                    title={"UI component processing"}
                                                    text={"Write JavaScript code to change report's UI on the client-side."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"clientSideUILogicForReportInput"}
                                                      value={this.state.clientSideUILogicForReport}
                                                      placeholder={"Use the variable \"report\" to access the data."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "clientSideUILogicForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.clientSideUILogicForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                </React.Fragment>
                        }
                        <React.Fragment>
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {this.state.showMenuOptionPanel.dataViewCategory !== "report" ? "1." : "7."}
                                    </i>
                                    <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Rename data view" : ""
                                    }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Rename system view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Rename default view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Rename report" : ""
                                        }
                                </span>
                                    <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Rename data view"}
                                                                        text={"Give a name for your data view. The length of this field is limited to 50 characters."}>
                                                </LeopardTooltipWithIcon>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Rename system view"}
                                                                        text={"Give a name for your system view. The length of this field is limited to 50 characters."}>
                                                </LeopardTooltipWithIcon>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Rename default view"}
                                                                        text={"Give a name for your default view. The length of this field is limited to 50 characters."}>
                                                </LeopardTooltipWithIcon>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Rename report"}
                                                                        text={"Give a name for your report. The length of this field is limited to 50 characters."}>
                                                </LeopardTooltipWithIcon>
                                                : null
                                        }
                                    </span>
                                </div>
                                <div>
                                    <TextBox defaultValue={this.state.editDataViewName} maxLength={50}
                                             disabled={this.state.showLoadingProgress} id={"dataViewNameInput"}
                                             ref={(e) => this.setInputFieldInstance({e, input: "dataViewNameInput"})}
                                             onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e, input: "dataViewNameInput", rules: [
                                                        {rule: "required"},
                                                        {rule: "safeinput"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            {(this.state.showMenuOptionPanel.dataViewCategory === "report" ||
                                this.state.editDataViewType === "photo") ? null :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">2.</i>
                                        <span>Post data processing</span>
                                        <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"clientSideQueryEditor_help"}
                                                title={"Post data processing"}
                                                text={"Write a custom logic in Javascript to process the data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                    </div>
                                    <div>
                                        <TextArea disabled={this.state.showLoadingProgress} height={"250px"}
                                                  id={"clientSideQueryEditorInput"}
                                                  value={this.state.clientSideQueryEditor}
                                                  placeholder={"Use the variable \"data\" to access the data."}
                                                  ref={(e) => this.setInputFieldInstance({
                                                      e,
                                                      input: "clientSideQueryEditorInputInput"
                                                  })}
                                                  onValueChanged={(e) => this.clientSideQueryEditorOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "clientSideQueryEditorInput",
                                                        rules: [
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextArea>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">2.</i>
                                        <span>Height of thumbnails</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"heightOfThumbnailInput_help"}
                                                                            title={"Height of thumbnails"}
                                                                            text={"Set the height of the thumbnails by pixels."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                   value={this.state.heightOfThumbnailForPhotoGallery}
                                                   id={"heightOfThumbnailForPhotoGalleryInput"}
                                                   showSpinButtons={true} format={"#"}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "heightOfThumbnailForPhotoGalleryInput"
                                                   })}
                                                   onValueChanged={(e) => this.heightOfThumbnailOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "heightOfThumbnailForPhotoGalleryInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">3.</i>
                                        <span>Width of thumbnails</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"widthOfThumbnailInput_help"}
                                                                            title={"Width of thumbnails"}
                                                                            text={"Set the width of the thumbnails by pixels."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                   value={this.state.widthOfThumbnailForPhotoGallery}
                                                   id={"widthOfThumbnailForPhotoGalleryInput"}
                                                   showSpinButtons={true} format={"#"}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "widthOfThumbnailForPhotoGalleryInput"
                                                   })}
                                                   onValueChanged={(e) => this.widthOfThumbnailOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "heightOfThumbnailForPhotoGalleryInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">4.</i>
                                        <span>Parent data source ID</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"parentDataSourceIdForPhotoInput_help"}
                                                        title={"Parent data source ID"}
                                                        text={"Specify the parent data source ID for this Data View."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TextBox disabled={this.state.showLoadingProgress}
                                                 value={this.state.parentDataSourceIdForPhotoGallery}
                                                 id={"parentDataSourceIdForPhotoInput"}
                                                 ref={(e) => this.setInputFieldInstance({
                                                     e,
                                                     input: "parentDataSourceIdForPhotoInput"
                                                 })}
                                                 onValueChanged={(e) => this.parentDataSourceIdOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "parentDataSourceIdForPhotoInput",
                                                        rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                        }
                        {this.state.showMenuOptionPanel.dataViewCategory === "report" ? null :
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {
                                            this.state.editDataViewType === "photo" ? "5." : "3."
                                        }
                                    </i>
                                    <span>Persistent ID</span>
                                    <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewPersistentId_help"}
                                                title={"Persistent ID"}
                                                text={"The Persistent ID is used for associating each Data View in a Workspace."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                </div>
                                <div>
                                    <TextBox value={this.state.dataViewPersistentId} id={"dataViewPersistentIdInput"}
                                             ref={(e) => this.setInputFieldInstance({
                                                 e,
                                                 input: "dataViewPersistentIdInput"
                                             })}
                                             onValueChanged={(e) => this.dataViewPersistentIdOnValueChanged(e)}>
                                        <TextBoxButton
                                            name={"dataViewPersistentIdInput_MiniButton"}
                                            location={'after'} options={{
                                            icon: "fas fa-sync",
                                            type: 'default',
                                            onClick: () => {
                                                this.setState({dataViewPersistentId: LDH.GenerateGuid()});
                                            }
                                        }}
                                        />
                                    </TextBox>
                                </div>
                            </div>
                        }
                        {this.state.showMenuOptionPanel.dataViewCategory === "report" ? null :
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {
                                            this.state.editDataViewType === "photo" ? "6." : "4."
                                        }
                                    </i>
                                    <span>Menu category</span>
                                    <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewPersistentId_help"}
                                                title={"Menu category"}
                                                text={"Move the Data View to the selected category."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                </div>
                                <div>
                                    <SelectBox displayExpr={'name'} id={"dataViewMenuCategorySelect"}
                                               value={this.state.dataViewMenuCategorySelect}
                                               dataSource={LeopardDropdownHelper.DropdownSelectionDataViewMenuCategory}
                                               ref={(e) => this.setInputFieldInstance({
                                                   e,
                                                   input: "dataViewMenuCategorySelect"
                                               })}
                                               valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                               onValueChanged={
                                                   (e) => this.dataViewMenuCategorySelectOnValueChanged(e)
                                               }>
                                    </SelectBox>
                                </div>
                            </div>
                        }
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">
                                    {
                                        this.state.editDataViewType === "photo" ? "7." :
                                            this.state.editDataViewType === "report" ? "8." : "5."
                                    }
                                </i>
                                <span>Comments / Remarks</span>
                                <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewCustomComments_help"}
                                                title={"Comments / Remarks"}
                                                text={"You can enter any custom information you want in this field."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                            </div>
                            <div>
                                <TextArea disabled={this.state.showLoadingProgress} height={"200px"}
                                          id={"dataViewCustomCommentsInput"}
                                          value={this.state.dataViewCustomComments}
                                          onValueChanged={(e) => this.dataViewCustomCommentsOnValueChanged(e)}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Delete'} id={"createDataViewDeleteButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewDeleteButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'} id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'} id={"createDataViewApplyButton"}
                                        disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                            </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    dashboardManageRelationshipAddOnClick = () => {
        let passed = true;
        for (let i = 0; i < this.state.dashboardEditManageRelationship.length; i++) {
            let relationship = this.state.dashboardEditManageRelationship[i];
            if (LDH.IsValueEmpty(relationship.parentDataViewId) ||
                LDH.IsValueEmpty(relationship.childDataViewId)) {
                LRH.ShowToast("Please specify all the parents and children before adding a new entry.", "error", 5000);
                passed = false;
                break;
            }
        }
        if (passed === false) return;

        let relationships = LDH.DeepClone(this.state.dashboardEditManageRelationship);
        relationships.push({
            parentDataViewId: "",
            childDataViewId: "",
            interactiveFeatures: [],
            dataSourceId: "",
            dataColumns: [],
            relationshipId: LDH.GenerateGuid()
        });
        this.setState({dashboardEditManageRelationship: relationships});
    };

    manageRelationshipDeleteItem = (e) => {
        let list = this.state.dashboardEditManageRelationship;
        let result = [];
        for (let i = 0; i < list.length; i++) {
            if (e.item.relationshipId !== list[i].relationshipId) {
                result.push(list[i]);
            }
        }
        this.setState({dashboardEditManageRelationship: result});
    };

    configItemBoxValueOnChange = (data) => {
        if (this.isConfigItemBoxValueOnChangeDisabled === true) {
            this.isConfigItemBoxValueOnChangeDisabled = false;
            return;
        }
        let list = LDH.DeepClone(this.state.dashboardEditManageRelationship);

        for (let i = 0; i < list.length; i++) {
            let dataItem = list[i];
            if (data.item.relationshipId !== dataItem.relationshipId) {
                continue;
            }

            if (data.inputField === "parent") {
                if (LDH.ValidateDashboardRelationships(LDH.DeepClone(list), data.e.value, data.item.childDataViewId,
                    data.item.relationshipId) === false) {
                    if (!LDH.IsObjectNull(this.uiObjectInstance[data.instanceName])) {
                        this.isConfigItemBoxValueOnChangeDisabled = true;
                        this.uiObjectInstance[data.instanceName].option("value", data.e.previousValue);
                        LRH.ShowToast("Circular relationship detected. Your change has been undone.", "error", 5000);
                    }
                    return;
                }

                dataItem.parentDataViewId = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            } else if (data.inputField === "child") {
                if (LDH.ValidateDashboardRelationships(LDH.DeepClone(list), data.item.parentDataViewId, data.e.value,
                    data.item.relationshipId) === false) {
                    if (!LDH.IsObjectNull(this.uiObjectInstance[data.instanceName])) {
                        this.isConfigItemBoxValueOnChangeDisabled = true;
                        this.uiObjectInstance[data.instanceName].option("value", data.e.previousValue);
                        LRH.ShowToast("Circular relationship detected. Your change has been undone.", "error", 5000);
                    }
                    return;
                }

                dataItem.childDataViewId = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            } else if (data.inputField === "features") {
                dataItem.interactiveFeatures = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            }
        }
    };

    buildMenuOptionPanelManageDashboardRelationship = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Manage Relationship
                </div>
                <form onSubmit={this.manageDashboardRelationshipApplyOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Configure relationships from the workspace
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon elementId={"dashboardManageRelationship_help"}
                                                                    title={"Manage relationship"}
                                                                    text={"Create and manage relationships between data views."}/>
                                </span>
                                <i className={"leopard-menuoption-add-button fas fa-plus"} onClick={
                                    (e) => this.dashboardManageRelationshipAddOnClick(e)}
                                />
                            </div>
                        </div>
                        <LeopardMenuConfigItemBox relationships={this.state.dashboardEditManageRelationship}
                                                  dataSource={this.state.selectedDataViewsForDashboard}
                                                  onDelete={(e) => this.manageRelationshipDeleteItem(e)}
                                                  setInputFieldInstance={(e) => this.setInputFieldInstance(e)}
                                                  configItemBoxValueOnChange={(e) => this.configItemBoxValueOnChange(e)}
                                                  showLoadingProgress={this.state.showLoadingProgress}/>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                            </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDashboardOptions = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "edit_template" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-editworkspace"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "edit_template",
                                     currentOption: this.state.showMenuOptionPanel.option
                                 })}>
                                Edit workspace
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "manage_dashboard_relationship" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-editworkspace-relationship"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "manage_dashboard_relationship",
                                     currentOption: this.state.showMenuOptionPanel.option
                                 })}>
                                Manage relationship
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "edit_dashboard_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewOptions = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "add_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-adddataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "add_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Create a new data view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Create a new system view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Create a new default view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Create a new report" : ""
                                }
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "clone_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-clonedataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "clone_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Clone an existing data view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Clone an existing system view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Clone an existing default view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Clone an existing report" : ""
                                }
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "import_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-importdataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "import_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Import data view(s)" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Import system view(s)" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Import default view(s)" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Import report(s)" : ""
                                }
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "export_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-exportdataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "export_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Export a data view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Export a system view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Export a default view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Export a report" : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "add_dataview_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    onSelectedFilesChanged = (e) => {
        let that = this;
        let isBulkImport = false;
        $(".dx-fileuploader-button.dx-fileuploader-upload-button").remove();

        let dataToImport = [];
        let dataToImportDisplay = [{
            key: "Workspaces",
            items: []
        }, {
            key: "Data Views",
            items: []
        }];
        for (let i = 0; i < e.value.length; i++) {
            let reader = new FileReader();
            reader.onload = function (event) {
                let jsonData = JSON.parse(event.target.result);
                if (!LDH.IsObjectNull(jsonData) && Array.isArray(jsonData)) {
                    for (let j = 0; j < jsonData.length; j++) {
                        dataToImport.push(JSON.stringify(jsonData[j]));

                        if (!LDH.IsObjectNull(jsonData[j].dashboardId)) {
                            dataToImportDisplay[0].items.push(jsonData[j]);
                        } else {
                            dataToImportDisplay[1].items.push(jsonData[j]);
                        }
                        isBulkImport = true;
                    }
                } else {
                    dataToImport.push(event.target.result);
                }
                if (e.value.length - 1 === i) {
                    let visible = that.state.numberOfDataViewsToImportVisible;
                    if (isBulkImport) {
                        that.uiObjectInstance["exportDataViewFileUploader"].reset();
                        visible = true;
                    }
                    that.setState({
                        numberOfDataViewsToImportVisible: visible,
                        dataViewListToImport: dataToImport,
                        numberOfDataViewsToImport: dataToImportDisplay
                    }, function () {
                        if (that.state.numberOfDataViewsToImport.length === 0 ||
                            (that.state.numberOfDataViewsToImport[0].items.length === 0 &&
                                that.state.numberOfDataViewsToImport[1].items.length === 0)) {
                            that.setState({numberOfDataViewsToImport: false});
                        }
                    });
                }
            };
            reader.readAsText(e.value[i]);
        }
    };

    buildMenuOptionPanelImportDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Import data view(s)" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Import system view(s)" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Import default view(s)" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Import report(s)" : ""
                    }
                </div>
                <form onSubmit={this.importDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select files to import</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectFilesToImport_help"}
                                                            title={"Select files to import"}
                                                            text={"You can import a data view by selecting a on your PC, you can import multiple data views at once. Please note that the file must be in JSON format and is compatible with Control Centre."}/>
                                    </span>
                            </div>
                            <div>
                                <FileUploader multiple={true} uploadMode={"useButtons"}
                                              readyToUploadMessage={"Ready to import"}
                                              accept={'.json'}
                                              ref={(e) => this.setInputFieldInstance({
                                                  e,
                                                  input: "exportDataViewFileUploader"
                                              })}
                                              onValueChanged={this.onSelectedFilesChanged}/>
                            </div>
                            <div>
                                <div className={"leopard-listcontainer"} style={{marginTop: "5px"}}>
                                    <List allowItemDeleting={true} activeStateEnabled={false}
                                          hoverStateEnabled={false} focusStateEnabled={false}
                                          visible={this.state.numberOfDataViewsToImportVisible}
                                          grouped={true} onItemDeleted={this.numberOfDataViewsToImportOnDeleted}
                                          dataSource={this.state.numberOfDataViewsToImport}
                                          ref={(e) => this.setInputFieldInstance({
                                              e,
                                              input: "numberOfDataViewsToImportListControl"
                                          })}
                                          itemRender={this.numberOfDataViewsToImportTemplate}/>
                                </div>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Data View name prefix</span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={200}
                                         defaultValue={this.state.dataViewNamePrefixForImport}
                                         id={"dataViewNamePrefixForImportInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: "dataViewNamePrefixForImportInput"
                                         })}
                                         onValueChanged={(e) => this.dataViewNamePrefixForImportOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e,
                                                input: "dataViewNamePrefixForImportInput",
                                                rules: [
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress || !this.state.numberOfDataViewsToImportVisible}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCloneDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Clone an existing data view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Clone an existing system view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Clone an existing default view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Clone an existing report" : ""
                    }
                </div>
                <form onSubmit={this.cloneDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Select data view to clone" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Select system view to clone" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Select default view to clone" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Select report to clone" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select data view to clone"}
                                                                    text={"You can clone a data view by selecting an existing view in Control Centre."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select system view to clone"}
                                                                    text={"You can clone a system view by selecting an existing view in Control Centre."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select default view to clone"}
                                                                    text={"You can clone a default view by selecting an existing view in Control Centre."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select report to clone"}
                                                                    text={"You can clone a report by selecting an existing report in Control Centre."}/>
                                            : null
                                    }
                                    </span>
                            </div>
                            <div>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                        <SelectBox dataSource={new DataSource({
                                            store: this.state.availableReportListForEdit, key: 'id', group: 'group'
                                        })} displayExpr={'name'} grouped={true}
                                                   valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                   id={"cloneReportViewSelectInput"}
                                                   onValueChanged={(e) => this.cloneDataViewSelectInputOnChanged(e)}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "cloneReportViewSelectInput"
                                                   })}>
                                        </SelectBox>
                                        :
                                        <SelectBox dataSource={new DataSource({
                                            store: this.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                        })} displayExpr={'name'} grouped={true}
                                                   valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                   id={"cloneDataViewSelectInput"}
                                                   onValueChanged={(e) => this.cloneDataViewSelectInputOnChanged(e)}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "cloneDataViewSelectInput"
                                                   })}>
                                        </SelectBox>
                                }
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Name of data view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Name of system view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Name of default view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Name of report" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of data view"}
                                                                        text={"Give a name for your data view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Name of system view"}
                                                                    text={"Give a name for your system view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Name of default view"}
                                                                    text={"Give a name for your default view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Name of report"}
                                                                    text={"Give a name for your report. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                </span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                         id={"dataViewNameInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: "dataViewNameInput"
                                         })}
                                         onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"createDataViewApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelExportDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Export a data view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Export a system view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Export a default view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Export a report" : ""
                    }
                </div>
                <form onSubmit={this.exportDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Choose an export method
                                </span>
                            </div>
                            <div>
                                <SelectBox
                                    dataSource={LeopardDropdownHelper.DropdownSelectionDataViewExportMethodList}
                                    displayExpr={'name'}
                                    valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                    value={this.state.dataViewExportMethod}
                                    id={"dataViewExportMethodInput"}
                                    ref={(e) => this.setInputFieldInstance({
                                        e,
                                        input: "dataViewExportMethodInput"
                                    })}
                                    onValueChanged={
                                        (e) => this.dataViewExportMethodOnValueChanged(e)
                                    }>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block" style={{
                            display: (this.state.dataViewExportMethod === "individual" ? "block" : "none")
                        }}>
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Select data view to export" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Select system view to export" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Select default view to export" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Select report to export" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                            <LeopardTooltipWithIcon
                                                elementId={"selectFilesToExport_help"}
                                                title={"Select data view to export"}
                                                text={"You can export any data view to a file in JSON format, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon
                                                elementId={"selectFilesToExport_help"}
                                                title={"Select system view to export"}
                                                text={"You can export any system view to a file in JSON format, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon
                                                elementId={"selectFilesToExport_help"}
                                                title={"Select default view to export"}
                                                text={"You can export any default view to a file in JSON format, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon
                                                elementId={"selectFilesToExport_help"}
                                                title={"Select report to export"}
                                                text={"You can export any report to a file, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                </span>
                            </div>
                            <div>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                        <TagBox dataSource={new DataSource({
                                            store: this.state.availableReportListForEdit, key: 'id', group: 'group'
                                        })} showSelectionControls={true} displayExpr={'name'} valueExpr={'id'}
                                                grouped={true} className={"leopard-grouped-selectbox"}
                                                id={"exportDataViewSelectInput"} multiline={false}
                                                disabled={this.state.showLoadingProgress}
                                                maxDisplayedTags={2} showMultiTagOnly={false}
                                                onValueChanged={(e) => this.exportDataViewSelectInputOnChanged(e)}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "exportDataViewSelectInput"
                                                })} applyValueMode={'useButtons'}>
                                        </TagBox>
                                        :
                                        <TagBox dataSource={new DataSource({
                                            store: this.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                        })} showSelectionControls={true} displayExpr={'name'} valueExpr={'id'}
                                                grouped={true} className={"leopard-grouped-selectbox"}
                                                id={"exportDataViewSelectInput"} multiline={false}
                                                disabled={this.state.showLoadingProgress}
                                                maxDisplayedTags={2} showMultiTagOnly={false}
                                                onValueChanged={(e) => this.exportDataViewSelectInputOnChanged(e)}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "exportDataViewSelectInput"
                                                })} applyValueMode={'useButtons'}>
                                        </TagBox>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress ||
                                    (this.state.dataViewExportMethod === "individual" &&
                                        LDH.IsObjectNull(this.state.dataViewJSONToExport))}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateDataView = () => {
        return (
            <React.Fragment>
                <div>
                    <div className="leopard-option-panel-title">
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Create a new data view" : ""
                        }
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Create a new system view" : ""
                        }
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Create a new default view" : ""
                        }
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Create a new report" : ""
                        }
                    </div>
                    <form onSubmit={this.createDataViewApplyButtonOnClick}>
                        <div className="leopard-option-panel-content">
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">1.</i>
                                    <span>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Select a type of data view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Select a type of system view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Select a type of default view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Select a type of report" : ""
                                        }
                                    </span>
                                    <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of data view"}
                                                                        text={"There are many types of data view that the Control Centre supports. " +
                                                                        "Administrators can create a data view with data grids, charts, maps and reports."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of system view"}
                                                                        text={"There are many types of system view that the Control Centre supports. " +
                                                                        "Administrators can create a system view with data grids, charts, maps and reports."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of default view"}
                                                                        text={"There are many types of default view that the Control Centre supports. " +
                                                                        "Administrators can create a default view with data grids, charts, maps and reports."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of report"}
                                                                        text={"There are many types of report that the Control Centre supports."}/>
                                                : null
                                        }
                                    </span>
                                </div>
                                <div>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionReportTypeList}
                                                displayExpr={'name'}
                                                valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                id={"selectDataViewTypeInput"}
                                                value={this.state.createDataViewType}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "selectDataViewTypeInput"
                                                })}
                                                onValueChanged={
                                                    (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                            :
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionDataViewTypeList}
                                                displayExpr={'name'}
                                                valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                value={this.state.createDataViewType}
                                                id={"selectDataViewTypeInput"}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "selectDataViewTypeInput"
                                                })}
                                                onValueChanged={
                                                    (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                    }
                                </div>
                            </div>
                            {
                                ((this.state.createDataViewType === "chart" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
                                    this.state.createDataViewType === "photo") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select data source</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon elementId={"selectChartTypeInput_help"}
                                                                        title={"Select data source"}
                                                                        text={"Select a data source for the data view to access, so the user will be able to view the data from that data source."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"selectDataSourceInput"}
                                                       grouped={true}
                                                       value={this.state.createDataViewDataSourceUrl}
                                                       dataSource={new DataSource({
                                                           store: this.state.availableDataSourceList,
                                                           key: 'id',
                                                           group: 'group'
                                                       })}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectDataSourceInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewDataSourceUrlOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataSourceInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.showMenuOptionPanel.dataViewCategory !== "default-view" || this.state.createDataViewType !== "datagrid" ||
                                    LDH.IsObjectNull(this.state.selectedDataSourceObject) || this.state.selectedDataSourceObject.id !== "custom-query") ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"dataSourceCustomQueryInput"} maxLength={2000}
                                                          value={this.state.dataSourceCustomQueryInput}
                                                          placeholder={"Transaction?%24expand=ScannedUser&%24top=3"}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "dataSourceCustomQueryInput"
                                                          })}
                                                          onValueChanged={(e) => this.dataSourceCustomQueryInputOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "dataSourceCustomQueryInput", rules: [
                                                                    {rule: "safeinput"},
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={200}
                                                         defaultValue={this.state.dataSourceCustomQueryTableSchemaInput}
                                                         placeholder={"TableName1 | {PrefixName}TableName2"}
                                                         id={"dataSourceCustomQueryTableSchemaInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "dataSourceCustomQueryTableSchemaInput"
                                                         })}
                                                         onValueChanged={(e) => this.dataSourceCustomQueryTableSchemaInputOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "dataSourceCustomQueryTableSchemaInput",
                                                                rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                this.state.createDataViewType !== "report" ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">3.</i>
                                                <span>OData parameters</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersForNormalReportInput_help"}
                                                    title={"OData parameters"}
                                                    text={"Specify OData parameters for retrieving data from the database."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"oDataParametersForNormalReportInput"}
                                                          value={this.state.oDataParametersForNormalReport}
                                                          placeholder={"$filter=...&$apply=..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "oDataParametersForNormalReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.oDataParametersForNormalReportOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "oDataParametersForNormalReportInput",
                                                                rules: [
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex"
                                                   style={{float: "left"}}>4.</i>
                                                <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                    <span>Scheduled report</span>
                                                    <span style={{float: "right"}}>
                                                    <Switch value={!this.state.disableScheduledReportOption}
                                                            width={"60px"}
                                                            switchedOffText={"OFF"} switchedOnText={"ON"}
                                                            id={"disableScheduledReportOption"}
                                                            disabled={this.state.showLoadingProgress}
                                                            onValueChanged={(e) => this.disableScheduledReportOptionOnValueChanged(e)}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                            {
                                                this.state.disableScheduledReportOption === true ? "" :
                                                    <React.Fragment>
                                                        <div className="leopard-option-panel-block">
                                                            <div className={"leopard-leftmenu-settings-section"}>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <span>OData parameters for scheduled report:</span>
                                                                    <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParametersForScheduledReportInput_help"}
                                                                        title={"OData parameters"}
                                                                        text={"Specify OData parameters for retrieving data from the database."}/>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <TextArea disabled={this.state.showLoadingProgress}
                                                                              height={"80px"}
                                                                              id={"oDataParametersForScheduledReportInput"}
                                                                              value={this.state.oDataParametersForScheduledReport}
                                                                              placeholder={"$filter=...&$apply=..."}
                                                                              ref={(e) => this.setInputFieldInstance({
                                                                                  e,
                                                                                  input: "oDataParametersForScheduledReportInput"
                                                                              })}
                                                                              onValueChanged={(e) => this.oDataParametersForScheduledReportOnValueChanged(e)}>
                                                                        <Validator>
                                                                            <RequiredRule type="custom"
                                                                                          validationCallback={
                                                                                              (e) => this.customValidationRuleCallback({
                                                                                                  e,
                                                                                                  input: "oDataParametersForScheduledReportInput",
                                                                                                  rules: [
                                                                                                      {rule: "safeinput"}
                                                                                                  ]
                                                                                              })
                                                                                          }/>
                                                                        </Validator>
                                                                    </TextArea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.buildRecurrenceSettingFields()
                                                        }
                                                        <div className="leopard-option-panel-block">
                                                            <div className={"leopard-leftmenu-settings-section"}>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <span>Specify recipients</span>
                                                                    <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"recipientsForReportInput_help"}
                                                                        title={"Specify recipients"}
                                                                        text={"Specify a list of recipients (email addresses) for receiving the scheduled report."}/>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <TextArea disabled={this.state.showLoadingProgress}
                                                                              height={"80px"}
                                                                              id={"recipientsForReportInput"}
                                                                              value={this.state.recipientsForReport}
                                                                              placeholder={"email1@company.com.au ; email2@company.com.au ;"}
                                                                              ref={(e) => this.setInputFieldInstance({
                                                                                  e,
                                                                                  input: "recipientsForReportInput"
                                                                              })}
                                                                              onValueChanged={(e) => this.recipientsForReportInputOnValueChanged(e)}>
                                                                        <Validator>
                                                                            <RequiredRule type="custom"
                                                                                          validationCallback={
                                                                                              (e) => this.customValidationRuleCallback({
                                                                                                  e,
                                                                                                  input: "recipientsForReportInput",
                                                                                                  rules: [
                                                                                                      {rule: "safeinput"},
                                                                                                      {rule: "required"},
                                                                                                      {rule: "emailaddress-list"}
                                                                                                  ]
                                                                                              })
                                                                                          }/>
                                                                        </Validator>
                                                                    </TextArea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">5.</i>
                                                <span>Query shaping</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"scriptShapingForReportInput_help"}
                                                    title={"Query shaping"}
                                                    text={"Write JavaScript code to manipulate query before it sends to the server."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"scriptShapingForReportInput"}
                                                          value={this.state.scriptShapingForReport}
                                                          placeholder={"Use the variable \"odataParams\", \"parameters\" and \"window.report_input_variables\" to access data and user inputs."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "scriptShapingForReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.scriptShapingForReportOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">6.</i>
                                                <span>Post data processing</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideQueryForReportInput_help"}
                                                    title={"Post data processing"}
                                                    text={"Write JavaScript code to manipulate data on the client-side."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"clientSideQueryForReportInput"}
                                                          value={this.state.clientSideQueryForReport}
                                                          placeholder={"Use the variable \"data\" to access the data."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "clientSideQueryForReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.clientSideQueryForReportOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">7.</i>
                                                <span>UI component processing</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideUILogicForReportInput_help"}
                                                    title={"UI component processing"}
                                                    text={"Write JavaScript code to change report's UI on the client-side."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"clientSideUILogicForReportInput"}
                                                          value={this.state.clientSideUILogicForReport}
                                                          placeholder={"Use the variable \"report\" to access the report UI."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "clientSideUILogicForReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.clientSideUILogicForReportOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                (this.state.createDataViewType !== "chart" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select type of chart</span>
                                            <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"selectTypeOfChart_help"}
                                                title={"Select type of chart"}
                                                text={"Specify what type of chart would you like to create. The Control Centre currently " +
                                                "supports 6 types of chart, such as bar chart, scatter chart, doughnut chart, bar chart, line chart and area chart."}/>
                                </span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionChartTypeList}
                                                       displayExpr={'name'}
                                                       disabled={this.state.showLoadingProgress}
                                                       visible={this.state.showChartTypeOption}
                                                       id={"selectChartTypeInput"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectChartTypeInput"
                                                       })}
                                                       valueExpr={'id'}
                                                       value={this.state.createDataViewChartType}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectChartTypeOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectChartTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "chart" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "10px"}}>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Mandatory configuration</span>
                                            <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"mandatoryConfigurationForChart_help"}
                                                                    title={"Mandatory configuration"}
                                                                    text={"You must configure all the settings in this section for a chart to work. These are the mandatory fields for any charts."}/>
                                                            </span>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for x-axis value</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectColumnForXAxisValueForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for display x-axis values."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress}
                                                         defaultValue={this.state.createDataViewChartArgumentField}
                                                         placeholder={"Column1 | Column2 | Column3"}
                                                         id={"selectArgumentFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "selectArgumentFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewArgumentFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "selectArgumentFieldInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for y-axis value</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectColumnForYAxisValueForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for display y-axis values."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress}
                                                         defaultValue={this.state.createDataViewChartValueField}
                                                         placeholder={"Column1 | Column2 | Column3"}
                                                         id={"selectValueFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "selectValueFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewValueFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "selectValueFieldInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Select a date range</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectDateRangeForChart_help"}
                                                                    title={"Select a date range"}
                                                                    text={"Specify a date range for the chart. For example, if you want to display a chart with total number of transactions per day for the last 7 days, you should select \"Last 7 Days\" from the dropdown list."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <SelectBox
                                                    dataSource={LeopardDropdownHelper.DropdownSelectionChartDateRange}
                                                    displayExpr={'name'} id={"selectDateRangeForCreateChartInput"}
                                                    visible={false}
                                                    value={this.state.selectDateRangeForCreateChart}
                                                    ref={(e) => this.setInputFieldInstance({
                                                        e,
                                                        input: "selectDateRangeForCreateChartInput"
                                                    })}
                                                    valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                    onValueChanged={
                                                        (e) => this.selectDateRangeForCreateChartOnValueChanged(e)
                                                    }>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "selectDateRangeForCreateChartInput",
                                                                rules: [
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for date range</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectDateRangeLookupForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for date range lookup."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                                         visible={false}
                                                         defaultValue={this.state.createDataViewChartDateRangeField}
                                                         id={"createDataViewChartDateRangeFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "createDataViewChartDateRangeFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewChartDateRangeFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "createDataViewChartDateRangeFieldInput",
                                                                rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>OData query string</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"customQueryAttributesForChart_help"}
                                                                    title={"OData query string"} customWidth={450}
                                                                    text={"Before you can create a chart, you must write a query to tell what data should be shown on the chart. For more info about how to write a query, please refer to OData V4 protocol."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"customQueryAttributesInput"}
                                                          placeholder={"/Transaction?$filter=...&$apply=..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "customQueryAttributesInput"
                                                          })}
                                                          onValueChanged={(e) => this.customQueryAttributesOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "customQueryAttributesInput", rules: [
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                    <TextBoxButton name={"customQueryAttributesInput_MiniButton"}
                                                                   location={'after'}
                                                                   options={{
                                                                       icon: "fas fa-question",
                                                                       type: 'default',
                                                                       onClick: () => {
                                                                           window.open(LeopardStaticUIConfig.ODataDocUrl);
                                                                       }
                                                                   }}
                                                    />
                                                </TextArea>
                                            </div>
                                        </div>
                                        {
                                            this.state.createDataViewChartType === "doughnut-chart" ? "" :
                                                <div className="leopard-option-panel-block">
                                                    <div style={{paddingLeft: "24px"}}>
                                                        <span>Chart series</span>
                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"numberOfSeriesForChart_help"}
                                                                    title={"Chart series"} customWidth={450}
                                                                    text={"You must define at least one series to be shown on the chart."}/>
                                                            </span>
                                                    </div>
                                                    <div style={{paddingLeft: "24px"}}>
                                                        <TextBox disabled={this.state.showLoadingProgress}
                                                                 maxLength={4000}
                                                                 id={"numberOfSeriesForChartInput"}
                                                                 ref={(e) => this.setInputFieldInstance({
                                                                     e,
                                                                     input: "numberOfSeriesForChartInput"
                                                                 })}
                                                                 onValueChanged={(e) => this.numberOfSeriesForChartOnValueChanged(e)}>
                                                            <Validator>
                                                                <RequiredRule type="custom" validationCallback={
                                                                    (e) => this.customValidationRuleCallback({
                                                                        e,
                                                                        input: "numberOfSeriesForChartInput",
                                                                        rules: [
                                                                            {rule: "safeinput"}
                                                                        ]
                                                                    })
                                                                }/>
                                                            </Validator>
                                                            <TextBoxButton
                                                                name={"numberOfSeriesForChartInput_MiniButton"}
                                                                location={'after'}
                                                                options={{
                                                                    icon: "fas fa-plus",
                                                                    type: 'default',
                                                                    onClick: () => {
                                                                        if (LDH.IsValueEmpty(this.numberOfSeriesForChartText)) {
                                                                            return;
                                                                        }
                                                                        this.setState({
                                                                            numberOfSeriesOnChart: [
                                                                                ...this.state.numberOfSeriesOnChart,
                                                                                {
                                                                                    id: "series_" + LDH.GenerateGuid(),
                                                                                    name: this.numberOfSeriesForChartText
                                                                                }
                                                                            ]
                                                                        }, function () {
                                                                            this.uiObjectInstance["numberOfSeriesForChartInput"].option("value", "");
                                                                            this.uiObjectInstance["numberOfSeriesForChartInput"].focus();
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </TextBox>
                                                    </div>
                                                    <div className={"leopard-listcontainer"}
                                                         style={{paddingLeft: "24px", marginTop: "5px"}}>
                                                        <List allowItemDeleting={true} activeStateEnabled={false}
                                                              noDataText={"Click the Add button to add a series"}
                                                              hoverStateEnabled={false} focusStateEnabled={false}
                                                              dataSource={this.state.numberOfSeriesOnChart}
                                                              itemRender={this.numberOfSeriesOnChartTemplate}/>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Height of thumbnails</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"heightOfThumbnailInput_help"}
                                                                            title={"Height of thumbnails"}
                                                                            text={"Set the height of the thumbnails by pixels."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                       defaultValue={this.state.heightOfThumbnailForPhotoGallery}
                                                       id={"heightOfThumbnailForPhotoGalleryInput"}
                                                       showSpinButtons={true} format={"#"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "heightOfThumbnailForPhotoGalleryInput"
                                                       })}
                                                       onValueChanged={(e) => this.heightOfThumbnailOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "heightOfThumbnailForPhotoGalleryInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Width of thumbnails</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"widthOfThumbnailInput_help"}
                                                                            title={"Width of thumbnails"}
                                                                            text={"Set the width of the thumbnails by pixels."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                       defaultValue={this.state.widthOfThumbnailForPhotoGallery}
                                                       id={"widthOfThumbnailForPhotoGalleryInput"}
                                                       showSpinButtons={true} format={"#"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "widthOfThumbnailForPhotoGalleryInput"
                                                       })}
                                                       onValueChanged={(e) => this.widthOfThumbnailOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "heightOfThumbnailForPhotoGalleryInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Parent data source ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"parentDataSourceIdForPhotoInput_help"}
                                                        title={"Parent data source ID"}
                                                        text={"Specify the parent data source ID for this Data View."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.parentDataSourceIdForPhotoGallery}
                                                     id={"parentDataSourceIdForPhotoInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "parentDataSourceIdForPhotoInput"
                                                     })}
                                                     onValueChanged={(e) => this.parentDataSourceIdOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "parentDataSourceIdForPhotoInput",
                                                            rules: [
                                                                {rule: "required"},
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "datagrid" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Top level filtering field</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"topLevelFilteringFieldInput_help"}
                                                        title={"Top level filtering field"}
                                                        text={"Specify a top level filtering field to restrict users from accessing other users information in a different group."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.topLevelFilteringField}
                                                     id={"topLevelFilteringFieldInput"}
                                                     placeholder={"UserGroupId|'{user-group-id}'"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "topLevelFilteringFieldInput"
                                                     })}
                                                     onValueChanged={(e) => this.topLevelFilteringFieldValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "topLevelFilteringFieldInput",
                                                            rules: [
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "datagrid" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Custom computed columns</span>
                                            <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"customComputedColumns_help"}
                                                title={"Custom computed columns"}
                                                text={"Specify custom computed columns for the grid view. " +
                                                "You'll need to write JavaScript code for processing data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress} maxLength={4000}
                                                     id={"customComputedColumnsInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "customComputedColumnsInput"
                                                     })}
                                                     onValueChanged={(e) => this.customComputedColumnsOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "customComputedColumnsInput", rules: [
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                                <TextBoxButton name={"customComputedColumnsInput_MiniButton"}
                                                               location={'after'}
                                                               options={{
                                                                   icon: "fas fa-plus",
                                                                   type: 'default',
                                                                   onClick: () => {
                                                                       if (LDH.IsValueEmpty(this.customComputedColumnsText)) {
                                                                           return;
                                                                       }
                                                                       this.setState({
                                                                           customComputedColumns: [
                                                                               ...this.state.customComputedColumns,
                                                                               {
                                                                                   id: "customColumns_" + (this.state.customComputedColumns.length + 1),
                                                                                   name: "_" + this.customComputedColumnsText
                                                                               }
                                                                           ]
                                                                       }, function () {
                                                                           this.uiObjectInstance["customComputedColumnsInput"].option("value", "");
                                                                           this.uiObjectInstance["customComputedColumnsInput"].focus();
                                                                       });
                                                                   }
                                                               }}
                                                />
                                            </TextBox>
                                        </div>
                                        <div className={"leopard-listcontainer"}
                                             style={{marginTop: "5px"}}>
                                            <List allowItemDeleting={true} activeStateEnabled={false}
                                                  noDataText={"Click the Add button to add a custom column"}
                                                  hoverStateEnabled={false} focusStateEnabled={false}
                                                  dataSource={this.state.customComputedColumns}
                                                  itemRender={this.customComputedColumnsTemplate}/>
                                        </div>
                                    </div>
                            }
                            <div className="leopard-option-panel-block">
                                <div>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <React.Fragment>
                                                <i className="leopard-option-panel-stepindex">8.</i>
                                            </React.Fragment>
                                            :
                                            (this.state.selectedDataSourceObject !== null &&
                                                this.state.selectedDataSourceObject.category === "data-table" &&
                                                this.state.createDataViewType !== "chart" &&
                                                this.state.createDataViewType !== "photo") ?
                                                <React.Fragment>
                                                    <i className="leopard-option-panel-stepindex">
                                                        {this.state.showChartTypeOption ? "7." : "5."}
                                                    </i>
                                                </React.Fragment>
                                                :
                                                (this.state.createDataViewType === "chart" &&
                                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                    <React.Fragment>
                                                        <i className="leopard-option-panel-stepindex">4.</i>
                                                    </React.Fragment>
                                                    :
                                                    (this.state.createDataViewType === "datagrid" &&
                                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                        <React.Fragment>
                                                            <i className="leopard-option-panel-stepindex">5.</i>
                                                        </React.Fragment>
                                                        :
                                                        this.state.createDataViewType === "photo" ?
                                                            <React.Fragment>
                                                                <i className="leopard-option-panel-stepindex">5.</i>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <i className="leopard-option-panel-stepindex">3.</i>
                                                            </React.Fragment>
                                    }
                                    <span>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Name of data view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Name of system view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Name of default view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Name of report" : ""
                                        }
                                    </span>
                                    <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of data view"}
                                                                        text={"Give a name for your data view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of system view"}
                                                                        text={"Give a name for your system view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of default view"}
                                                                        text={"Give a name for your default view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of report"}
                                                                        text={"Give a name for your report. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                    </span>
                                </div>
                                <div>
                                    <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                             id={"dataViewNameInput"}
                                             ref={(e) => this.setInputFieldInstance({
                                                 e,
                                                 input: "dataViewNameInput"
                                             })}
                                             onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e, input: "dataViewNameInput", rules: [
                                                        {rule: "required"},
                                                        {rule: "safeinput"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </TextBox>
                                    <div style={{display: "none"}}>
                                        <TextBox
                                            ref={(e) => this.setInputFieldInstance({
                                                e,
                                                input: "validationInput"
                                            })}
                                            onValueChanged={(e) => this.validationInputOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e, input: "validationInput", rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                {
                                    this.state.showLoadingProgress === false ? "" :
                                        <span className={"leopard-loading-icon"} style={{
                                            marginRight: "15px", marginLeft: "30px"
                                        }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                                }
                                <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                                <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                          </span>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateTemplate = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Create Workspace
                </div>
                <form onSubmit={this.createDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                        title={"Select data views"}
                                        text={"A workspace contains a collection of data views. When you create a workspace, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Name of workspace</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dashboardNameInput_help"}
                                                            title={"Name of workspace"}
                                                            text={"Give a name to your workspace. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox
                                    ref={(e) => this.setInputFieldInstance({e, input: "dashboardNameInput"})}
                                    disabled={this.state.showLoadingProgress} maxLength={50}
                                    id={"dashboardNameInput"}
                                    onValueChanged={(e) => this.createDashboardNameInputOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dashboardNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                        <i className="fas fa-spinner fa-pulse" style={{
                                            color: "#FF8000", fontSize: "25px"
                                        }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                disabled={this.state.showLoadingProgress}
                                id={"createDashboardCancelButton"}
                                onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
                  </span>
                            <span style={{paddingRight: "20px"}}>
                        <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                                id={"createDashboardApplyButton"}
                                disabled={this.state.showLoadingProgress}/>
                  </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelEditTemplate = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Edit Workspace
                </div>
                <form onSubmit={this.editDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                        title={"Select data views"}
                                        text={"A workspace contains a collection of data views. When you create a workspace, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Rename workspace</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                            title={"Rename workspace"}
                                                            text={"Give a name to your workspace. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox maxLength={50}
                                         ref={(e) => this.setInputFieldInstance({e, input: "dataViewNameInput"})}
                                         disabled={this.state.showLoadingProgress}
                                         id={"dataViewNameInput"}
                                         defaultValue={this.state.editDataViewName}
                                         onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "#FF8000", fontSize: "25px"
                    }}></i>
                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Delete'}
                                id={"createDashboardDeleteButton"}
                                disabled={this.state.showLoadingProgress} onClick={
                            () => this.createDashboardDeleteButtonOnClick()}/>
                  </span>
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                id={"createDashboardCancelButton"}
                                disabled={this.state.showLoadingProgress}
                                onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
                  </span>
                            <span style={{marginRight: "20px"}}>
                <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                        id={"createDashboardApplyButton"}
                        disabled={this.state.showLoadingProgress}/>
              </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    renderDataViewSelectBoxListForCreateOrEditDashboard = () => {
        let that = this;
        if (that.state.availableDataViewListForCreate.length === 0) {
            return null;
        }
        return this.state.selectedDataViewsForDashboard.map(function (item, i) {
            if (that.state.showMenuOptionPanel.option === "add_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + i}>
                        <div className={"leopard-menuoption-splitter-container"}>
                            <div style={{marginBottom: "5px"}}>
                                <SelectBox dataSource={new DataSource({
                                    store: that.state.availableDataViewListForCreate, key: 'id', group: 'group'
                                })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                           disabled={that.state.showLoadingProgress}
                                           grouped={true} defaultValue={item.selectedId} id={"selectedDataView_" + i}
                                           onValueChanged={(e) => that.selectDataViewValueOnChange({
                                               e, index: i, item
                                           })}/>
                            </div>
                            <div>
                                <TextBox defaultValue={item.customDataViewTitle} placeholder={"Custom title..."}
                                         disabled={that.state.showLoadingProgress} id={"customDataViewTitleInput_" + i}
                                         onValueChanged={(e) => that.selectDataViewCustomTitleOnChange({
                                             e, index: i, item
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            if (that.state.showMenuOptionPanel.option === "edit_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + i}>
                        <div className={"leopard-menuoption-splitter-container"}>
                            <div style={{marginBottom: "5px"}}>
                                <SelectBox dataSource={new DataSource({
                                    store: that.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                           disabled={that.state.showLoadingProgress}
                                           grouped={true} value={item.selectedId} id={"selectedDataView_" + i}
                                           onValueChanged={(e) => that.selectDataViewValueOnChange({
                                               e, index: i, item
                                           })}/>
                            </div>
                            <div>
                                <TextBox defaultValue={item.customDataViewTitle} placeholder={"Custom title..."}
                                         disabled={that.state.showLoadingProgress} id={"customDataViewTitleInput_" + i}
                                         onValueChanged={(e) => that.selectDataViewCustomTitleOnChange({
                                             e, index: i, item
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            return null;
        });
    };

    renderProgressLoadingPanel = (showPanel) => {
        if (showPanel === false) return null;

        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{
            width: "100%", height: "100%", marginLeft: "0px", paddingTop: "40%"
        }}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    renderAddDashboardButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-workspace-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_template"
               })}/>
        );
    };

    renderAddDataViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-dataview-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "data-view"
               })}></i>
        );
    };

    renderAddSystemViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-systemview-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "system-view"
               })}></i>
        );
    };

    renderAddDefaultViewButton = (showGridViewAdminToolbar) => {
        if (!showGridViewAdminToolbar || !LPH.IsLeopardAdminModeEnabled(this)) {
            return null;
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-defaultview-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "default-view"
               })}></i>
        );
    };

    renderAddReportButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-report-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "report"
               })}></i>
        );
    };

    renderLeftMenuItemsPanel = (showPanel) => {
        if (showPanel === false) return null;

        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        setTimeout(function () {
            let value = LeopardStaticUIConfig.Global_MenuItemSearchText;
            LRH.SearchForMenuItems(value);
        }, 10);

        return (
            <React.Fragment>
                <div className="leopard-leftmenu-category-container text-selection-disabled">
                    <div className="leopard-screen-cover" style={{display: "none"}}></div>
                    <span className="leopard-screen-menucover" style={{display: "none"}}></span>
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}
                             onClick={() => this.menuCategoryOnClick({category: "workspaces"})}>
                            Workspaces (<span className={"menu-item-totalcount"} category={"workspaces"}>
                            {LDH.IsObjectNull(this.templateMenuItems) ? 0 : this.templateMenuItems.length}</span>)
                            {
                                this.state.categoryWorkspacesVisibility ?
                                    <i className="fas fa-caret-up" style={{fontSize: "14px", marginLeft: "5px"}}></i> :
                                    <i className="fas fa-caret-down" style={{fontSize: "14px", marginLeft: "5px"}}></i>
                            }
                        </div>
                        {this.renderAddDashboardButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuTemplates("workspaces")}
                    <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}>
                        <div className="leopard-leftmenu-category-text" id={"leftmenu-dataview-category-title"}
                             onClick={() => this.menuCategoryOnClick({category: "data-view"})}>
                            Data Views (<span className={"menu-item-totalcount"} category={"data-view"}>
                            {LDH.IsObjectNull(this.dataViewMenuItems) ? 0 : this.dataViewMenuItems.length}</span>)
                            {
                                this.state.categoryDataViewsVisibility ?
                                    <i className="fas fa-caret-up" style={{fontSize: "14px", marginLeft: "5px"}}></i> :
                                    <i className="fas fa-caret-down" style={{fontSize: "14px", marginLeft: "5px"}}></i>
                            }
                        </div>
                        {this.renderAddDataViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.dataViewMenuItems, "data-view")}
                    {
                        showGridViewAdminToolbar === false ? "" :
                            <React.Fragment>
                                <div
                                    className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}>
                                    <div className="leopard-leftmenu-category-text" id={"leftmenu-defaultview-category-title"}
                                         onClick={() => this.menuCategoryOnClick({category: "default-view"})}>
                                        Default Views (<span className={"menu-item-totalcount"}
                                                             category={"default-view"}>
                            {LDH.IsObjectNull(this.defaultViewMenuItems) ? 0 : this.defaultViewMenuItems.length}</span>)
                                        {
                                            this.state.categoryDefaultViewsVisibility ?
                                                <i className="fas fa-caret-up"
                                                   style={{fontSize: "14px", marginLeft: "5px"}}></i> :
                                                <i className="fas fa-caret-down"
                                                   style={{fontSize: "14px", marginLeft: "5px"}}></i>
                                        }
                                    </div>
                                    {this.renderAddDefaultViewButton(showGridViewAdminToolbar)}
                                </div>
                                {this.buildLeopardMenuDataViews(this.defaultViewMenuItems, "default-view")}
                            </React.Fragment>
                    }
                    <div
                        className={"leopard-leftmenu-category " + (window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin ? "" : "standarduser")}>
                        <div className="leopard-leftmenu-category-text" id={"leftmenu-report-category-title"}
                             onClick={() => this.menuCategoryOnClick({category: "report"})}>
                            Reports (<span className={"menu-item-totalcount"} category={"report"}>
                            {LDH.IsObjectNull(this.reportMenuItems) ? 0 : this.reportMenuItems.length}</span>)
                            {
                                this.state.categoryReportsVisibility ?
                                    <i className="fas fa-caret-up" style={{fontSize: "14px", marginLeft: "5px"}}></i> :
                                    <i className="fas fa-caret-down" style={{fontSize: "14px", marginLeft: "5px"}}></i>
                            }
                        </div>
                        {this.renderAddReportButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.reportMenuItems, "report")}
                    <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}>
                        <div className="leopard-leftmenu-category-text" id={"leftmenu-systemview-category-title"}
                             onClick={() => this.menuCategoryOnClick({category: "system-view"})}>
                            System (<span className={"menu-item-totalcount"} category={"system-view"}>
                            {LDH.IsObjectNull(this.systemViewMenuItems) ? 0 : this.systemViewMenuItems.length}</span>)
                            {
                                this.state.categorySystemViewsVisibility ?
                                    <i className="fas fa-caret-up" style={{fontSize: "14px", marginLeft: "5px"}}></i> :
                                    <i className="fas fa-caret-down" style={{fontSize: "14px", marginLeft: "5px"}}></i>
                            }
                        </div>
                        {this.renderAddSystemViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.systemViewMenuItems, "system-view")}
                </div>
            </React.Fragment>
        );
    };

    render() {
        let showOption = this.state.showMenuOptionPanel !== null;
        let showPanel = this.state.leftMenuItemsInitialized;
        LRH.ShowOrHideApplicationBackgroundCover(showOption);

        if (this.state.showMenuOptionPanel === undefined || this.state.showMenuOptionPanel === null ||
            showOption === false) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (!LDH.IsObjectNull(this.state.showMenuOptionPanel) &&
            this.state.showMenuOptionPanel.option === "add_dataview_options" && showOption === true) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                            {this.displayMenuOptionByType({option: "add_dataview_options"})}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "add_dataview" ||
                this.state.showMenuOptionPanel.option === "export_dataview" ||
                this.state.showMenuOptionPanel.option === "import_dataview" ||
                this.state.showMenuOptionPanel.option === "clone_dataview")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({option: "add_dataview_options"})}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "edit_template" ||
                this.state.showMenuOptionPanel.option === "manage_dashboard_relationship")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({option: "edit_dashboard_options"})}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className={"leopard-left-panel-container"}>
                    {
                        showOption === false ? "" :
                            <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                                {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                            </div>
                    }
                    {this.renderProgressLoadingPanel(!showPanel)}
                    {this.renderLeftMenuItemsPanel(showPanel)}
                    <br/>
                </div>
            </React.Fragment>
        );
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetLeftMenuItem: (data) => {
            dispatch(SetLeftMenuItem(data));
        },
        KeepGridViewDataById: (id) => {
            dispatch(KeepGridViewDataById(id));
        },
        KeepChartDataById: (id) => {
            dispatch(KeepChartDataById(id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardMasterLeftMenu);
