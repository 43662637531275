import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import LeopardSecurity from './security/LeopardSecurity';
import LRH from './helpers/LeopardReactHelper';
import {
    SetAuthenticatedUser,
    SetLeopardAdminMode,
    SetUserPermissions,
    SetWebAppInitialized
} from './foundation/LeopardActionCreators';

import {Button, TextBox} from 'devextreme-react';
import LeopardTopMenuBar from './components/LeopardTopMenuBar';
import LeopardMasterLeftMenu from './components/LeopardMasterLeftMenu';
import LeopardStaticUIConfig from './foundation/LeopardStaticUIConfig';
import LeopardMasterContentPanel from './components/LeopardMasterContentPanel';
import LeopardAjaxHelper from './helpers/LeopardAjaxHelper';
import LDH from './helpers/LeopardDataHelper';
import config from 'devextreme-react/core/config';

config({useLegacyTemplateEngine: true});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUserName: "",
            loggedInUserRoles: "",
            authState: null,
            masterPageOnLoadComplete: false
        };

        this.gridViewInstance = null;
        this.chartInstance = null;
        this.mapInstance = null;
        this.photoInstance = null;
        this.showLeftMenuContent = true;
    }

    updateWindowDimensionsOnResizing = (optimizePagerForLargeDataset) => {
        let that = this;

        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let height = window.innerHeight - 153;
        let width = window.innerWidth;
        width = width - (that.showLeftMenuContent ? 305 : 0);

        if (this.gridViewInstance !== null) {
            let heightForGridView = window.innerHeight;
            heightForGridView = heightForGridView - 153;

            let adjustment = 0;
            if ((LDH.IsValueEmpty(optimizePagerForLargeDataset) === false &&
                optimizePagerForLargeDataset === true) ||
                $(".leopard-page-container").length > 0) {
                adjustment = -33;
            }

            if (showGridViewAdminToolbar === true) {
                this.gridViewInstance.option("height", heightForGridView + adjustment - 3);
            } else {
                this.gridViewInstance.option("height", heightForGridView + adjustment + 27);
            }
            this.gridViewInstance.repaint();
        }

        if (this.chartInstance !== null) {
            let heightForChart = window.innerHeight - 128;
            if (showGridViewAdminToolbar === true) {
                this.chartInstance.option("size", {height: heightForChart - 30});
            } else {
                this.chartInstance.option("size", {height: heightForChart});
            }
        }

        if (this.mapInstance !== null) {
            let heightForMap = window.innerHeight - 95;
            this.mapInstance.option("height", heightForMap);
        }

        let winHeight = height + 68;
        $(".leopard-right-panel-container").css("width", width).css("height", winHeight);
        $(".leopard-left-panel-container").css("height", winHeight);
        $(".leopard-leftmenu-option-panel").css("height", winHeight);
        $(".leopard-option-panel-content").css("height", winHeight - 115);
        $(".leopard-screen-cover").css("height", winHeight);

        $(".leopard-table-with-fixedcolumn table").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn thead").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn tbody").css("width", width - 50);

        let $reportDesigner = $("#LeopardReportDesigner");
        if ($reportDesigner.length > 0 && $reportDesigner.css("z-index") !== "1000000") {
            $reportDesigner.css("height", winHeight - 10);
        }
    };

    setGridViewInstance = (e) => {
        if (e.isDataView === true) {
            this.gridViewInstance = e.instance;
            this.updateWindowDimensionsOnResizing(e.optimizePagerForLargeDataset);
        }
    };

    setChartInstance = (e) => {
        if (e.isDataView === true) {
            this.chartInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setPhotoInstance = (e) => {
        if (e.isDataView === true) {
            this.photoInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensionsOnResizing);
        this.gridViewInstance = null;
        this.showLeftMenuContent = true;
    }

    componentDidUpdate = () => {
        let that = this;
        if (this.props.updateUserProfile === true) {
            this.props.updateUserProfileComplete(function () {
                that.initializeAuthenticatedUserAndProfile();
            });
        }
    };

    componentDidMount() {
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        window.checkControlCentreVersion = false;

        let fontStyle = '<link rel="stylesheet" type="text/css" href="/css/fonts.css?v=' + version + '" />';
        $("head").append(fontStyle);

        let devextremeStyle = '<link rel="stylesheet" type="text/css" href="/css/devextreme/dx.generic.leopard-theme.css?v=' + version + '" />';
        $("head").append(devextremeStyle);

        let customStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/custom.css?v=' + version + '" />';
        $("#leopard-default-style").remove();
        $("head").append(customStyle);

        let defaultStyle = '<link rel="stylesheet" type="text/css" href="/css/default.css?v=' + version + '" />';
        $("head").append(defaultStyle);

        $("head").append('<script src="/js/webconfig.js?v=' + version + '"></script>');
        window.addEventListener("resize", this.updateWindowDimensionsOnResizing);
        window.Stimulsoft.Base.StiLicense.key = LeopardStaticUIConfig.ReportLicenseKey;

        $("body").on("contextmenu", function (e) {
            if (!LDH.IsObjectNull(e.target) && !LDH.IsValueEmpty(e.target.className) &&
                e.target.className === "leopard-photogallery-enlarged-image") {
                return true;
            }
            return false;
        });

        $(document).off("click").on("click", function () {
            if ($(".leopard-login-userinfo-panel").is(":visible")) {
                $(".leopard-login-userinfo-panel").hide();
            }
        });

        let storagekeys = LDH.GetAllLocalStorageItemKeys();
        for (let i = 0; i < storagekeys.length; i++) {
            if (storagekeys[i].indexOf("GridViewLayoutState_") > -1) {
                localStorage.removeItem(storagekeys[i]);
            }
        }

        let that = this;
        this.setState({masterPageOnLoadComplete: true}, function () {
            that.updateWindowDimensionsOnResizing();
        });
        LeopardAjaxHelper.AddSecurityHeadersToAjaxRequestForOdata();
    }

    initializeAuthenticatedUserAndProfile = () => {
        let that = this;

        if (that.props.authState === "signedIn") {
            LeopardSecurity.GetCurrentAuthenticatedUser(function (user) {
                let roles = user.attributes["custom:role"];

                that.props.SetAuthenticatedUser({data: user});
                that.setState({
                    loggedInUserName: user.username,
                    authState: that.props.authState,
                    loggedInUserRoles: roles
                });

                if (roles === LeopardStaticUIConfig.UserRoleCCAdmin) {
                    that.props.SetUserPermissions({
                        ShowGridViewAdminToolbar: true,
                        AllowReportDesigner: true
                    });
                    that.props.SetLeopardAdminMode({enabled: false});
                } else {
                    that.props.SetUserPermissions({
                        ShowGridViewAdminToolbar: false,
                        AllowReportDesigner: false
                    });
                    that.props.SetLeopardAdminMode({enabled: false});
                }

                let userId = user.attributes.sub;
                window.userRoles = roles;

                if (roles === LeopardStaticUIConfig.UserRoleAppUser) {
                    setTimeout(function () {
                        $(".leopard-left-panel-container .leopard-loading-icon").hide();
                        LRH.ShowToast("You're logged in as an \"App User\" which do not have access to Control Centre. " +
                            "Please contact your administrator for details.", "error", 8000);
                    }, 100);

                    setTimeout(function () {
                        LeopardSecurity.UserLogout();
                    }, 5000);
                    return;
                }

                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    window.userProfile = profile;
                    window.userProfileForInitWebApp = profile;
                    that.props.SetWebAppInitialized({data: true});

                    LeopardAjaxHelper.GetControlCentreVersion(userId, function (data) {
                        if (LDH.IsUpdateControlCentreVersionRequired(data) === true) {
                            let orgId = LDH.GetOrganizationIdFromUserProfile(profile);
                            LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                        }
                        window.checkControlCentreVersion = true;
                    }, null, profile);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve the user profile.", "error", 5000);
                    }
                });
            }, function () {
                that.setState({authState: that.props.authState});
            });
            that.getCurrentUserCredentials();

            setInterval(function () {
                that.getCurrentUserCredentials();
            }, 60000);
        } else {
            that.setState({authState: that.props.authState});
        }
    };

    getCurrentUserCredentials = () =>{
        LeopardSecurity.GetCurrentUserCredentials(function (credential) {
            if (!LDH.IsObjectNull(credential.name) &&
                credential.name === "NotAuthorizedException") {
                LeopardSecurity.UserLogout();
                return;
            }
            window.userCredential = credential;
        }, function () {
            LeopardSecurity.UserLogout();
        });
    };

    navHideButtonOnClick = () => {
        let that = this;

        if (that.showLeftMenuContent === false) {
            that.showLeftMenuContent = true;
            $(".leopard-left-panel-container").show(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();

                setTimeout(function () {
                    that.resizeDashboardItems();
                }, 10);
            });
            $("#RootWindowContainer").removeClass("left-panel-hidden")
                .addClass("left-panel-shown");
        } else {
            that.showLeftMenuContent = false;
            $(".leopard-left-panel-container").hide(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();

                setTimeout(function () {
                    that.resizeDashboardItems();
                }, 10);
            });
            $("#RootWindowContainer").removeClass("left-panel-shown")
                .addClass("left-panel-hidden");
        }
    };

    resizeDashboardItems = () => {
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
        setTimeout(function () {
            for (let i = 0; i < listeners.length; i++) {
                if (LDH.IsObjectNull(listeners[i].instance)) {
                    continue;
                }
                if (listeners[i].instance.NAME !== "dxTileView") {
                    let size = listeners[i].instance.option("size");
                    listeners[i].instance.option("size", size);
                    if (listeners[i].instance.NAME === "dxDataGrid") {
                        listeners[i].instance.repaint();
                    }
                }
            }
        }, 10);
    };

    refreshBrowserTab = () => {
        window.location.reload();
    };

    render() {
        if (this.state.masterPageOnLoadComplete === true) {
            return (
                <React.Fragment>
                    <div className="App">
                        <div className={"leopard-add-autocomplete-blocker"}>
                            <TextBox mode={"text"} style={{height: "0px", width: "0px", border: "none"}}></TextBox>
                            <TextBox mode={"password"} style={{height: "0px", width: "0px", border: "none"}}></TextBox>
                        </div>
                        <div className={"leopard-application-loading-cover"}>
                             <span className={"leopard-loading-icon leopard-centre-box"}>
                                 <i className="fas fa-spinner fa-pulse"
                                    style={{color: "#FF8000", fontSize: "45px"}}></i>
                             </span>
                        </div>
                        <div className={"cc-update-notice-text"}>
                            <h3 style={{marginBottom: "30px"}}>Your Control Centre needs update</h3>
                            <div>We have just updated the Control Centre on the server.</div>
                            <div style={{marginBottom: "30px"}}>
                                Please refresh your browser to get the latest version.
                            </div>
                            <Button className="leopard-button refresh-browser" text={'Refresh Browser'}
                                    onClick={(e) => this.refreshBrowserTab(e)}/>
                        </div>
                        <LeopardTopMenuBar loggedInUserName={this.state.loggedInUserName}
                                           navHideButtonOnClick={this.navHideButtonOnClick}>
                        </LeopardTopMenuBar>
                        <div className="leopard-master-leftmenu-container">
                            <LeopardMasterLeftMenu
                                updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}>
                            </LeopardMasterLeftMenu>
                            <div className={"leopard-right-panel-container"}>
                                <LeopardMasterContentPanel
                                    setGridViewInstance={(e) => this.setGridViewInstance(e)}
                                    setChartInstance={(e) => this.setChartInstance(e)}
                                    setPhotoInstance={(e) => this.setPhotoInstance(e)}
                                    windowHeight={0}
                                    setMapInstance={(e) => this.setMapInstance(e)}
                                    updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}>
                                </LeopardMasterContentPanel>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetUserPermissions: (data) => {
            dispatch(SetUserPermissions(data));
        },
        SetLeopardAdminMode: (data) => {
            dispatch(SetLeopardAdminMode(data));
        },
        SetAuthenticatedUser: (data) => {
            dispatch(SetAuthenticatedUser(data));
        },
        SetWebAppInitialized: (data) => {
            dispatch(SetWebAppInitialized(data));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(App);
