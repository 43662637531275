import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import {TileView} from "devextreme-react";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import TabPanel from "devextreme-react/tab-panel";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardAttachmentEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customStore: null,
            photoData: []
        };

        this.uiObjectInstance = {
            photoInstance: null,
            tabPanelInstance: null
        };

        this.disposingAllInstances = false;
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
    }

    setTabPanelInstance = (ref) => {
        if (ref === null || ref.instance === null) {
            return;
        }
        this.uiObjectInstance.tabPanelInstance = ref.instance;
    };

    setPhotoInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null ||
            this.uiObjectInstance.photoInstance !== null) {
            return;
        }
        this.uiObjectInstance.photoInstance = ref.instance;

        this.props.setPhotoInstance({
            instance: ref.instance,
            id: this.props.dataViewId,
            type: "photo",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.photoInstance.option("relationships", linkedList);
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback(data) {
                    let photoDefinition = that.props.definition.photoDefinition;
                    let id = photoDefinition.parentDataSourceId;
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let allowErrorMessage = false;

                            if (!LDH.IsObjectNull(data.dataFromSource) && !LDH.IsObjectNull(data.parentGridDef) &&
                                !LDH.IsObjectNull(data.parentGridDef.customColumnConfiguration) &&
                                !LDH.IsObjectNull(data.parentGridDef.customColumnConfiguration.customColumns)) {
                                for (let v = 0; v < data.parentGridDef.customColumnConfiguration.customColumns.length; v++) {
                                    let customColumn = data.parentGridDef.customColumnConfiguration.customColumns[v];
                                    if (!LDH.IsObjectNull(customColumn.countColumnName) &&
                                        !LDH.IsValueEmpty(customColumn.countColumnName) &&
                                        customColumn.columnType === "photo-gallery" &&
                                        !LDH.IsValueEmpty(data.dataFromSource[customColumn.countColumnName]) &&
                                        parseInt(data.dataFromSource[customColumn.countColumnName]) > 0) {
                                        allowErrorMessage = true;
                                    }
                                }
                            }
                            if (LDH.IsObjectNull(data.dataFromSource) === false) {
                                that.getDataFromDataSource(data.dataFromSource[id], allowErrorMessage);
                            } else {
                                that.getDataFromDataSource(null, allowErrorMessage);
                            }
                        }
                    }
                }
            });
        }
    };

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);

        this.props.setPhotoInstance({
            instance: null,
            id: this.props.dataViewId,
            type: "photo",
            isDataView: true
        });
    };

    componentDidMount = () => {
        this.relationships = this.props.relationships;
    };

    getDataFromDataSource = (itemId, allowErrorMessage) => {
        let that = this;
        if (that.disposingAllInstances === true) {
            return;
        }
        if (LDH.IsObjectNull(itemId) || LDH.IsValueEmpty(itemId)) {
            that.setState({photoData: []});
            return;
        }
        LeopardAjaxHelper.GetAttachmentsByItemId(itemId, allowErrorMessage, function (result) {
            if (that.disposingAllInstances === true) {
                return;
            }
            let items = LDH.GetImageListFromResult(result, itemId);
            that.setState({photoData: items});
        }, function () {
            if (that.disposingAllInstances === false) {
                LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
            }
        });
    };

    photoOnClick = (e) => {
        window.open(e.imageData.e.originalImageUrl);
    };

    tileViewItem = (data) => {
        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"}
                     style={{width: "100%", height: "100%", padding: "5px"}}>
                    <div className={data.e.id + " leopard-tileviewitem-image"}
                         onClick={(e) => this.photoOnClick({e, imageData: data})}
                         style={{backgroundImage: `url(${data.e.imageUrl})`}}>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        let that = this;

        if (data.e.tabId === 0) {
            return (
                <React.Fragment>
                    <div className={"leopard-photogallery-data-view"}>
                        <TileView dataSource={that.state.photoData} height={"100%"}
                                  baseItemHeight={data.photoDefinition.heightOfThumbnail}
                                  baseItemWidth={data.photoDefinition.widthOfThumbnail}
                                  noDataText={"No photo available to display."}
                                  ref={that.setPhotoInstance}
                                  direction={"vertical"} itemMargin={0}
                                  itemComponent={(e) => that.tileViewItem({
                                      e, definition: that.props.definition
                                  })}>
                        </TileView>
                    </div>
                </React.Fragment>
            );
        } else if (data.e.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{height: "100%"}}>
                        <div style={{padding: "10px"}}>
                            This feature is currently under development.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        if (this.disposingAllInstances) return null;
        let photoDefinition = this.props.definition.photoDefinition;

        return (
            <React.Fragment>
                <div className={"leopard-attachment-widget-container"}
                     id={"leopard-attachment-widget-" + this.props.dataViewId}>
                    <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionAttachmentTabPanel}
                              animationEnabled={true} swipeEnabled={false} showNavButtons={true}
                              itemTitleRender={this.tabItemTitleRender} ref={this.setTabPanelInstance}
                              itemComponent={(e) => this.tabItemContent({
                                  e, photoDefinition
                              })}>
                    </TabPanel>
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardAttachmentEngine);
