const initialState = {
    gridViewState: [],
    chartState: [],
    mapState: []
};

const LeopardGridViewReducer = (state, action) => {
    state = state || initialState;
    if (action.type === "INIT_CHART_DATA") {
        let data = action.chartData;
        let id = action.dataViewId;

        return {
            ...state,
            chartState: state.chartState.map((currentState) => {
                if (currentState.dataViewId !== action.dataViewId) {
                    return currentState;
                }
                currentState.dataViewId = id;
                currentState.chartData = data;

                return currentState;
            })
        }
    }

    if (action.type === "INIT_PHOTO_GALLERY_DATA") {
        let data = action.photoData;
        let id = action.dataViewId;

        return {
            ...state,
            photoState: state.photoState.map((currentState) => {
                if (currentState.dataViewId !== action.dataViewId) {
                    return currentState;
                }
                currentState.dataViewId = id;
                currentState.photoData = data;

                return currentState;
            })
        }
    }

    if (action.type === "INIT_MAP_DATA") {
        let data = action.mapData;
        let id = action.mapId;

        return {
            ...state,
            mapState: state.mapState.map((currentState) => {
                if (currentState.mapId !== action.mapId) {
                    return currentState;
                }
                currentState.mapId = id;
                currentState.mapData = data;

                return currentState;
            })
        }
    }

    if (action.type === "SET_LEFT_MENU_ITEM") {
        let item = action.selectedLeftMenuItem;

        return {...state, selectedLeftMenuItem: item};
    }

    if (action.type === "SEND_DASHBOARD_EVENT_MESSAGING") {
        let item = action.messaging;
        return {...state, dashboardEventMessaging: item};
    }

    if (action.type === "SET_USER_PERMISSIONS") {
        let permissions = action.permissions;
        return {...state, permissions};
    }

    if (action.type === "SET_LEOPARD_ADMIN_MODE") {
        let data = action.data;
        return {...state, leopardAdminMode: data};
    }

    if (action.type === "SET_USER_CREDENTIAL") {
        let credential = action.credential;
        return {...state, credential};
    }

    if (action.type === "SET_AUTHENTICATED_USER") {
        let authenticatedUser = action.authenticatedUser;
        return {...state, authenticatedUser};
    }

    if (action.type === "SET_WEBAPP_INITIALIZED") {
        let value = action.webAppInitialized;
        return {...state, value};
    }

    if (action.type === "INIT_CUSTOM_STORE") {
        let gridViewState = state.gridViewState;
        let currentState = gridViewState.filter(gridView => {
            return gridView.gridViewId === action.gridViewId;
        });
        if (currentState !== null && currentState.length > 0) {
            return {...state, gridViewState: [...state.gridViewState]};
        }

        let newState = {
            gridViewId: action.gridViewId,
            customStore: action.customStore,
            childViewParams: null,
            gridColumns: [],
            gridDefinition: {
                columnDefinition: [],
                adaptiveColumn: false,
                optimizePagerForLargeDataset: true,
                defaultPageSize: 10
            }
        };

        return {
            ...state, gridViewState: [...state.gridViewState, newState]
        };
    }

    if (action.type === "INIT_CUSTOM_STORE_FOR_CHART") {
        let chartState = state.chartState;
        let currentState = chartState.filter(chart => {
            return chart.dataViewId === action.dataViewId;
        });
        if (currentState !== null && currentState.length > 0) {
            return {...state, chartState: [...state.chartState]};
        }

        let newState = {
            dataViewId: action.dataViewId,
            customStore: action.customStore,
            chartData: {data: []}
        };

        return {
            ...state, chartState: [...state.chartState, newState]
        };
    }

    if (action.type === "INIT_CUSTOM_STORE_FOR_MAP") {
        let mapState = state.mapState;
        let currentState = mapState.filter(map => {
            return map.mapId === action.mapId;
        });
        if (currentState !== null && currentState.length > 0) {
            return {...state, mapState: [...state.mapState]};
        }

        let newState = {
            mapId: action.mapId,
            customStore: action.customStore,
            mapData: {data: []}
        };

        return {
            ...state, mapState: [...state.mapState, newState]
        };
    }

    if (action.type === "UPDATE_GRIDVIEW_COLUMN_DEF") {
        return {
            ...state,
            gridViewState: state.gridViewState.map((currentState) => {
                if (currentState.gridViewId !== action.gridViewId) {
                    return currentState;
                }
                currentState.gridDefinition = action.gridDefinition;
                return currentState;
            })
        }
    }

    if (action.type === "DELETE_GRID_DATA_BY_ID") {
        return {
            ...state,
            gridViewState: state.gridViewState.filter((currentState) =>
                currentState.gridViewId !== action.gridViewId)
        }
    }

    if (action.type === "KEEP_GRID_DATA_BY_ID") {
        return {
            ...state,
            gridViewState: state.gridViewState.filter((currentState) =>
                currentState.gridViewId === action.gridViewId)
        }
    }

    if (action.type === "KEEP_CHART_DATA_BY_ID") {
        return {
            ...state,
            chartState: state.chartState.filter((currentState) =>
                currentState.dataViewId === action.dataViewId)
        }
    }

    if (action.type === "KEEP_MAP_DATA_BY_ID") {
        return {
            ...state,
            mapState: state.mapState.filter((currentState) =>
                currentState.mapId === action.mapId)
        }
    }

    return state;
};

export default LeopardGridViewReducer;
