import React, {Component} from 'react';
import {Button} from 'devextreme-react';
import LeopardSecurity from '../security/LeopardSecurity';

class LeopardLoginUserInfoPanel extends Component {
    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    logoutButtonOnClick = () => {
        LeopardSecurity.UserLogout();
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                <div className="m-dropdown__body leopard-userinfo-panel">
                    <div className="orange-arrow-top"></div>
                    <div className="m-dropdown__content">
                        <div className={"leopard-leftmenu-category"} style={{opacity: "0.3"}}>
                            {"ACCOUNT"}
                        </div>
                        <div className={"leopard-leftmenu-item"} style={{opacity: "0.3"}}>
                            <div className={"leopard-leftmenu-item-text"}>
                                {"Update Profile"}
                            </div>
                        </div>
                        <div className={"leopard-leftmenu-item"} style={{opacity: "0.3"}}>
                            <div className={"leopard-leftmenu-item-text"}>
                                {"Change Password"}
                            </div>
                        </div>
                        <br/>
                        <div className="leopard-logout-button"
                             style={{marginRight: "20px", marginBottom: "20px"}}>
                            <Button text={'Logout'} id="topmenu_button_logout" onClick={
                                (e) => this.logoutButtonOnClick(e)}
                            ></Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardLoginUserInfoPanel;
