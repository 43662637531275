import React, {Component} from 'react';
import {connect} from 'react-redux';
import Box, {Item} from 'devextreme-react/box';
import $ from 'jquery';
import {Button, CheckBox, NumberBox, SelectBox, TextArea, TextBox} from 'devextreme-react';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import TabPanel from 'devextreme-react/tab-panel';
import FilterBuilder from 'devextreme-react/filter-builder';
import {UpdateGridViewDefinition} from '../foundation/LeopardActionCreators';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardDesignerTagBox from "./LeopardDesignerTagBox";
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LeopardTooltipWithText from "./LeopardTooltipWithText";
import LeopardCommandLinks from "./LeopardCommandLinks";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import LeopardTextEditorPopup from '../components/LeopardTextEditorPopup';
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import List from "devextreme-react/list";

class LeopardColumnDesigner extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.filterFields = [];
        this.filterBuilderPreviewValue = "";
        this.columnDefinitionToSave = [];
        this.hasColumnFilterChanged = false;
        this.filterBuilderValue = "";
        this.enableAdaptiveColumnOption = false;
        this.enableAutoRefresh = false;
        this.autoRefreshInterval = 30;
        this.customColumnOverallWidth = 200;
        this.clientSideQuery = "";
        this.dataExportJavaScript = "";
        this.oDataQueryForLinkedView = "";
        this.clearFilterOnParentSelectionChanged = true;
        this.customComputedColumnsText = "";
        this.customComputedColumns = [];
        this.showAutoRefreshSwitch = true;
        this.optimizePagerForLargeDataset = true;
        this.enablePagination = true;
        this.removeColumnSortOnFilterCleared = false;
        this.defaultPageSize = 10;
        this.gridViewRowKey = "";
        this.customColumnConfiguration = null;
        this.allowDraggingOption = false;
        this.allowDroppingOption = false;
        this.gridViewId = null;
        this.columnFieldList = [];
        this.columnFieldListForDropdown = [];
        this.dataSourceId = "";
        this.dataSourceCustomQueryTableSchema = "";
        this.dataSourceURI = "";
        this.uiObjectInstances = [];
        this.topLevelFilteringField = "";
        this.extraQueryForSingleDataView = "";
        this.urlForDataImportField = "";
        this.overrideDatabaseTableField = "";
        this.defaultValueLookupForOverrideDbField = "";
        this.exportMaximumRowLimit = LeopardStaticUIConfig.GridView_ExportMaximumRowLimit;
        this.explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        this.columnResizingMode = LeopardStaticUIConfig.GridView_ColumnResizingMode;
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    componentDidMount() {
        let definition = this.props.gridViewDefinition;
        this.columnDefinitionToSave = definition.columnDefinition;
        this.filterBuilderValue = definition.filterBuilderValue;
        this.filterBuilderPreviewValue = definition.filterBuilderValue;
        this.dataSourceId = definition.dataSourceId;
        this.dataSourceCustomQueryTableSchema = definition.dataSourceCustomQueryTableSchema;
        this.dataSourceURI = definition.dataSourceURI;
        this.gridViewRowKey = definition.gridViewRowKey;
        this.enableAdaptiveColumnOption = definition.adaptiveColumn;
        this.enableAutoRefresh = definition.enableAutoRefresh;
        this.autoRefreshInterval = definition.autoRefreshInterval;
        this.clientSideQuery = definition.clientSideQuery;
        this.dataExportJavaScript = definition.dataExportJavaScript;
        this.oDataQueryForLinkedView = definition.oDataQueryForLinkedView;
        this.clearFilterOnParentSelectionChanged = definition.clearFilterOnParentSelectionChanged;
        this.customComputedColumns = definition.customComputedColumns;
        this.customColumnOverallWidth = definition.customColumnOverallWidth;
        this.showAutoRefreshSwitch = definition.showAutoRefreshSwitch;
        this.customColumnConfiguration = definition.customColumnConfiguration;
        this.defaultPageSize = definition.defaultPageSize;
        this.optimizePagerForLargeDataset = definition.optimizePagerForLargeDataset;
        this.enablePagination = definition.enablePagination;
        this.removeColumnSortOnFilterCleared = definition.removeColumnSortOnFilterCleared;
        this.exportMaximumRowLimit = definition.exportMaximumRowLimit;

        if (LDH.IsValueEmpty(this.optimizePagerForLargeDataset)) {
            this.optimizePagerForLargeDataset = true;
        }
        if (LDH.IsValueEmpty(this.enablePagination)) {
            this.enablePagination = true;
        }
        if (LDH.IsValueEmpty(this.removeColumnSortOnFilterCleared)) {
            this.removeColumnSortOnFilterCleared = false;
        }
        if (LDH.IsValueEmpty(this.exportMaximumRowLimit)) {
            this.exportMaximumRowLimit = LeopardStaticUIConfig.GridView_ExportMaximumRowLimit;
        }
        if (LDH.IsValueEmpty(this.defaultPageSize)) {
            this.defaultPageSize = 10;
        }
        if (LDH.IsValueEmpty(definition.enableAutoRefresh)) {
            this.enableAutoRefresh = false;
        }
        if (LDH.IsValueEmpty(definition.autoRefreshInterval)) {
            this.autoRefreshInterval = 30;
        }
        if (LDH.IsValueEmpty(definition.gridViewRowKey)) {
            this.gridViewRowKey = "";
        }
        if (LDH.IsValueEmpty(definition.clientSideQuery)) {
            this.clientSideQuery = "";
        }
        if (LDH.IsValueEmpty(definition.dataExportJavaScript)) {
            this.dataExportJavaScript = "";
        }
        if (LDH.IsValueEmpty(definition.oDataQueryForLinkedView)) {
            this.oDataQueryForLinkedView = "";
        }
        if (LDH.IsValueEmpty(definition.clearFilterOnParentSelectionChanged)) {
            this.clearFilterOnParentSelectionChanged = true;
        }
        if (LDH.IsObjectNull(definition.customComputedColumns) ||
            LDH.IsValueEmpty(definition.customComputedColumns)) {
            this.customComputedColumns = [];
        }
        if (LDH.IsValueEmpty(definition.customColumnOverallWidth)) {
            this.customColumnOverallWidth = 200;
        }
        if (LDH.IsValueEmpty(definition.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = true;
        }

        this.allowDraggingOption = definition.allowDragging;
        this.columnResizingMode = definition.columnResizingMode;

        this.explicitFilterOption = definition.explicitFilterOption;
        if (LDH.IsValueEmpty(this.explicitFilterOption)) {
            this.explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        }

        this.topLevelFilteringField = definition.topLevelFilteringField;
        if (LDH.IsValueEmpty(this.topLevelFilteringField)) {
            this.topLevelFilteringField = "";
        }

        this.extraQueryForSingleDataView = definition.extraQueryForSingleDataView;
        if (LDH.IsValueEmpty(this.extraQueryForSingleDataView)) {
            this.extraQueryForSingleDataView = "";
        }

        this.urlForDataImportField = definition.urlForDataImportField;
        if (LDH.IsValueEmpty(this.urlForDataImportField)) {
            this.urlForDataImportField = "";
        }

        this.overrideDatabaseTableField = definition.overrideDatabaseTableField;
        if (LDH.IsValueEmpty(this.overrideDatabaseTableField)) {
            this.overrideDatabaseTableField = "";
        }

        this.defaultValueLookupForOverrideDbField = definition.defaultValueLookupForOverrideDbField;
        if (LDH.IsValueEmpty(this.defaultValueLookupForOverrideDbField)) {
            this.defaultValueLookupForOverrideDbField = "";
        }

        this.gridViewId = this.props.gridViewId;
        this.columnFieldList = this.props.columnFieldList.fullColumns;

        this.columnFieldListForDropdown = [];
        for (let i = 0; i < this.columnFieldList.length; i++) {
            this.columnFieldListForDropdown.push({
                value: this.columnFieldList[i],
                text: this.columnFieldList[i]
            });
        }

        setTimeout(function () {
            $('.leopard-table-with-fixedcolumn tbody').scroll(function () {
                let $tbody = $(".leopard-table-with-fixedcolumn tbody");
                let scroll = $tbody.scrollLeft();
                $('.leopard-table-with-fixedcolumn thead').css("left", -scroll);
                $('.leopard-table-with-fixedcolumn thead th:nth-child(1)').css("left", scroll);
                $('.leopard-table-with-fixedcolumn tbody td:nth-child(1)').css("left", scroll);
            });
            LRH.TriggerWindowResizeEvent();
        }, 100);
    };

    componentWillUnmount = () => {
        let instances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(instances);
    };

    addUIObjectInstance = (data) => {
        if (data.ref === null || data.ref.instance === null) return;
        this.uiObjectInstances[data.uiObjectName] = data.ref.instance;
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    leopardDesignerTagBoxOnOpened = (data) => {
        let columnName = data.columnName;
        let instance = this.uiObjectInstances["columnType" + columnName];
        if (LDH.IsObjectNull(instance)) return;

        let columnType = instance.option("value");
        if (LDH.IsObjectNull(columnType) || columnType === "string") {
            data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionStringFilterOperations);
        }
        if (LDH.IsObjectNull(columnType) || columnType === "guid") {
            data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionStringFilterOperations);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "number") {
            data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionNumberFilterOperations);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "boolean") {
            data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionBoolFilterOperations);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "date") {
            data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionDateFilterOperations);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "datetime") {
            data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionDateFilterOperations);
        }
    };

    leopardDesignerTagBoxOnClosed = (data) => {
        let columnName = data.columnName;
        let instance = this.uiObjectInstances["columnType" + columnName];
        if (LDH.IsObjectNull(instance)) return;

        data.e.component.option("items", LeopardDropdownHelper.DropdownSelectionAllFilterOperations);
    };

    previewButtonOnClick = (callback, filter) => {
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");
        let that = this;
        LDH.DeleteLocalStorageById("GridViewLayoutState_" + that.gridViewId);

        setTimeout(() => {
            that.filterBuilderValue = filter;

            let gridDefinition = {
                columnDefinition: that.columnDefinitionToSave,
                adaptiveColumn: that.enableAdaptiveColumnOption,
                optimizePagerForLargeDataset: that.optimizePagerForLargeDataset,
                enablePagination: that.enablePagination,
                removeColumnSortOnFilterCleared: that.removeColumnSortOnFilterCleared,
                exportMaximumRowLimit: that.exportMaximumRowLimit,
                defaultPageSize: that.defaultPageSize,
                gridViewRowKey: that.gridViewRowKey,
                customColumnConfiguration: that.customColumnConfiguration,
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                topLevelFilteringField: that.topLevelFilteringField,
                extraQueryForSingleDataView: that.extraQueryForSingleDataView,
                urlForDataImportField: that.urlForDataImportField,
                overrideDatabaseTableField: that.overrideDatabaseTableField,
                defaultValueLookupForOverrideDbField: that.defaultValueLookupForOverrideDbField,
                filterBuilderValue: that.filterBuilderValue,
                dataSourceId: that.dataSourceId,
                dataSourceCustomQueryTableSchema: that.dataSourceCustomQueryTableSchema,
                dataSourceURI: that.dataSourceURI,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
                enableAutoRefresh: that.enableAutoRefresh,
                autoRefreshInterval: that.autoRefreshInterval,
                clientSideQuery: that.clientSideQuery,
                dataExportJavaScript: that.dataExportJavaScript,
                oDataQueryForLinkedView: that.oDataQueryForLinkedView,
                clearFilterOnParentSelectionChanged: that.clearFilterOnParentSelectionChanged,
                customComputedColumns: that.customComputedColumns,
                customColumnOverallWidth: that.customColumnOverallWidth,
                showAutoRefreshSwitch: that.showAutoRefreshSwitch
            };
            that.props.UpdateGridViewDefinition(gridDefinition, that.gridViewId);
            that.props.updateGridViewDefinitionOnSingleDataView(gridDefinition);

            that.props.applyGridViewConfigurationOnDataView({
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                topLevelFilteringField: that.topLevelFilteringField,
                extraQueryForSingleDataView: that.extraQueryForSingleDataView,
                urlForDataImportField: that.urlForDataImportField,
                overrideDatabaseTableField: that.overrideDatabaseTableField,
                defaultValueLookupForOverrideDbField: that.defaultValueLookupForOverrideDbField,
                filterBuilderValue: that.filterBuilderValue,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
                dataSourceId: that.dataSourceId,
                dataSourceCustomQueryTableSchema: that.dataSourceCustomQueryTableSchema,
                dataSourceURI: that.dataSourceURI,
                enableAutoRefresh: that.enableAutoRefresh,
                autoRefreshInterval: that.autoRefreshInterval,
                clientSideQuery: that.clientSideQuery,
                dataExportJavaScript: that.dataExportJavaScript,
                oDataQueryForLinkedView: that.oDataQueryForLinkedView,
                clearFilterOnParentSelectionChanged: that.clearFilterOnParentSelectionChanged,
                customComputedColumns: that.customComputedColumns,
                customColumnOverallWidth: that.customColumnOverallWidth,
                showAutoRefreshSwitch: that.showAutoRefreshSwitch
            });

            if (!LDH.IsObjectNull(that.enableAutoRefresh) && that.enableAutoRefresh === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }

            if (callback !== undefined && callback !== null) {
                callback();
            }

            that.cancelButtonOnClick();
            $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
        }, 100);
    };

    saveButtonOnClick = () => {
        let that = this;
        this.filterBuilderValue = this.filterBuilderPreviewValue;

        let columnDefinition = this.columnDefinitionToSave;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");

        for (let i = 0; i < this.columnDefinitionToSave.length; i++) {
            this.columnDefinitionToSave[i].hasSorted = false;
        }
        LDH.DeleteLocalStorageById("GridViewLayoutState_" + that.gridViewId);

        setTimeout(function () {
            LeopardAjaxHelper.UpdateDataViewDefinitionByType(userId, organizationId, that.gridViewId, {
                columnDefinition,
                adaptiveColumn: that.enableAdaptiveColumnOption,
                enableAutoRefresh: that.enableAutoRefresh,
                autoRefreshInterval: that.autoRefreshInterval,
                clientSideQuery: that.clientSideQuery,
                dataExportJavaScript: that.dataExportJavaScript,
                oDataQueryForLinkedView: that.oDataQueryForLinkedView,
                clearFilterOnParentSelectionChanged: that.clearFilterOnParentSelectionChanged,
                customComputedColumns: that.customComputedColumns,
                customColumnOverallWidth: that.customColumnOverallWidth,
                showAutoRefreshSwitch: that.showAutoRefreshSwitch,
                optimizePagerForLargeDataset: that.optimizePagerForLargeDataset,
                enablePagination: that.enablePagination,
                removeColumnSortOnFilterCleared: that.removeColumnSortOnFilterCleared,
                exportMaximumRowLimit: that.exportMaximumRowLimit,
                defaultPageSize: that.defaultPageSize,
                gridViewRowKey: that.gridViewRowKey,
                customColumnConfiguration: that.customColumnConfiguration,
                dataSourceId: that.dataSourceId,
                dataSourceCustomQueryTableSchema: that.dataSourceCustomQueryTableSchema,
                dataSourceURI: that.dataSourceURI,
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                topLevelFilteringField: that.topLevelFilteringField,
                extraQueryForSingleDataView: that.extraQueryForSingleDataView,
                urlForDataImportField: that.urlForDataImportField,
                overrideDatabaseTableField: that.overrideDatabaseTableField,
                defaultValueLookupForOverrideDbField: that.defaultValueLookupForOverrideDbField,
                filterBuilderValue: that.filterBuilderValue,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
            }, function () {
                LRH.ShowToast("Your Grid View configuration has been successfully saved.", "success", 5000);
                $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                }
                $(".leopard-columndesigner-loading-progress").css("visibility", "hidden")
            }, "gridview");
        }, 100);
    };

    cancelButtonOnClick = () => {
        let that = this;
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");
        setTimeout(function () {
            that.props.gridViewBuilderCancelButtonOnClick();
        }, 100);
    };

    enableAdaptiveColumnsOnChange = (e) => {
        this.enableAdaptiveColumnOption = e.value;

        this.uiObjectInstances["columnResizingMode"]
            .option("disabled", e.value);

        if (this.columnResizingMode === "widget") {
            this.uiObjectInstances["columnResizingMode"]
                .option("value", "nextColumn");
        }
    };

    enableAutoRefreshOnChange = (e) => {
        this.enableAutoRefresh = e.value;

        if (e.value === true) {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
        } else {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
        }
        this.disablePreviewButton();
    };

    clearFilterOnParentSelectionChangedOnChange = (e) => {
        this.clearFilterOnParentSelectionChanged = e.value;
        this.disablePreviewButton();
    };

    showAutoRefreshSwitchOnChange = (e) => {
        this.showAutoRefreshSwitch = e.value;
        this.disablePreviewButton();
    };

    autoRefreshIntervalOnChange = (e) => {
        this.autoRefreshInterval = e.value;
        this.disablePreviewButton();
    };

    clientSideQueryOnValueChanged = (e) => {
        this.clientSideQuery = e.value;
        this.disablePreviewButton();
    };

    dataExportJavaScriptOnValueChanged = (e) => {
        this.dataExportJavaScript = e.value;
        this.disablePreviewButton();
    };

    customComputedColumnsOnValueChanged = (e) => {
        this.customComputedColumnsText = e.value;
        this.disablePreviewButton();
    };

    customComputedColumnsListOnValueChanged = () => {
        this.disablePreviewButton();
    };

    oDataQueryForLinkedViewOnValueChanged = (e) => {
        this.oDataQueryForLinkedView = e.value;
        this.disablePreviewButton();
    };

    optimizePagerForLargeDatasetOnChange = (e) => {
        this.optimizePagerForLargeDataset = e.value;
        this.disablePreviewButton();
    };

    removeColumnSortOnFilterClearedOnChange = (e) => {
        this.removeColumnSortOnFilterCleared = e.value;
        this.disablePreviewButton();
    };

    enablePaginationOnChange = (e) => {
        this.enablePagination = e.value;
        let input = this.uiObjectInstances["optimizePagerForLargeDatasetInput"];
        if (e.value === false) {
            input.option("disabled", true);
            input.option("value", false);
        } else {
            input.option("disabled", false);
        }
        this.disablePreviewButton();
    };

    disablePreviewButton = () => {
        this.uiObjectInstances["buttonPreview"].option("disabled", true);
    };

    columnColumnDataOnChanged = (data) => {
        this.customColumnConfiguration = data;
    };

    defaultPageSizeOnChange = (e) => {
        this.defaultPageSize = e.value;
        this.disablePreviewButton();
    };

    gridViewRowKeyOnChange = (e) => {
        this.gridViewRowKey = e.value;
        this.disablePreviewButton();
    };

    columnResizingModeOnChange = (e) => {
        this.columnResizingMode = e.value;
    };

    explicitFilterOptionOnChange = (e) => {
        this.explicitFilterOption = e.value;
    };

    topLevelFilteringFieldOnChange = (e) => {
        this.topLevelFilteringField = e.value;
        this.disablePreviewButton();
    };

    urlForDataImportFieldOnChange = (e) => {
        this.urlForDataImportField = e.value;
        this.disablePreviewButton();
    };

    exportMaximumRowLimitOnChange = (e) => {
        this.exportMaximumRowLimit = e.value;
        this.disablePreviewButton();
    };

    overrideDatabaseTableFieldOnChange = (e) => {
        this.overrideDatabaseTableField = e.value;
        this.disablePreviewButton();
    };

    defaultValueLookupForOverrideDbFieldOnChange = (e) => {
        this.defaultValueLookupForOverrideDbField = e.value;
        this.disablePreviewButton();
    };

    extraQueryForSingleDataViewOnChange = (e) => {
        this.extraQueryForSingleDataView = e.value;
        this.disablePreviewButton();
    };

    filterBuilderOnValueChanged = (e) => {
        this.filterBuilderPreviewValue = e.value;
    };

    validationRulesPopupOnClick = (data) => {
        let instances = data.thisComp.uiObjectInstances;
        if (LDH.IsObjectNull(instances.leopardTextEditorPopup) ||
            LDH.IsObjectNull(instances[data.textboxRef])) {
            return;
        }

        LeopardStaticUIConfig.Global_PopupTempData =
            instances[data.textboxRef].option("value");

        LeopardStaticUIConfig.Global_PopupTempObjectInstance = {
            popup: instances.leopardTextEditorPopup,
            textbox: instances[data.textboxRef]
        };

        instances.leopardTextEditorPopup.option("visible", true);
    };

    updateUIStateOnColumnTypeChanged = (columnName, columnType) => {
        let instanceFO = this.uiObjectInstances["filterOperations" + columnName];
        let instanceDO = this.uiObjectInstances["defaultOperation" + columnName];
        let instanceAAC = this.uiObjectInstances["allowAutoComplete" + columnName];

        instanceAAC.option("value", false);
        instanceFO.option("disabled", false);
        instanceDO.option("disabled", false);

        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            instanceAAC.option("disabled", false);
            instanceFO.option("value", ["contains"]);
            instanceDO.option("value", "contains");
        }
        if (LDH.IsValueEmpty(columnType) || columnType === "guid") {
            instanceAAC.option("disabled", false);
            instanceFO.option("value", ["contains"]);
            instanceDO.option("value", "contains");
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "number") {
            instanceAAC.option("disabled", true);
            instanceFO.option("value", ["="]);
            instanceDO.option("value", "=");
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "boolean") {
            instanceAAC.option("disabled", true);
            instanceFO.option("value", ["="]);
            instanceDO.option("value", "=");
        }
        if (!LDH.IsValueEmpty(columnType) && (columnType === "date" ||
            columnType === "datetime")) {
            instanceAAC.option("disabled", true);
            instanceFO.option("value", ["between"]);
            instanceDO.option("value", "between");
        }
    };

    updateUIStateOnInitialize = (columnName, columnType) => {
        let instanceFO = this.uiObjectInstances["filterOperations" + columnName];
        let instanceDO = this.uiObjectInstances["defaultOperation" + columnName];
        let instanceAAC = this.uiObjectInstances["allowAutoComplete" + columnName];

        if (LDH.IsObjectNull(instanceFO) || LDH.IsObjectNull(instanceDO) ||
            LDH.IsObjectNull(instanceAAC)) {
            return;
        }

        instanceFO.option("disabled", false);
        instanceDO.option("disabled", false);

        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            instanceAAC.option("disabled", false);
        }
        if (LDH.IsValueEmpty(columnType) || columnType === "guid") {
            instanceAAC.option("disabled", false);
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "number") {
            instanceAAC.option("disabled", true);
            instanceAAC.option("value", false);
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "boolean") {
            instanceAAC.option("disabled", true);
            instanceAAC.option("value", false);
        }
        if (!LDH.IsValueEmpty(columnType) && (columnType === "date" ||
            columnType === "datetime")) {
            instanceAAC.option("disabled", true);
            instanceAAC.option("value", false);
        }

        if (instanceAAC.option("value") === true) {
            instanceFO.option("disabled", true);
            instanceDO.option("disabled", true);
        } else {
            instanceFO.option("disabled", false);
            instanceDO.option("disabled", false);
        }
    };

    updateColumnDefinition = (data) => {
        let updateType = data.updateType;
        let clonedColumnDef = LDH.DeepClone(this.columnDefinitionToSave);

        if (updateType === "visibleIndex" || updateType === "allowAutoComplete" ||
            updateType === "isEnabled" || updateType === "sortOrder" ||
            updateType === "autoCompleteOperation") {
            this.disablePreviewButton();
        }

        let column = clonedColumnDef.filter(c => {
            return c.columnName === data.column.columnName;
        });
        if (column === undefined || column === null || column.length === 0) {
            if (data.e.fieldType === "filterOperations") {
                data.column[updateType] = data.e.fieldValue;
            } else {
                data.column[updateType] = data.e.value;
            }
            clonedColumnDef.push(data.column);
        } else {
            if (data.e.fieldType === "filterOperations") {
                column[0][updateType] = data.e.fieldValue;
            } else {
                column[0][updateType] = data.e.value;
            }
        }

        this.columnDefinitionToSave = clonedColumnDef;
        if (updateType === "allowFiltering" && data.e.value === false) {
            let userProfile = window.userProfile;
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

            if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
            }
            this.hasColumnFilterChanged = true;
            this.filterBuilderValue = defaultFilter;
            this.filterBuilderPreviewValue = "";
            $(".leopard-settings-warning-text").show();
        }

        let dataColumn;
        if ((column === undefined || column === null || column.length === 0) &&
            !LDH.IsObjectNull(data.column)) {
            dataColumn = data.column;
        } else {
            dataColumn = column[0];
        }
        if (LDH.IsObjectNull(dataColumn)) return;

        if (updateType === "columnType") {
            let cn = dataColumn.columnName;
            this.updateUIStateOnColumnTypeChanged(cn, data.e.value);
        }

        if (updateType === "allowAutoComplete") {
            let cn = dataColumn.columnName;
            let instanceFO = this.uiObjectInstances["filterOperations" + cn];
            let instanceDO = this.uiObjectInstances["defaultOperation" + cn];
            let instanceType = this.uiObjectInstances["columnType" + cn];

            if (data.e.value === true) {
                instanceFO.option("value", ["="]);
                instanceDO.option("value", "=");
            }

            if (instanceType.option("value") === "string" ||
                instanceType.option("value") === "guid") {
                instanceFO.option("disabled", data.e.value);
                instanceDO.option("disabled", data.e.value);
            } else {
                instanceFO.option("disabled", false);
                instanceDO.option("disabled", false);
            }
        }
    };

    onOptionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            if (!LDH.IsObjectNull(this.enableAutoRefresh) && this.enableAutoRefresh === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }
        }
    };

    onItemRendered = () => {
        if (!LDH.IsObjectNull(this.enableAutoRefresh) && this.enableAutoRefresh === true) {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
        } else {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
        }
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                {
                    data.tabId !== 1 ?
                        <span style={{paddingLeft: "5px", paddingRight: "5px"}}>
                            {data.tabTitle}
                        </span>
                        :
                        <React.Fragment>
                            <span style={{paddingLeft: "5px"}}>
                                {data.tabTitle}
                            </span>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}
                               style={{top: "-7px", left: "2px", paddingRight: "5px"}}>
                            </i>
                        </React.Fragment>
                }
            </React.Fragment>
        );
    };

    listViewTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    tabItemContent = (data) => {
        let that = this;

        if (data.e.tabId === 0) {
            setTimeout(function () {
                for (let j = 0; j < that.columnDefinitionToSave.length; j++) {
                    let columnType = that.columnDefinitionToSave[j].columnType;
                    let columnName = that.columnDefinitionToSave[j].columnName;
                    that.updateUIStateOnInitialize(columnName, columnType);
                }
            }, 100);
            return (
                <React.Fragment>
                    <div>
                        <div className="leopard-table-with-fixedcolumn-container">
                            <table className="leopard-table-with-fixedcolumn">
                                <thead>
                                <tr>
                                    <th className={"leopard-config-table-header leopard-table-fixed-column column-name-header"}>
                                        Column Name
                                    </th>
                                    <th className={"leopard-config-table-header"}>
                                        Column Type
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "92px"}}>
                                        Alignment
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "92px"}}>
                                        Min Width
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "92px"}}>
                                        Width
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "117px"}}>
                                        <span>Order Index</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "141px"}}>
                                        Data Key Name
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "141px"}}>
                                        Data Key Format
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "141px"}}>
                                        Data Value Format
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "203px"}}>
                                        <span>Filter Operations</span>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "130px"}}>
                                        <span>Default Operation</span>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "133px"}}>
                                        <LeopardTooltipWithText elementId={"autoCompleteOperationHeader_help"}
                                                                labelText={"AC Operation"} width={200}
                                                                text={"Auto-Complete Operation"}/>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "132px"}}>
                                        <span>Custom Heading</span>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "152px"}}>
                                        <span>Validation Rules</span>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "132px"}}>
                                        <span>Default Value</span>
                                    </th>
                                    <th className={"leopard-config-table-header"}><span>Sort Order</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"autoCompleteHeader_help"}
                                                                labelText={"AC"} width={150}
                                                                text={"Auto-Complete"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"resizingHeader_help"}
                                                                labelText={"RE"} width={100}
                                                                text={"Resizing"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"sortingHeader_help"}
                                                                labelText={"SO"} width={100}
                                                                text={"Sorting"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"columnFilterHeader_help"}
                                                                labelText={"CF"} width={100}
                                                                text={"Column Filter"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"headerFilterHeader_help"}
                                                                labelText={"HF"} width={150}
                                                                text={"Header Filter"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"orderingHeader_help"}
                                                                labelText={"OR"} width={100}
                                                                text={"Ordering"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"fixedColumnHeader_help"}
                                                                labelText={"FC"} width={100}
                                                                text={"Fixed Column"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"visibleHeader_help"}
                                                                labelText={"VI"} width={100}
                                                                text={"Visible"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"enabledHeader_help"}
                                                                labelText={"EN"} width={100}
                                                                text={"Enabled"}/>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <RenderGridViewColumnDefinition
                                    gridColumns={this.columnFieldList} state={this.state}
                                    updateColumnDefinition={(e) => this.updateColumnDefinition(e)}
                                    thisComp={this}>
                                </RenderGridViewColumnDefinition>
                                </tbody>
                            </table>
                        </div>
                        <div className="leopard-settings-warning-text" style={{display: "none"}}>
                            Warning: You have changed the Filter settings. The Advanced Filter has now been reset.
                        </div>
                    </div>
                    <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does
                        not support preview.
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <LeopardCommandLinks columnFieldListForDropdown={this.columnFieldListForDropdown}
                                             disablePreviewButton={this.disablePreviewButton}
                                             customColumnConfiguration={this.customColumnConfiguration}
                                             columnColumnDataOnChanged={(e) => this.columnColumnDataOnChanged(e)}
                        />
                    </div>
                    <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does
                        not support preview.
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 2) {
            setTimeout(function () {
                if (!LDH.IsObjectNull(that.uiObjectInstances["columnResizingMode"])) {
                    that.uiObjectInstances["columnResizingMode"].option(
                        "disabled", that.enableAdaptiveColumnOption);
                }

                if (!LDH.IsObjectNull(that.uiObjectInstances["optimizePagerForLargeDatasetInput"]) &&
                    that.enablePagination === false) {
                    that.uiObjectInstances["optimizePagerForLargeDatasetInput"].option("disabled", true);
                } else {
                    that.uiObjectInstances["optimizePagerForLargeDatasetInput"].option("disabled", false);
                }
            }, 100);

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Optimisation</div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"enableAdaptiveColumnOption_help"}
                                                title={"Enable adaptive columns"}
                                                text={"The grid view can adapt its layout to narrow screens. " +
                                                "If screen width is not enough to display all columns, the widget hides them according to the specified priority. " +
                                                "To view values of hidden columns, click the ellipsis button in the last column of the required row."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Enable adaptive columns:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                       displayExpr={'name'}
                                                       valueExpr={'id'}
                                                       defaultValue={this.enableAdaptiveColumnOption}
                                                       onValueChanged={(e) => this.enableAdaptiveColumnsOnChange(e)}>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"optimizePagerForLargeDataset_help"}
                                                title={"Optimise pager for large dataset"}
                                                text={"The grid view allows user to navigate data page by page and prevents user from jumping to a large page number. " +
                                                "It is recommended to turn on this feature if you have 500,000+ rows of data."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Optimise pager for large dataset:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                displayExpr={'name'}
                                                valueExpr={'id'}
                                                defaultValue={this.optimizePagerForLargeDataset}
                                                ref={(e) => this.addUIObjectInstance({
                                                    uiObjectName: "optimizePagerForLargeDatasetInput", ref: e
                                                })}
                                                onValueChanged={(e) => this.optimizePagerForLargeDatasetOnChange(e)}>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"defaultPageSize_help"}
                                                title={"Number of records per page"}
                                                text={"Specify the number of records per page."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Number of records per page:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionGridViewDefaultPageSize}
                                                displayExpr={'text'}
                                                valueExpr={'value'}
                                                defaultValue={this.defaultPageSize}
                                                onValueChanged={(e) => this.defaultPageSizeOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"gridViewRowKey_help"}
                                                title={"Specify a row key"}
                                                text={"Specify a row key for the grid view. If the row key is not specified, the grid view uses the entire row data as a key."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Specify a row key:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={this.columnFieldListForDropdown}
                                                displayExpr={'text'} valueExpr={'value'}
                                                defaultValue={this.gridViewRowKey}
                                                onValueChanged={(e) => this.gridViewRowKeyOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"enablePagination_help"}
                                                title={"Enable pagination"}
                                                text={"Enable or disable the pagination control for the grid view."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Enable pagination:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                displayExpr={'name'}
                                                valueExpr={'id'}
                                                defaultValue={this.enablePagination}
                                                onValueChanged={(e) => this.enablePaginationOnChange(e)}>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"removeSortOnFilterCleared_help"}
                                                title={"Remove column sort"}
                                                text={"Remove column sort on filter cleared."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Remove column sort on filter cleared:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                displayExpr={'name'}
                                                valueExpr={'id'}
                                                defaultValue={this.removeColumnSortOnFilterCleared}
                                                onValueChanged={(e) => this.removeColumnSortOnFilterClearedOnChange(e)}>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"exportMaximumRowLimit_help"}
                                                title={"Maximum row limit"}
                                                text={"Maximum row limit for the data exporting feature."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Maximum row limit for export:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <NumberBox defaultValue={this.exportMaximumRowLimit} showSpinButtons={true}
                                                       min={1} max={99999999} format={"#"}
                                                       onValueChanged={(e) => this.exportMaximumRowLimitOnChange(e)}>
                                            </NumberBox>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">General</div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"columnResizingMode_help"}
                                                title={"Column resizing mode"}
                                                text={"The grid view allows a user to resize columns at runtime. They can be resized in two different modes: " +
                                                "by changing the width of the next column or the width of the grid view."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Column resizing mode:</span>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionGridViewColumnResizingMode}
                                                displayExpr={'text'}
                                                valueExpr={'value'} defaultValue={this.columnResizingMode}
                                                ref={(e) => this.addUIObjectInstance({
                                                    uiObjectName: "columnResizingMode", ref: e
                                                })}
                                                onValueChanged={(e) => this.columnResizingModeOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"explicitFilterOption_help"}
                                                title={"Explicit data filtering"}
                                                text={"The grid view allows a user to filter values on multiple columns, and sends the " +
                                                "request to the server by pressing the Enter key or clicking on Apply Filter button."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Explicit data filtering:</span>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionGridViewFilterOptions}
                                                displayExpr={'text'}
                                                valueExpr={'value'} defaultValue={this.explicitFilterOption}
                                                onValueChanged={(e) => this.explicitFilterOptionOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"topLevelFilteringField_help"}
                                                title={"Top level filtering field"}
                                                text={"Specify a top level filtering field to restrict users from accessing other users information in a different group."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Top level filtering field:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <TextBox defaultValue={this.topLevelFilteringField}
                                                     placeholder={"UserGroupId|'{user-group-id}'"}
                                                     onValueChanged={(e) => this.topLevelFilteringFieldOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"UrlForDataImportField_help"}
                                                title={"URL for data import"}
                                                text={"Specify an URL for importing data to the server."}>
                                            </LeopardTooltipWithIcon>
                                            <span>URL for data import:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <TextBox defaultValue={this.urlForDataImportField}
                                                     placeholder={"/users"}
                                                     onValueChanged={(e) => this.urlForDataImportFieldOnChange(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"OverrideDatabaseTableField_help"}
                                                title={"Override database table"}
                                                text={"Specify a database table for retrieving data from the server."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Override database table:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <TextBox defaultValue={this.overrideDatabaseTableField}
                                                     placeholder={(!LDH.IsObjectNull(this.dataSourceCustomQueryTableSchema) &&
                                                         !LDH.IsValueEmpty(this.dataSourceCustomQueryTableSchema)) ?
                                                         "" : "transaction/state.item"}
                                                     disabled={(!LDH.IsObjectNull(this.dataSourceCustomQueryTableSchema) &&
                                                         !LDH.IsValueEmpty(this.dataSourceCustomQueryTableSchema))}
                                                     onValueChanged={(e) => this.overrideDatabaseTableFieldOnChange(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"DefaultValueLookupField_help"}
                                                title={"Default values for overriding database table"}
                                                text={"Specify a default value lookup for overriding database table."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Default values for overriding table:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <TextBox defaultValue={this.defaultValueLookupForOverrideDbField}
                                                     placeholder={(!LDH.IsObjectNull(this.dataSourceCustomQueryTableSchema) &&
                                                         !LDH.IsValueEmpty(this.dataSourceCustomQueryTableSchema)) ?
                                                         "" : "{EventChainId}|0,{Barcode}|'blank',{Id}|null"}
                                                     disabled={(!LDH.IsObjectNull(this.dataSourceCustomQueryTableSchema) &&
                                                         !LDH.IsValueEmpty(this.dataSourceCustomQueryTableSchema))}
                                                     onValueChanged={(e) => this.defaultValueLookupForOverrideDbFieldOnChange(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"extraQueryForSingleDataViewField_help"}
                                                title={"Extra query for single data view"}
                                                text={"This extra query will be executed only if you open this Data View standalone."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Extra query for single data view:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <TextBox defaultValue={this.extraQueryForSingleDataView}
                                                     placeholder={"?$top=10"}
                                                     onValueChanged={(e) => this.extraQueryForSingleDataViewOnChange(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Real-time update</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"enableAutoRefreshOnGridView_help"}
                                                title={"Enable auto-refresh"}
                                                text={"You can enable the auto-refresh feature to achieve real-time data update on the grid view."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Enable auto-refresh:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                       displayExpr={'name'}
                                                       valueExpr={'id'} defaultValue={this.enableAutoRefresh}
                                                       onValueChanged={(e) => this.enableAutoRefreshOnChange(e)}>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup auto-refresh-feature">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"autoRefreshInternalOnGridView_help"}
                                                title={"Auto-refresh interval (seconds)"}
                                                text={"Specify the number of seconds for the data to be automatically refreshed."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Auto-refresh interval (seconds):</span>
                                        </div>
                                        <div>
                                            <NumberBox defaultValue={this.autoRefreshInterval} showSpinButtons={true}
                                                       min={5} max={9999} format={"#"}
                                                       onValueChanged={(e) => this.autoRefreshIntervalOnChange(e)}>
                                            </NumberBox>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup auto-refresh-feature">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"showAutoRefreshSwitchOnGridView_help"}
                                                title={"Show auto-refresh switch"}
                                                text={"You can hide the auto-refresh switch on the grid view but still allow it working in the background."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Show auto-refresh switch:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                       displayExpr={'name'}
                                                       valueExpr={'id'} defaultValue={this.showAutoRefreshSwitch}
                                                       onValueChanged={(e) => this.showAutoRefreshSwitchOnChange(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Workspace Interactive Features</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"odataCustomQuery_help"}
                                                title={"OData paramters"}
                                                text={"The Odata parameters will be used when the data view is linked with another data view in a workspace."}>
                                            </LeopardTooltipWithIcon>
                                            <span>OData paramters:</span>
                                        </div>
                                        <TextArea defaultValue={this.oDataQueryForLinkedView} height={"259px"}
                                                  id={"Textbox_oDataCustomQuery"}
                                                  placeholder={"Id eq {Id} or Barcode eq '{Barcode}'"}
                                                  onValueChanged={(e) => this.oDataQueryForLinkedViewOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"clearFilterOnParentSelectionChanged_help"}
                                                title={"Reset filter"}
                                                text={"The filter criteria of grid view will be reset when the selection of parent panel has changed."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Reset filter on parent selection changed:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                   displayExpr={'name'}
                                                   valueExpr={'id'}
                                                   defaultValue={this.clearFilterOnParentSelectionChanged}
                                                   onValueChanged={(e) => this.clearFilterOnParentSelectionChangedOnChange(e)}>
                                        </SelectBox>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Client-Side Processing</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"clientSideQuery_help"}
                                                title={"Data processing"}
                                                text={"Write a custom logic in Javascript to process the data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Data processing:</span>
                                        </div>
                                        <TextArea defaultValue={this.clientSideQuery} height={"143px"}
                                                  id={"Textbox_clientSideQueryEditor"}
                                                  placeholder={"Use the variable \"data\" to access the data."}
                                                  onValueChanged={(e) => this.clientSideQueryOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"dataExportJavaScript_help"}
                                                title={"Data exporting"}
                                                text={"Write a custom logic in Javascript for exporting data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                            <span>Data exporting:</span>
                                        </div>
                                        <TextArea defaultValue={this.dataExportJavaScript} height={"143px"}
                                                  id={"Textbox_dataExportJavaScript"}
                                                  placeholder={"Use the variable \"gridCell\" to access the data."}
                                                  onValueChanged={(e) => this.dataExportJavaScriptOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Custom Computed Columns</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"customComputedColumns_help"}
                                                title={"List of computed columns"}
                                                text={"Specify custom computed columns for the grid view. " +
                                                "You'll need to write JavaScript code for processing data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                            <span>List of computed columns:</span>
                                        </div>
                                        <div>
                                            <div className="leopard-settings-panel-content-label">
                                                <TextBox maxLength={100} showClearButton={true}
                                                         ref={(e) => this.addUIObjectInstance({
                                                             uiObjectName: "customComputedColumnsInput",
                                                             ref: e
                                                         })}
                                                         onValueChanged={(e) => this.customComputedColumnsOnValueChanged(e)}>
                                                    <TextBoxButton name={"customComputedColumnsInput_MiniButton"}
                                                                   location={'after'}
                                                                   options={{
                                                                       icon: "fas fa-plus",
                                                                       type: 'default',
                                                                       onClick: () => {
                                                                           if (LDH.IsValueEmpty(that.customComputedColumnsText)) {
                                                                               return;
                                                                           }
                                                                           that.customComputedColumns.push({
                                                                               id: "customColumns_" + (that.customComputedColumns.length + 1),
                                                                               name: "_" + that.customComputedColumnsText
                                                                           });
                                                                           that.uiObjectInstances["customComputedColumnsList"].option(
                                                                               "dataSource", that.customComputedColumns);
                                                                           that.uiObjectInstances["customComputedColumnsInput"].option("value", "");
                                                                           that.uiObjectInstances["customComputedColumnsInput"].focus();
                                                                       }
                                                                   }}>
                                                    </TextBoxButton>
                                                </TextBox>
                                            </div>
                                            <div className={"leopard-listcontainer"} style={{height: "285px"}}>
                                                <List allowItemDeleting={true} activeStateEnabled={false}
                                                      noDataText={"Click the Add button to add a custom column"}
                                                      hoverStateEnabled={false} focusStateEnabled={false}
                                                      onItemDeleted={this.customComputedColumnsListOnValueChanged}
                                                      dataSource={this.customComputedColumns}
                                                      ref={(e) => this.addUIObjectInstance({
                                                          uiObjectName: "customComputedColumnsList",
                                                          ref: e
                                                      })}
                                                      itemRender={this.listViewTemplate}>
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting
                            does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 3) {
            let that = this;
            if (this.hasColumnFilterChanged === true || this.filterFields.length === 0) {
                that.filterFields = [];
                for (let i = 0; i < that.columnFieldList.length; i++) {
                    let gridColumnName = that.columnFieldList[i];
                    let columnDataType = "string";
                    let allowFiltering = true;

                    let isCustomColumn = false;
                    if (!LDH.IsObjectNull(that.props.gridViewDefinition.customComputedColumns)) {
                        let customColumns = that.props.gridViewDefinition.customComputedColumns;
                        for (let v = 0; v < customColumns.length; v++) {
                            let cName = customColumns[v].name.toLowerCase().trim();
                            if (cName === gridColumnName.toLowerCase().trim()) {
                                isCustomColumn = true;
                                break;
                            }
                        }
                    }

                    for (let j = 0; j < that.columnDefinitionToSave.length; j++) {
                        let columnName = that.columnDefinitionToSave[j].columnName;
                        if (columnName !== gridColumnName) continue;
                        if (that.columnDefinitionToSave[j].columnType !== undefined &&
                            that.columnDefinitionToSave[j].columnType !== "") {
                            columnDataType = that.columnDefinitionToSave[j].columnType;
                        }
                        if (isCustomColumn ||
                            (that.columnDefinitionToSave[j].allowFiltering !== undefined &&
                                that.columnDefinitionToSave[j].allowFiltering === false) ||
                            (!LDH.IsObjectNull(that.columnDefinitionToSave[j].isEnabled) &&
                                that.columnDefinitionToSave[j].isEnabled === false)) {
                            allowFiltering = false;
                        }
                    }

                    if (allowFiltering === true) {
                        if (columnDataType === "date") {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType,
                                format: "dd/MM/yyyy"
                            });
                        } else if (columnDataType === "datetime") {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType,
                                format: "dd/MM/yyyy HH:mm:ss"
                            });
                        } else {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType
                            });
                        }
                    }
                }
                this.hasColumnFilterChanged = false;
            }
            this.filterBuilderPreviewValue = this.filterBuilderValue;
            return (
                <React.Fragment>
                    <div style={{padding: "20px"}}>
                        <FilterBuilder fields={this.filterFields} defaultValue={this.filterBuilderValue}
                                       onValueChanged={(e) => this.filterBuilderOnValueChanged(e)}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    render() {
        let gridViewState = this.props.state.gridViewState;
        let currentState = gridViewState.filter(gridView => {
            return gridView.gridViewId === this.props.gridViewId;
        });

        if (this.props.state.gridViewState.length === 0 || currentState === undefined ||
            this.props.gridViewBuilderVisible === false) {
            return ("");
        }
        currentState = currentState[0];
        return (
            <React.Fragment>
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div className={"leopard-editgridview-title"}>Configure Grid</div>
                    <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                        <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionGridViewTabPanel}
                                  onOptionChanged={this.onOptionChanged}
                                  loop={true} itemTitleRender={this.tabItemTitleRender}
                                  onItemRendered={this.onItemRendered}
                                  itemComponent={(e) => this.tabItemContent({e, currentState})}
                                  animationEnabled={true} swipeEnabled={false} showNavButtons={true}>
                        </TabPanel>
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Preview"}
                                ref={(e) => this.addUIObjectInstance({uiObjectName: "buttonPreview", ref: e})}
                                onClick={() => this.previewButtonOnClick(null, this.filterBuilderPreviewValue)}/>
                    </span>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                onClick={() => this.saveButtonOnClick()}>
                        </Button>
                    </span>
                    <span style={{padding: "0 0 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                onClick={(e) => this.cancelButtonOnClick(e)}/>
                    </span>
                    <span className="leopard-columndesigner-loading-progress"
                          style={{
                              marginRight: "15px",
                              marginLeft: "30px",
                              position: "relative",
                              top: "5px",
                              visibility: "hidden"
                          }}>
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                    </span>
                </div>
                <hr/>
                <div className={"leopard-editgridview-title"} style={{marginBottom: "10px", marginTop: "20px"}}>
                    Preview Layout
                </div>
                <LeopardTextEditorPopup popupInstance={(e) => this.addUIObjectInstance({
                    uiObjectName: "leopardTextEditorPopup", ref: e
                })} popupTitle={"Validation Rules"} exampleData={
                    "[\n" +
                    "    {\n" +
                    "        \"regex\": \"^[^0-9]+$\",\n" +
                    "        \"error\": \"Digits not allowed\"\n" +
                    "    },\n" +
                    "    {\n" +
                    "        \"regex\": \"^\\\\+\\\\s*1\\\\s*\\\\(\\\\s*[02-9]\\\\d{2}\\\\)\\\\s*\\\\d{3}\\\\s*-\\\\s*\\\\d{4}$\",\n" +
                    "        \"error\": \"Invalid USA phone format\"\n" +
                    "    }\n" +
                    "]"}></LeopardTextEditorPopup>
            </React.Fragment>
        );
    }
}

const RenderGridViewColumnDefinition = ({gridColumns, updateColumnDefinition, thisComp}) => {
    let columnsToBind = [];
    let hasExpandColumns = false;
    for (let i = 0; i < gridColumns.length; i++) {
        if (gridColumns[i].indexOf("#") > -1) {
            hasExpandColumns = true;
        }
        columnsToBind.push({columnName: gridColumns[i]});
    }

    let returnHtml = columnsToBind.map(column => {
        let columnDef = thisComp.columnDefinitionToSave.filter(c => {
            return c.columnName === column.columnName;
        });

        let isCustomColumn = false;
        if (!LDH.IsObjectNull(thisComp.props.gridViewDefinition.customComputedColumns)) {
            let customColumns = thisComp.props.gridViewDefinition.customComputedColumns;
            for (let v = 0; v < customColumns.length; v++) {
                let cName = customColumns[v].name.toLowerCase().trim();
                if (cName === column.columnName.toLowerCase().trim()) {
                    isCustomColumn = true;
                    break;
                }
            }
        }

        let allowAutoComplete = false;
        if (columnDef.length > 0 && columnDef[0].allowAutoComplete !== undefined && !isCustomColumn &&
            (LDH.IsObjectNull(columnDef[0].columnType) || columnDef[0].columnType === "string" ||
                columnDef[0].columnType === "guid")) {
            allowAutoComplete = columnDef[0].allowAutoComplete;
        }

        let allowResizing = true;
        if (columnDef.length > 0 && columnDef[0].allowResizing !== undefined) {
            allowResizing = columnDef[0].allowResizing;
        }

        let allowSorting = !isCustomColumn;
        if (columnDef.length > 0 && columnDef[0].allowSorting !== undefined &&
            !isCustomColumn) {
            allowSorting = columnDef[0].allowSorting;
        }

        let allowFiltering = !isCustomColumn;
        if (columnDef.length > 0 && columnDef[0].allowFiltering !== undefined &&
            !isCustomColumn) {
            allowFiltering = columnDef[0].allowFiltering;
        }

        let allowHeaderFilter = !isCustomColumn;
        if (columnDef.length > 0 && columnDef[0].allowHeaderFilter !== undefined &&
            !isCustomColumn) {
            allowHeaderFilter = columnDef[0].allowHeaderFilter;
        }

        let allowReordering = true;
        if (columnDef.length > 0 && columnDef[0].allowReordering !== undefined) {
            allowReordering = columnDef[0].allowReordering;
        }

        let dataKeyName = "";
        if (columnDef.length > 0 && columnDef[0].dataKeyName !== undefined) {
            dataKeyName = columnDef[0].dataKeyName;
        }

        let dataKeyFormat = LeopardDropdownHelper.DropdownSelectionValueFormat[0].id;
        if (columnDef.length > 0 && columnDef[0].dataKeyFormat !== undefined) {
            dataKeyFormat = columnDef[0].dataKeyFormat;
        }

        let dataValueFormat = LeopardDropdownHelper.DropdownSelectionValueFormat[0].id;
        if (columnDef.length > 0 && columnDef[0].dataValueFormat !== undefined) {
            dataValueFormat = columnDef[0].dataValueFormat;
        }

        let isFixed = false;
        if (columnDef.length > 0 && columnDef[0].isFixed !== undefined) {
            isFixed = columnDef[0].isFixed;
        }

        let isVisible = true;
        if (columnDef.length > 0 && columnDef[0].isVisible !== undefined) {
            isVisible = columnDef[0].isVisible;
        }

        let isEnabled = true;
        if (columnDef.length > 0 && columnDef[0].isEnabled !== undefined) {
            isEnabled = columnDef[0].isEnabled;
        }

        let visibleIndex = null;
        if (columnDef.length > 0 && columnDef[0].visibleIndex !== undefined) {
            visibleIndex = columnDef[0].visibleIndex;
        }

        let columnCustomHeader = "";
        if (columnDef.length > 0 && columnDef[0].columnCustomHeader !== undefined) {
            columnCustomHeader = columnDef[0].columnCustomHeader;
        }

        let columnDefaultValue = "";
        if (columnDef.length > 0 && columnDef[0].columnDefaultValue !== undefined) {
            columnDefaultValue = columnDef[0].columnDefaultValue;
        }

        let validationRules = "";
        if (columnDef.length > 0 && columnDef[0].validationRules !== undefined) {
            validationRules = columnDef[0].validationRules;
        }

        let minimumWidth = 50;
        if (columnDef.length > 0 && columnDef[0].minimumWidth !== undefined) {
            minimumWidth = columnDef[0].minimumWidth;
        }

        let width = null;
        if (columnDef.length > 0 && columnDef[0].width !== undefined) {
            width = columnDef[0].width;
        }

        let columnAlign = "left";
        if (columnDef.length > 0 && columnDef[0].columnAlign !== undefined) {
            columnAlign = columnDef[0].columnAlign;
        }

        let columnType = "string";
        if (columnDef.length > 0 && columnDef[0].columnType !== undefined) {
            columnType = columnDef[0].columnType;
        }

        let sortOrder = "none";
        if (columnDef.length > 0 && columnDef[0].sortOrder !== undefined) {
            sortOrder = columnDef[0].sortOrder;
        }

        let defaultOperation = "=";
        if (columnDef.length > 0 && columnDef[0].defaultOperation !== undefined) {
            defaultOperation = columnDef[0].defaultOperation;
        }

        let autoCompleteOperation = "startswith";
        if (columnDef.length > 0 && columnDef[0].autoCompleteOperation !== undefined) {
            autoCompleteOperation = columnDef[0].autoCompleteOperation;
        }

        let filterOperations = [];
        if (columnDef.length > 0 && columnDef[0].filterOperations !== undefined) {
            filterOperations = columnDef[0].filterOperations;
        }

        return (
            <tr key={column.columnName} className={"leopard-configure-grid-tablerow"}>
                <td className={"leopard-table-column-columnname leopard-table-fixed-column " +
                (hasExpandColumns ? "has-expanded-column" : "")} title={column.columnName}
                    style={{paddingLeft: "10px", borderRight: "1px solid #ddd"}}>
                    {column.columnName}
                </td>
                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionColumnTypeDataSource}
                        valueExpr={'value'} displayExpr={'text'}
                        defaultValue={columnType} width={100}
                        ref={(e) => thisComp.addUIObjectInstance({
                            uiObjectName: "columnType" + column.columnName, ref: e
                        })}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "columnType"
                        })}>
                    </SelectBox>
                </td>
                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionColumnAlignmentDataSource}
                        valueExpr={'value'} displayExpr={'text'}
                        defaultValue={columnAlign}
                        width={90}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "columnAlign"
                        })}>
                    </SelectBox>
                </td>
                <td>
                    <NumberBox
                        min={1} defaultValue={minimumWidth} showSpinButtons={true} width={90}
                        placeholder={"Min 1"} format={"#"}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "minimumWidth"
                        })}>
                    </NumberBox>
                </td>
                <td>
                    <NumberBox
                        min={1} defaultValue={width} showSpinButtons={true} width={90}
                        placeholder={"Min 1"} format={"#"}
                        onValueChanged={(e) => updateColumnDefinition({
                            e, column, updateType: "width"
                        })}>
                    </NumberBox>
                </td>
                <td>
                    <NumberBox
                        defaultValue={visibleIndex} showSpinButtons={true} width={115}
                        placeholder={"Starts from 0"} min={0}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "visibleIndex"
                        })}>
                    </NumberBox>
                </td>
                <td>
                    <TextBox
                        defaultValue={dataKeyName} maxLength={255} width={140}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "dataKeyName"
                        })}>
                    </TextBox>
                </td>
                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionValueFormat}
                        valueExpr={'id'} displayExpr={'name'}
                        defaultValue={dataKeyFormat}
                        width={140}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "dataKeyFormat"
                        })}>
                    </SelectBox>
                </td>
                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionValueFormat}
                        valueExpr={'id'} displayExpr={'name'}
                        defaultValue={dataValueFormat}
                        width={140}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "dataValueFormat"
                        })}>
                    </SelectBox>
                </td>
                <td>
                    <LeopardDesignerTagBox
                        dropdownList={LeopardDropdownHelper.DropdownSelectionAllFilterOperations}
                        fieldValue={filterOperations} fieldType={"filterOperations"}
                        addUIObjectInstance={(e) => thisComp.addUIObjectInstance(e)}
                        columnName={column.columnName} disabled={isCustomColumn}
                        leopardDesignerTagBoxOnOpened={
                            (e) => thisComp.leopardDesignerTagBoxOnOpened({
                                e, columnName: column.columnName
                            })
                        }
                        leopardDesignerTagBoxOnClosed={
                            (e) => thisComp.leopardDesignerTagBoxOnClosed({
                                e, columnName: column.columnName
                            })
                        }
                        leopardDesignerTagBoxValueOnChange={(e) => updateColumnDefinition({
                            e, column,
                            updateType: "filterOperations"
                        })}>
                    </LeopardDesignerTagBox>
                </td>

                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionAllFilterOperations}
                        valueExpr={'value'} displayExpr={'text'} defaultValue={defaultOperation}
                        width={130} disabled={isCustomColumn}
                        ref={(e) => thisComp.addUIObjectInstance({
                            uiObjectName: "defaultOperation" + column.columnName, ref: e
                        })}
                        onOpened={(e) => thisComp.leopardDesignerTagBoxOnOpened({
                            e, columnName: column.columnName
                        })}
                        onClosed={(e) => thisComp.leopardDesignerTagBoxOnClosed({
                            e, columnName: column.columnName
                        })}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "defaultOperation"
                        })}>
                    </SelectBox>
                </td>

                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionAutoCompleteOperations}
                        valueExpr={'value'} displayExpr={'text'} defaultValue={autoCompleteOperation}
                        width={130} disabled={isCustomColumn}
                        ref={(e) => thisComp.addUIObjectInstance({
                            uiObjectName: "autoCompleteOperation" + column.columnName, ref: e
                        })}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "autoCompleteOperation"
                        })}>
                    </SelectBox>
                </td>

                <td>
                    <TextBox
                        defaultValue={columnCustomHeader} maxLength={50} width={130}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "columnCustomHeader"
                        })}>
                    </TextBox>
                </td>

                <td>
                    <TextBox defaultValue={validationRules} width={150}
                             ref={(e) => thisComp.addUIObjectInstance({
                                 uiObjectName: "validationRules" + column.columnName, ref: e
                             })} onValueChanged={(e) => updateColumnDefinition({
                        e, column, updateType: "validationRules"
                    })}>
                        <TextBoxButton
                            name={"validationRulesText_" + column.columnName + "_MiniButton"}
                            location={'after'} options={{
                            icon: "far fa-window-restore", type: 'default',
                            onClick: (e) => {
                                thisComp.validationRulesPopupOnClick({
                                    e, thisComp,
                                    textboxRef: "validationRules" + column.columnName
                                })
                            }
                        }}>
                        </TextBoxButton>
                    </TextBox>
                </td>

                <td>
                    <TextBox
                        defaultValue={columnDefaultValue} maxLength={255} width={130}
                        disabled={isCustomColumn}
                        onValueChanged={(e) => updateColumnDefinition({
                            e,
                            column,
                            updateType: "columnDefaultValue"
                        })}>
                    </TextBox>
                </td>

                <td>
                    <SelectBox
                        dataSource={LeopardDropdownHelper.DropdownSelectionGridViewSortOrder}
                        valueExpr={'value'} displayExpr={'text'} defaultValue={sortOrder} width={100}
                        disabled={isCustomColumn} onValueChanged={(e) => updateColumnDefinition({
                        e, column, updateType: "sortOrder"
                    })}>
                    </SelectBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowAutoComplete} disabled={isCustomColumn}
                              ref={(e) => thisComp.addUIObjectInstance({
                                  uiObjectName: "allowAutoComplete" + column.columnName, ref: e
                              })} onValueChanged={(e) => updateColumnDefinition({
                        e,
                        column,
                        updateType: "allowAutoComplete"
                    })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowResizing}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "allowResizing"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowSorting} disabled={isCustomColumn}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "allowSorting"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowFiltering} disabled={isCustomColumn}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "allowFiltering"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowHeaderFilter} disabled={isCustomColumn}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "allowHeaderFilter"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowReordering}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "allowReordering"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={isFixed}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "isFixed"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={isVisible}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "isVisible"
                              })}>
                    </CheckBox>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={isEnabled}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e,
                                  column,
                                  updateType: "isEnabled"
                              })}>
                    </CheckBox>
                </td>
            </tr>);
    });

    if (hasExpandColumns) {
        setTimeout(function () {
            $(".leopard-config-table-header.column-name-header").addClass("has-expanded-column");
        }, 10);
    }

    return (<React.Fragment>{returnHtml}</React.Fragment>);
};

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardColumnDesigner);
