import React from 'react';
import PieChart, {
    Animation,
    Connector,
    Export,
    Label,
    Legend,
    Margin,
    Series,
    SmallValuesGrouping,
    Tooltip
} from "devextreme-react/pie-chart";
import {Point, ScrollBar, ZoomAndPan} from 'devextreme-react/chart';

export const InitializePieChart = (
    genericChartProps, chartDefinition, genericZoomAndPanProps, genericTooltipProps,
    genericScrollbarProps, genericSeriesBasicProps, genericSeriesLabelProps,
    genericSeriesPointProps, genericSmallValuesGroupingProps, genericLegendBasicProps,
    genericExportProps, thisComp) => {
    return (
        <PieChart {...genericChartProps} onDrawn={(e) => thisComp.onDrawn(e)}
                  onLegendClick={(e) => thisComp.onLegendClick(e)}
                  onPointClick={(e) => thisComp.onPointClick(e)}
                  onPointSelectionChanged={(e) => thisComp.onPointSelectionChanged(e)}
                  title={{
                      text: chartDefinition.chartTitle,
                      font: {size: chartDefinition.chartTitleFontSize}
                  }} type={'doughnut'}>
            <Margin top={chartDefinition.chartMarginTop} right={chartDefinition.chartMarginRight}
                    left={chartDefinition.chartMarginLeft} bottom={chartDefinition.chartMarginBottom}>
            </Margin>
            <Animation enabled={false} duration={1000}></Animation>
            <ZoomAndPan {...genericZoomAndPanProps} />
            <Tooltip {...genericTooltipProps} customizeTooltip={
                (e) => thisComp.genericChart_customizeTooltip({e, chartDefinition})
            }/>
            <ScrollBar {...genericScrollbarProps} />
            <Series {...genericSeriesBasicProps} valueField={chartDefinition.seriesValueField}
                    name={chartDefinition.seriesValueField}>
                <Label {...genericSeriesLabelProps} customizeText={
                    (e) => thisComp.genericChart_customizeLabel({
                        e, chartDefinition
                    })} wordWrap={"none"}
                       overlappingBehavior={chartDefinition.argAxisOverlappingBehavior}>
                    <Connector visible={true} width={1}/>
                </Label>
                <Point {...genericSeriesPointProps} />
                <SmallValuesGrouping {...genericSmallValuesGroupingProps} />
            </Series>
            <Legend {...genericLegendBasicProps} />
            <Export {...genericExportProps} />
        </PieChart>
    );
};
