import React, {Component} from 'react';
import {Switch} from 'devextreme-react';
import $ from 'jquery';
import LDH from '../helpers/LeopardDataHelper';

class LeopardDesignerCountdownSwitch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fieldValue: false,
            autoRefreshInterval: "",
            switchedOnText: ""
        };

        this.tooltip = "";
        this.isPaused = false;
        this.autoRefreshTimer = null;
    }

    componentDidMount = () => {
        let that = this;
        this.setState({
            fieldValue: this.props.fieldValue,
            autoRefreshInterval: this.props.autoRefreshInterval,
            switchedOnText: this.props.autoRefreshInterval
        });

        if (LDH.IsValueEmpty(this.props.tooltip) === false) {
            this.tooltip = this.props.tooltip;
        }

        if (this.props.fieldValue === true) {
            this.autoRefreshTimer = setInterval(function () {
                let id = that.props.gridViewId;
                if (that.isPaused === true) return;
                if ($("#gridViewToobar_" + id).is(":visible") ||
                    $(".leopard-gray-panel-container").is(":visible") ||
                    $(".leopard-gridview-dataloading").is(":visible") ||
                    $(".dx-overlay-wrapper.dx-datagrid-filter-range-overlay").is(":visible") ||
                    $(".leopard-screen-cover").is(":visible") ||
                    $(".leopard-popup-window.edit-form").is(":visible")) {
                    return;
                }

                let countdown = that.state.switchedOnText;
                that.setState({switchedOnText: countdown - 1});

                if (countdown - 1 < 0) {
                    let interval = that.props.autoRefreshInterval;
                    that.setState({switchedOnText: interval}, function () {
                        that.props.autoRefreshCountdownOnEnd();
                    });
                }
            }, 1000);
        }
    };

    componentWillUnmount() {
        if (!LDH.IsObjectNull(this.autoRefreshTimer)) {
            clearTimeout(this.autoRefreshTimer);
        }
    };

    onValueChanged = (e) => {
        this.isPaused = !e.value;
        if (e.previousValue === false && e.value === true) {
            this.setState({
                fieldValue: e.value,
                switchedOnText: this.props.autoRefreshInterval
            });
        } else {
            this.setState({fieldValue: e.value});
        }
    };

    render() {
        return (
            <React.Fragment>
                <Switch value={this.state.fieldValue} width={"60px"} switchedOffText={"OFF"}
                        switchedOnText={this.state.switchedOnText + "s"}
                        hint={this.tooltip}
                        onValueChanged={(e) => this.onValueChanged(e)}
                />
            </React.Fragment>
        );
    }
}

export default LeopardDesignerCountdownSwitch;