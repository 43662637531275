import React, {Component} from 'react';
import {Popover} from 'devextreme-react/popover';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardTooltipWithText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    onMouseEnter = () => {
        this.setState({visible: true});
    };

    onMouseLeave = () => {
        this.setState({visible: false});
    };

    contentTemplate = () => {
        return (<div style={{textAlign: "center"}}>
            {this.props.text}
        </div>)
    };

    render() {
        return (
            <React.Fragment>
                <span id={this.props.elementId} className={"leopard-link-with-tooltip"}
                      onMouseEnter={() => this.onMouseEnter()}
                      onMouseLeave={() => this.onMouseLeave()}>{this.props.labelText}</span>
                <div style={{position: "absolute"}}>
                    <Popover target={"#" + this.props.elementId} position={'top'}
                             width={this.props.width} visible={this.state.visible}
                             contentComponent={(e) => this.contentTemplate(e)}
                             animation={LeopardStaticUIConfig.TooltipAnimationConfig}>
                        {this.props.text}
                    </Popover>
                </div>
            </React.Fragment>);
    }
}

export default LeopardTooltipWithText;