import React, {Component} from 'react';
import {Popover} from 'devextreme-react/popover';
import LeopardDataHelper from "../helpers/LeopardDataHelper";
import LDH from "../helpers/LeopardDataHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardTooltipWithIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    onMouseEnter = () => {
        this.setState({visible: true});
    };

    onMouseLeave = () => {
        this.setState({visible: false});
    };

    render() {
        let width = 300;
        if (!LeopardDataHelper.IsObjectNull(this.props.customWidth)) {
            width = this.props.customWidth;
        }

        return (
            <React.Fragment>
                <i id={this.props.elementId}
                   className={LDH.IsObjectNull(this.props.customIcon) ?
                       "fas fa-info-circle leopard-field-attention-info" :
                       this.props.customIcon}
                   onMouseEnter={() => this.onMouseEnter()}
                   onMouseLeave={() => this.onMouseLeave()}></i>
                <div style={{position: "absolute"}}>
                    <Popover target={"#" + this.props.elementId}
                             showTitle={true} title={this.props.title}
                             position={'top'} width={width} visible={this.state.visible}
                             animation={LeopardStaticUIConfig.TooltipAnimationConfig}>
                        {this.props.text}
                    </Popover>
                </div>
            </React.Fragment>);
    }
}

export default LeopardTooltipWithIcon;
