import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, ColorBox, NumberBox, SelectBox, TextArea} from 'devextreme-react';
import List from 'devextreme-react/list';
import {Button as TextBoxButton, TextBox} from 'devextreme-react/text-box';
import Box, {Item} from 'devextreme-react/box';
import TabPanel from 'devextreme-react/tab-panel';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {RequiredRule, Validator} from 'devextreme-react/validator';
import $ from "jquery";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardChartDesigner extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            viewDataButtonText: "View Data",
            selectedIndex: 0,
            chartDefinition: {}
        };

        this.uiObjectInstance = [];
        this.numberOfSeriesForChartText = "";
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    componentDidMount = () => {
        let definition = this.props.definition.chartDefinition;
        this.setState({chartDefinition: definition});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    disablePreviewButton = () => {
        this.uiObjectInstance["buttonPreview"].option("disabled", true);
    };

    updateChartDefinition = (data) => {
        let inputValue = data.e.value;
        let chartDef = this.state.chartDefinition;
        let clonedChartDef = LDH.DeepClone(chartDef);
        clonedChartDef[data.prop] = inputValue;

        if (data.tabInfo.settingsName === "enableAutoRefresh") {
            if (data.e.value === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }
        }

        if (data.supportPreview === false) {
            this.disablePreviewButton();
        }

        this.setState({chartDefinition: clonedChartDef}, function () {
            chartDef = this.state.chartDefinition;
        });
    };

    getDefaultValue = (name, defaultValue) => {
        if (this.state.chartDefinition[name] === undefined) {
            return defaultValue;
        }
        return this.state.chartDefinition[name];
    };

    showOrHideSettings = (settingsName) => {
        let show = false;
        let chartType = this.props.chartType;

        if (settingsName === "chartTitle") show = true;
        if (settingsName === "chartEnableExport") show = true;
        if (settingsName === "seriesValueField") show = true;
        if (settingsName === "seriesArgumentField") show = true;
        if (settingsName === "chartResolveLabelOverlapping") show = true;
        if (settingsName === "chartAllowTouchGuestures") show = true;
        if (settingsName === "valueAxisValueType") show = true;
        if (settingsName === "valueAxisInvertAxis") show = true;
        if (settingsName === "valueAxisPosition") show = true;
        if (settingsName === "argAxisValueType") show = true;
        if (settingsName === "argAxisInvertAxis") show = true;
        if (settingsName === "argAxisPosition") show = true;
        if (settingsName === "tooltipEnabled") show = true;
        if (settingsName === "seriesIgnoreEmptyPoint") show = true;
        if (settingsName === "legendVisibility") show = true;
        if (settingsName === "legendOrientation") show = true;
        if (settingsName === "legendItemTextPosition") show = true;
        if (settingsName === "legendPosition") show = true;
        if (settingsName === "legendItemsAlignment") show = true;
        if (settingsName === "legendHorizontalAlignment") show = true;
        if (settingsName === "legendVerticalAlignment") show = true;
        if (settingsName === "tooltipArrowLength") show = true;
        if (settingsName === "tooltipOpacity") show = true;
        if (settingsName === "tooltipPaddingLeftRight") show = true;
        if (settingsName === "tooltipPaddingTopBottom") show = true;
        if (settingsName === "chartMarginForExport") show = true;
        if (settingsName === "legendColumnCount") show = true;
        if (settingsName === "legendRowCount") show = true;
        if (settingsName === "legendColumnItemSpacing") show = true;
        if (settingsName === "legendRowItemSpacing") show = true;
        if (settingsName === "legendPaddingLeftRight") show = true;
        if (settingsName === "legendPaddingTopBottom") show = true;
        if (settingsName === "legendMargin") show = true;
        if (settingsName === "tooltipColor") show = true;
        if (settingsName === "chartBgColorForExport") show = true;
        if (settingsName === "legendBgColor") show = true;
        if (settingsName === "labelVisibility") show = true;
        if (settingsName === "labelRotationAngle") show = true;
        if (settingsName === "labelPosition") show = true;
        if (settingsName === "labelBgColor") show = true;
        if (settingsName === "chartPalette") show = true;
        if (settingsName === "dateRangeValue") show = true;
        if (settingsName === "dateRangeField") show = true;
        if (settingsName === "customQueryAttributes") show = true;
        if (settingsName === "oDataQueryForLinkedView") show = true;
        if (settingsName === "valueAxisShowZero") show = true;
        if (settingsName === "argAxisShowZero") show = true;
        if (settingsName === "argAxisOverlappingBehavior") show = true;
        if (settingsName === "chartShowScrollbar") show = true;
        if (settingsName === "chartScrollbarPosition") show = true;
        if (settingsName === "chartTitleFontSize") show = true;
        if (settingsName === "enableAutoRefresh") show = true;
        if (settingsName === "autoRefreshInterval") show = true;
        if (settingsName === "showAutoRefreshSwitch") show = true;
        if (settingsName === "clientSideQueryEditor") show = true;
        if (settingsName === "seriesSelectionMode") show = true;
        if (settingsName === "seriesHoverMode") show = true;
        if (settingsName === "legendHoverMode") show = true;
        if (settingsName === "chartMarginTop") show = true;
        if (settingsName === "chartMarginRight") show = true;
        if (settingsName === "chartMarginBottom") show = true;
        if (settingsName === "chartMarginLeft") show = true;
        if (settingsName === "labelLabelFormat") show = true;
        if (settingsName === "tooltipCustomTextPart1") show = true;
        if (settingsName === "tooltipCustomTextPart2") show = true;
        if (settingsName === "labelCustomTextPart1") show = true;
        if (settingsName === "labelCustomTextPart2") show = true;
        if (settingsName === "maximumDataThreshold") show = true;
        if (settingsName === "defaultValuesForQuery") show = true;

        if (chartType === "bar-chart" || chartType === "line-chart" || chartType === "spline-chart" ||
            chartType === "area-chart" || chartType === "scatter-chart") {
            if (settingsName === "seriesTypeOfSeries") show = true;
            if (settingsName === "seriesStyleDashStyle") show = true;
            if (settingsName === "chartBarGroupWitdh") show = true;
            if (settingsName === "chartBarGroupPadding") show = true;
            if (settingsName === "pointSizeOfPoint") show = true;
            if (settingsName === "pointCustomSymbol") show = true;
            if (settingsName === "pointVisibility") show = true;
            if (settingsName === "seriesStyleBarWidth") show = true;
            if (settingsName === "seriesStyleBarPadding") show = true;
            if (settingsName === "seriesStyleCornerRadius") show = true;
            if (settingsName === "pointColor") show = true;
            if (settingsName === "argAxisCustomizeText") show = true;
            if (settingsName === "argAxisTitle") show = true;
            if (settingsName === "valueAxisCustomizeText") show = true;
            if (settingsName === "chartNegativeValuesAsZeroes") show = true;
            if (settingsName === "labelShowForZero") show = true;
            if (settingsName === "labelAlignment") show = true;
            if (settingsName === "valueAxisHighAverage") show = true;
            if (settingsName === "valueAxisLowAverage") show = true;
            if (settingsName === "valueAxisHighAverageText") show = true;
            if (settingsName === "valueAxisLowAverageText") show = true;
            if (settingsName === "valueAxisTitle") show = true;
            if (settingsName === "chartShowCrosshair") show = true;
            if (settingsName === "chartCrosshairWidth") show = true;
            if (settingsName === "chartCrosshairColor") show = true;
            if (settingsName === "chartCrosshairStyle") show = true;
            if (settingsName === "defaultVisualRange") show = true;
            if (settingsName === "pointHoverMode") show = true;
            if (settingsName === "chartPointSelectionMode") show = true;
            if (settingsName === "numberOfSeriesOnChart") show = true;
            if (settingsName === "showScrollbar") show = true;
            if (settingsName === "valueAxisAllowDecimals") show = true;
            if (settingsName === "argAxisAllowDecimals") show = true;
        }

        if (chartType === "doughnut-chart") {
            if (settingsName === "seriesSmallValueGroupingMode") show = true;
            if (settingsName === "seriesSmallValueGroupingTopCount") show = true;
        }
        return show;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================

    previewButtonOnClick = (callback) => {
        let definition = this.state.chartDefinition;
        this.props.chartDefinitionUpdateForPreview({
            definition
        });
        if (callback !== undefined && callback !== null) {
            callback();
        }
    };

    saveDataButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let definition = that.state.chartDefinition;
        let dataViewId = that.props.dataViewId;
        $(".loading-progress-configurechart").css("visibility", "visible");

        setTimeout(function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.UpdateDataViewDefinitionByType(userId, organizationId,
                dataViewId, definition, function () {
                    LRH.ShowToast("Your chart configuration has been successfully saved.", "success", 5000);
                    $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".loading-progress-configurechart").css("visibility", "hidden");
                }, "chart");
        }, 100);
    };

    onSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    };

    cancelButtonOnClick = (e) => {
        this.props.chartDesignerButtonOnClick(e);
    };

    numberOfSeriesForChartTextValueOnChanged = (e) => {
        this.numberOfSeriesForChartText = e.value;
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeTabItemTitle = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    initializeSettingsTextBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                         ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                         onValueChanged={(e) => this.updateChartDefinition({
                                             e, tabInfo: data, prop: data.settingsName,
                                             supportPreview
                                         })} showClearButton={true} placeholder={data.placeholder}>
                                    <Validator>
                                        <RequiredRule validationCallback={(e) => this.customValidationRuleCallback({
                                            e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                        })} type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextAreaWithButtonField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextArea defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                          id={"Textbox_" + data.settingsName} height={"140px"}
                                          onValueChanged={(e) => this.updateChartDefinition({
                                              e, tabInfo: data, prop: data.settingsName,
                                              supportPreview
                                          })} placeholder={data.placeholder}>
                                    {
                                        data.validator === false ? "" :
                                            <Validator>
                                                <RequiredRule
                                                    validationCallback={(e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: data.settingsName,
                                                        rules: [{rule: "safeinput"}]
                                                    })} type="custom"/>
                                            </Validator>
                                    }
                                    {
                                        data.hasMiniButton === false ? "" :
                                            <TextBoxButton location={'after'} options={{
                                                icon: data.buttonIcon, type: 'default',
                                                onClick: () => {
                                                    data.onClick();
                                                }
                                            }} name={data.settingsName + "_MiniButton"}>
                                            </TextBoxButton>
                                    }
                                </TextArea>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsSelectBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <SelectBox dataSource={data.dataSource} displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateChartDefinition({
                                               e, tabInfo: data, prop: data.settingsName,
                                               supportPreview
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    numberOfSeriesOnChartTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    initializeSettingsListField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div style={{marginBottom: "5px"}}>
                                <TextBox maxLength={50} showClearButton={true} placeholder={data.placeholder}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: data.settingsName + "_AddInput"
                                         })}
                                         onValueChanged={(e) => this.numberOfSeriesForChartTextValueOnChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={(e) => this.customValidationRuleCallback({
                                                          e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                                      })}>
                                        </RequiredRule>
                                    </Validator>
                                    <TextBoxButton name={data.settingsName + "_MiniButton"} location={'after'}
                                                   options={{
                                                       icon: "fas fa-plus", type: 'default', onClick: () => {
                                                           if (LDH.IsValueEmpty(this.numberOfSeriesForChartText)) {
                                                               return;
                                                           }
                                                           let dataSource = this.getDefaultValue(data.dataSource, []);
                                                           dataSource.push({
                                                               id: "series_" + LDH.GenerateGuid(),
                                                               name: this.numberOfSeriesForChartText
                                                           });
                                                           this.uiObjectInstance[data.settingsName + "_AddInput"].option("value", "");
                                                           this.uiObjectInstance[data.settingsName + "_AddInput"].focus();
                                                           this.uiObjectInstance[data.settingsName].option("dataSource", dataSource);
                                                       }
                                                   }}>
                                    </TextBoxButton>
                                </TextBox>
                            </div>
                            <div>
                                <List allowItemDeleting={true} activeStateEnabled={false}
                                      noDataText={"There is no series on the chart"}
                                      ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                      hoverStateEnabled={false} focusStateEnabled={false}
                                      itemRender={this.numberOfSeriesOnChartTemplate}>
                                </List>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsNumberBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <NumberBox min={data.minValue} max={data.maxValue} showSpinButtons={true}
                                           showClearButton={true}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateChartDefinition({
                                               e, tabInfo: data, prop: data.settingsName,
                                               supportPreview
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    renderSettingsColorBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <ColorBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          onValueChanged={(e) => this.updateChartDefinition({
                                              e, tabInfo: data, prop: data.settingsName,
                                              supportPreview
                                          })}>
                                </ColorBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        if (data.tabId === 0) {
            let showXAxisValue = this.props.chartType !== "doughnut-chart";
            let showYAxisValue = this.props.chartType !== "doughnut-chart";

            let definition = this.state.chartDefinition;
            setTimeout(function () {
                if (!LDH.IsObjectNull(definition.enableAutoRefresh) && definition.enableAutoRefresh === true) {
                    $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
                } else {
                    $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
                }
            }, 100);

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "chartTitle",
                                            defaultValue: undefined,
                                            displayName: "Chart title:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartTitleFontSize",
                                            defaultValue: 20,
                                            displayName: "Font size for chart title:",
                                            minValue: 8,
                                            maxValue: 40
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartPalette",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionChartPalette[5].id,
                                            displayName: "Palette:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionChartPalette
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartEnableExport",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Enable export feature:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartRedrawOnResize",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Redraw on resize:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartResolveLabelOverlapping",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionResolveLabelOverlapping[1].id,
                                            displayName: "Resolve label Overlapping:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionResolveLabelOverlapping
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartNegativeValuesAsZeroes",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Show negatives as zeroes:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartAllowMouseWheel",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Allow mouse wheel:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartAllowTouchGuestures",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Allow touch gestures:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartDragToZoom",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Drag to zoom:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "defaultVisualRange",
                                            defaultValue: 20,
                                            displayName: "Number of visual points:",
                                            minValue: 0,
                                            maxValue: 99999,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "showScrollbar",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionScrollbarVisibility[2].id,
                                            displayName: "Scrollbar visibility:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionScrollbarVisibility,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "maximumDataThreshold",
                                            defaultValue: 0,
                                            displayName: "Maximum data threshold:",
                                            minValue: 0,
                                            maxValue: 9999999,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "defaultValuesForQuery",
                                            defaultValue: undefined,
                                            displayName: "Default values for query:",
                                            placeholder: "{EventChainId}|0,{Barcode}|'blank',{Id}|null"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                {this.props.chartType === "doughnut-chart" ? null :
                                    <div className="leopard-settings-panel-cell crosshair">
                                        <div className="leopard-settings-panel-title">Crosshair</div>
                                        {
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "chartShowCrosshair",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                                displayName: "Show crosshair:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                            })
                                        }
                                        {
                                            this.initializeSettingsNumberBoxField({
                                                settingsName: "chartCrosshairWidth",
                                                defaultValue: 2,
                                                displayName: "Crosshair width:",
                                                minValue: 1,
                                                maxValue: 10
                                            })
                                        }
                                        {
                                            this.renderSettingsColorBoxField({
                                                settingsName: "chartCrosshairColor",
                                                defaultValue: "#FE8000",
                                                displayName: "Crosshair color:"
                                            })
                                        }
                                        {
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "chartCrosshairStyle",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionChartLineStyle[0].id,
                                                displayName: "Crosshair style:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionChartLineStyle
                                            })
                                        }
                                    </div>
                                }
                                <div className="leopard-settings-panel-cell" style={{marginTop: "1px"}}>
                                    <div className="leopard-settings-panel-title">
                                        <span>Real-time update</span>
                                    </div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "enableAutoRefresh",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Enable auto-refresh:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "autoRefreshInterval",
                                            defaultValue: 30,
                                            displayName: "Auto-refresh interval (seconds):",
                                            minValue: 5,
                                            maxValue: 9999,
                                            additionalClass: "auto-refresh-feature",
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "showAutoRefreshSwitch",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Show auto-refresh switch:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo,
                                            additionalClass: "auto-refresh-feature",
                                            supportPreview: false
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showXAxisValue}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">X-Axis Value</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisValueType",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionValueAxisValueType[2].id,
                                            displayName: "Value type:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionValueAxisValueType
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "argAxisCustomizeText",
                                            defaultValue: undefined,
                                            displayName: "Customize text:",
                                            placeholder: "#value# must be included"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisAllowDecimals",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Allow decimals:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisEnableAutoBreak",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Enable auto-break:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "argAxisMaxAutoBreakCount",
                                            defaultValue: 4,
                                            displayName: "Maximum auto-break count:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "argAxisMaxValueMargin",
                                            defaultValue: undefined,
                                            displayName: "Maximum value margin:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisEndOnTick",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "End on tick:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisInvertAxis",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Invert axis:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisShowZero",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Show zero:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisType",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionValueAxisType[0].id,
                                            displayName: "Axis type:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionValueAxisType
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisPosition",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionItemTextPosition[0].id,
                                            displayName: "Position:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionItemTextPosition
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "argAxisOverlappingBehavior",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionOverlappingBehavior[0].id,
                                            displayName: "Overlapping mode:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionOverlappingBehavior
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "argAxisTitle",
                                            defaultValue: undefined,
                                            displayName: "Title:"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showYAxisValue}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Y-Axis Value</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisValueType",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionValueAxisValueType[2].id,
                                            displayName: "Value type:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionValueAxisValueType
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "valueAxisCustomizeText",
                                            defaultValue: undefined,
                                            displayName: "Customize text:",
                                            placeholder: "#value# must be included"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisAllowDecimals",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Allow decimals:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisEnableAutoBreak",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Enable auto-break:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "valueAxisMaxAutoBreakCount",
                                            defaultValue: 4,
                                            displayName: "Maximum auto-break count:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "valueAxisMaxValueMargin",
                                            defaultValue: undefined,
                                            displayName: "Maximum value margin:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisEndOnTick",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "End on tick:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisInvertAxis",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Invert axis:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisShowZero",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Show zero:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisType",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionValueAxisType[0].id,
                                            displayName: "Axis type:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionValueAxisType
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "valueAxisPosition",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionItemTextPosition[1].id,
                                            displayName: "Position:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionItemTextPosition
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "valueAxisHighAverage",
                                            defaultValue: undefined,
                                            displayName: "High average:",
                                            minValue: -999999,
                                            maxValue: 999999,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "valueAxisHighAverageText",
                                            defaultValue: "High Average",
                                            displayName: "High average custom text:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "valueAxisLowAverage",
                                            defaultValue: undefined,
                                            displayName: "Low average:",
                                            minValue: -999999,
                                            maxValue: 999999,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "valueAxisLowAverageText",
                                            defaultValue: "Low Average",
                                            displayName: "Low average custom text:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "valueAxisTitle",
                                            defaultValue: undefined,
                                            displayName: "Title:"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Tooltip</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "tooltipEnabled",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Enable tooltips:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "tooltipArgumentFormat",
                                            defaultValue: undefined,
                                            displayName: "Argument format:",
                                            placeholder: "e.g. decimal, currency, string, longDate..."
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "tooltipArrowLength",
                                            defaultValue: 10,
                                            displayName: "Arrow length:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "tooltipColor",
                                            defaultValue: "#FFFFFF",
                                            displayName: "Tooltip color:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "tooltipLocation",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionTooltipLocation[0].id,
                                            displayName: "Location:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionTooltipLocation
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "tooltipOpacity",
                                            defaultValue: undefined,
                                            displayName: "Opacity:",
                                            minValue: 0,
                                            maxValue: 1
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "tooltipPaddingLeftRight",
                                            defaultValue: 10,
                                            displayName: "Padding left right:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "tooltipPaddingTopBottom",
                                            defaultValue: 10,
                                            displayName: "Padding top bottom:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "tooltipCustomTextPart1",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionTooltipCustomText[0].id,
                                            displayName: "Custom text part 1:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionTooltipCustomText
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "tooltipCustomTextPart2",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionTooltipCustomText[0].id,
                                            displayName: "Custom text part 2:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionTooltipCustomText
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Style</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartPaletteExtMode",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionPaletteExtensionMode[1].id,
                                            displayName: "Palette extension mode:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionPaletteExtensionMode
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartBarGroupWitdh",
                                            defaultValue: null,
                                            displayName: "Bar group width:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartBarGroupPadding",
                                            defaultValue: 0.3,
                                            displayName: "Bar group padding:",
                                            minValue: 0,
                                            maxValue: 1
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "chartContainerBgColor",
                                            defaultValue: "#FFFFFF",
                                            displayName: "Container background color:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMaxBubbleSize",
                                            defaultValue: 0.2,
                                            displayName: "Maximum bubble size:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMinBubbleSize",
                                            defaultValue: 12,
                                            displayName: "Minimum bubble size:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "chartBgColorForExport",
                                            defaultValue: "#FFFFFF",
                                            displayName: "Background color for export:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMarginForExport",
                                            defaultValue: 10,
                                            displayName: "Margin for export:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartShowScrollbar",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Show scrollbar:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartScrollbarPosition",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionItemTextPosition[0].id,
                                            displayName: "Scrollbar position:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionItemTextPosition
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartScrollbarOffset",
                                            defaultValue: 5,
                                            displayName: "Scrollbar offset:",
                                            minValue: -999,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMarginTop",
                                            defaultValue: 0,
                                            displayName: "Margin top:",
                                            minValue: -9999,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMarginRight",
                                            defaultValue: 0,
                                            displayName: "Margin right:",
                                            minValue: -9999,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMarginBottom",
                                            defaultValue: 0,
                                            displayName: "Margin bottom:",
                                            minValue: -9999,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "chartMarginLeft",
                                            defaultValue: 0,
                                            displayName: "Margin left:",
                                            minValue: -9999,
                                            maxValue: 9999
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting
                            does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 1) {
            let that = this;
            let showSeriesPoint = this.props.chartType !== "doughnut-chart";
            let showSeriesStyle = this.props.chartType !== "doughnut-chart";

            setTimeout(function () {
                let ds = that.getDefaultValue("numberOfSeriesOnChart", []);
                if (!LDH.IsObjectNull(that.uiObjectInstance["numberOfSeriesOnChart"]) &&
                    !LDH.IsObjectNull(that.uiObjectInstance["numberOfSeriesOnChart"].option)) {
                    that.uiObjectInstance["numberOfSeriesOnChart"].option("dataSource", ds);
                }
            }, 100);

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "seriesArgumentField",
                                            defaultValue: undefined,
                                            displayName: "Specify a column for x-axis value:",
                                            placeholder: "Column1 | Column2 | Column3"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "seriesValueField",
                                            defaultValue: undefined,
                                            displayName: "Specify a column for y-axis value:",
                                            placeholder: "Column1 | Column2 | Column3"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "seriesTypeOfSeries",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionTypeOfSeries[1].id,
                                            displayName: "Type of series:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionTypeOfSeries,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.props.chartType === "doughnut-chart" ?
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "seriesHoverMode",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModePieChart[1].id,
                                                displayName: "Hover mode:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModePieChart
                                            }) :
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "seriesHoverMode",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModeBarChart[1].id,
                                                displayName: "Hover mode:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModeBarChart
                                            })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "seriesIgnoreEmptyPoint",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Ignore empty points:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsListField({
                                            settingsName: "numberOfSeriesOnChart",
                                            displayName: "Chart series:",
                                            additionalClass: "numberOfSeriesOnChart",
                                            dataSource: "numberOfSeriesOnChart"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "seriesAxisBinding",
                                            defaultValue: undefined,
                                            displayName: "Axis binding:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "seriesSmallValueGroupingMode",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionSmallValuesGroupingMode[2].id,
                                            displayName: "Grouping mode:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionSmallValuesGroupingMode
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "seriesSmallValueGroupingTopCount",
                                            defaultValue: 8,
                                            displayName: "Grouping top count:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.props.chartType === "doughnut-chart" ?
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "seriesSelectionMode",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModePieChart[1].id,
                                                displayName: "Selection mode:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModePieChart
                                            }) :
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "seriesSelectionMode",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModeBarChart[1].id,
                                                displayName: "Selection mode:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionSeriesSelectionModeBarChart
                                            })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Label</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "labelVisibility",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionShowHide[1].id,
                                            displayName: "Label visibility:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionShowHide
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "labelAlignment",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionHorizontalAlignOption[0].id,
                                            displayName: "Alignment:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHorizontalAlignOption
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "labelArgumentFormat",
                                            defaultValue: undefined,
                                            displayName: "Argument format:",
                                            placeholder: "e.g. decimal, currency, string, longDate..."
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "labelBgColor",
                                            defaultValue: undefined,
                                            displayName: "Background color:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "labelLabelFormat",
                                            defaultValue: undefined,
                                            displayName: "Label format:",
                                            placeholder: "e.g. decimal, currency, string, longDate..."
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "labelHorizontalOffset",
                                            defaultValue: 0,
                                            displayName: "Horizontal offset:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "labelVerticalOffset",
                                            defaultValue: 0,
                                            displayName: "Vertical offset:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "labelPosition",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionLegendPosition[1].id,
                                            displayName: "Label position:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionLegendPosition
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "labelRotationAngle",
                                            defaultValue: 0,
                                            displayName: "Rotation angle:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "labelShowForZero",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Show for zero values:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "labelCustomTextPart1",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionTooltipCustomText[0].id,
                                            displayName: "Custom text part 1:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionTooltipCustomText
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "labelCustomTextPart2",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionTooltipCustomText[0].id,
                                            displayName: "Custom text part 2:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionTooltipCustomText
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showSeriesPoint}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Point</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "pointVisibility",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionShowHide[1].id,
                                            displayName: "Point visibility:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionShowHide
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "pointColor",
                                            defaultValue: undefined,
                                            displayName: "Color:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "pointHoverMode",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionSeriesHoverMode[1].id,
                                            displayName: "Hover mode:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionSeriesHoverMode
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "pointSizeOfPoint",
                                            defaultValue: 12,
                                            displayName: "Size of point:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "pointCustomSymbol",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionChartCustomSymbol[0].id,
                                            displayName: "Custom symbol:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionChartCustomSymbol
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "chartPointSelectionMode",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionPointSelectionMode[1].id,
                                            displayName: "Point selection mode:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionPointSelectionMode
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showSeriesStyle}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Style</div>
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "seriesStyleBarWidth",
                                            defaultValue: undefined,
                                            displayName: "Bar width:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "seriesStyleBarPadding",
                                            defaultValue: undefined,
                                            displayName: "Bar padding:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "seriesStyleCornerRadius",
                                            defaultValue: 0,
                                            displayName: "Corner radius:",
                                            minValue: 0,
                                            maxValue: 100
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "seriesStyleDashStyle",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionDashStyleOption[0].id,
                                            displayName: "Dash style:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionDashStyleOption
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "seriesStyleInnerColor",
                                            defaultValue: undefined,
                                            displayName: "Inner color:"
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting
                            does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 2) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendVisibility",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionShowHide[1].id,
                                            displayName: "Visibility:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionShowHide
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendColumnCount",
                                            defaultValue: undefined,
                                            displayName: "Column count:",
                                            minValue: 0,
                                            maxValue: 20
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendRowCount",
                                            defaultValue: undefined,
                                            displayName: "Row count:",
                                            minValue: 0,
                                            maxValue: 20
                                        })
                                    }
                                    {
                                        this.props.chartType === "doughnut-chart" ?
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "legendHoverMode",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionLegendHoverModeForPieChart[0].id,
                                                displayName: "Hover mode:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionLegendHoverModeForPieChart
                                            }) :
                                            this.initializeSettingsSelectBoxField({
                                                settingsName: "legendHoverMode",
                                                defaultValue: LeopardDropdownHelper.DropdownSelectionLegendHoverModeForBarChart[0].id,
                                                displayName: "Hover mode:",
                                                dataSource: LeopardDropdownHelper.DropdownSelectionLegendHoverModeForBarChart
                                            })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendMarkerSize",
                                            defaultValue: 20,
                                            displayName: "Marker size:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Layout & Position</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendOrientation",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionChartOrientation[0].id,
                                            displayName: "Orientation:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionChartOrientation
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendItemTextPosition",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionItemTextPosition[3].id,
                                            displayName: "Item text position:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionItemTextPosition
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendPosition",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionLegendPosition[1].id,
                                            displayName: "Position:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionLegendPosition
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendItemsAlignment",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionHorizontalAlignOption[0].id,
                                            displayName: "Items alignment:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHorizontalAlignOption
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendHorizontalAlignment",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionHorizontalAlignOption[0].id,
                                            displayName: "Horizontal alignment:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHorizontalAlignOption
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "legendVerticalAlignment",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionVerticalAlignOption[1].id,
                                            displayName: "Vertical alignment:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionVerticalAlignOption
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Style</div>
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "legendBgColor",
                                            defaultValue: undefined,
                                            displayName: "Background color:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendColumnItemSpacing",
                                            defaultValue: 20,
                                            displayName: "Column item spacing:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendRowItemSpacing",
                                            defaultValue: 8,
                                            displayName: "Row item spacing:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendPaddingLeftRight",
                                            defaultValue: 10,
                                            displayName: "Padding left right:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendPaddingTopBottom",
                                            defaultValue: 10,
                                            displayName: "Padding top bottom:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "legendMargin",
                                            defaultValue: 10,
                                            displayName: "Margin:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 3) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300} visible={false}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Date Range</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dateRangeValue",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionDateRange[0].id,
                                            displayName: "Select a date range:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionDateRange
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dateRangeField",
                                            defaultValue: undefined,
                                            displayName: "Specify a column for date range lookup:",
                                            placeholder: "Field name"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={600}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Server-Side Query Editor</span>
                                    </div>
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "customQueryAttributes",
                                            defaultValue: undefined,
                                            displayName: "OData query string:",
                                            supportPreview: false,
                                            hasMiniButton: true,
                                            validator: false,
                                            buttonIcon: "fas fa-question",
                                            onClick() {
                                                window.open(LeopardStaticUIConfig.ODataDocUrl);
                                            }
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Workspace Interactive Features</span>
                                    </div>
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "oDataQueryForLinkedView",
                                            defaultValue: undefined,
                                            displayName: "OData paramters:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            placeholder: "Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Client-Side Query Editor</span>
                                    </div>
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "clientSideQueryEditor",
                                            defaultValue: undefined,
                                            supportPreview: false,
                                            validator: false,
                                            placeholder: "Use the variable \"data\" to access the data.",
                                            displayName: "Javascript code:",
                                            hasMiniButton: false
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting
                            does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.saveDataButtonOnClick}>
                    <div style={{width: "100%", overflowX: "auto"}}>
                        <div className={"leopard-editgridview-title"}>Configure Chart</div>
                        <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                            <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionChartTabPanel}
                                      onOptionChanged={this.onSelectionChanged}
                                      loop={true} itemTitleRender={this.initializeTabItemTitle}
                                      itemComponent={this.tabItemContent} animationEnabled={true} swipeEnabled={false}
                            ></TabPanel>
                        </div>
                    </div>
                    <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                        <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Preview"}
                                    ref={(e) => this.setInputFieldInstance({e, input: "buttonPreview"})}
                                    onClick={() => this.previewButtonOnClick(function () {
                                    })}>
                            </Button>
                        </span>
                        <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                    useSubmitBehavior={true}>
                            </Button>
                        </span>
                        <span style={{padding: "0 0 0 5px"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                    onClick={(e) => this.cancelButtonOnClick({e})}>
                            </Button>
                        </span>
                        <span className={"loading-progress-configurechart"}>
                            <i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                        </span>
                    </div>
                </form>
                <br/>
            </React.Fragment>
        );
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardChartDesigner);
