import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {NumberBox, SelectBox, TextBox, TextArea} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";
import LeopardDesignerTagBox from "./LeopardDesignerTagBox";
import LDPH from "../helpers/LeopardDropdownHelper";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import {alert} from 'devextreme/ui/dialog';

class LeopardCommandLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customColumnOverallWidth: 200,
            customColumns: null
        };
    }

    componentDidMount() {
        if (LDH.IsObjectNull(this.props.customColumnConfiguration)) {
            this.setState({customColumns: []});
        } else {
            let customColumns = this.props.customColumnConfiguration.customColumns;
            let width = this.props.customColumnConfiguration.customColumnOverallWidth;
            this.setState({customColumns, customColumnOverallWidth: width});
        }
    }

    customColumnOverallWidthOnChange = (e) => {
        let that = this;
        this.setState({customColumnOverallWidth: e.value}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
        this.props.disablePreviewButton();
    };

    updateValueForCustomColumn = (data) => {
        let that = this;
        let clonedColumns = LDH.DeepClone(this.state.customColumns);
        for (let i = 0; i < clonedColumns.length; i++) {
            if (clonedColumns[i].id === data.id) {
                let pName = data.propertyName;
                if (pName === "disabledFields" || pName === "hiddenFields") {
                    clonedColumns[i][pName] = data.e.fieldValue;
                } else {
                    clonedColumns[i][pName] = data.e.value;
                }

                if (pName === "columnType") {
                    if (data.e.value === "modify-row") {
                        clonedColumns[i].showVisibleCountField = false;
                        clonedColumns[i].showAPIGatewayPathField = true;
                        clonedColumns[i].showEditFormFields = true;
                        clonedColumns[i].disableLinkText = false;
                    } else if (data.e.value === "reset-password") {
                        clonedColumns[i].showVisibleCountField = false;
                        clonedColumns[i].showAPIGatewayPathField = false;
                        clonedColumns[i].showEditFormFields = true;
                        clonedColumns[i].disableLinkText = false;
                    } else if (data.e.value === "delete-row" ||
                        data.e.value === "disable-row") {
                        clonedColumns[i].showVisibleCountField = false;
                        clonedColumns[i].showAPIGatewayPathField = true;
                        clonedColumns[i].showEditFormFields = false;
                        clonedColumns[i].disableLinkText = false;
                    } else if (data.e.value === "add-row") {
                        clonedColumns[i].showVisibleCountField = false;
                        clonedColumns[i].showAPIGatewayPathField = true;
                        clonedColumns[i].showEditFormFields = true;
                        clonedColumns[i].linkText = "Add Row";
                        clonedColumns[i].disableLinkText = true;
                    } else {
                        clonedColumns[i].showVisibleCountField = true;
                        clonedColumns[i].showAPIGatewayPathField = false;
                        clonedColumns[i].showEditFormFields = false;
                        clonedColumns[i].disableLinkText = false;
                    }
                }
            }
        }
        this.props.disablePreviewButton();

        this.setState({customColumns: clonedColumns}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    customColumnDeleteOnClick = (data) => {
        let that = this;
        let clonedColumns = LDH.DeepClone(this.state.customColumns);
        let filteredColumns = [];
        for (let i = 0; i < clonedColumns.length; i++) {
            if (clonedColumns[i].id !== data.id) {
                filteredColumns.push(clonedColumns[i]);
            }
        }
        this.props.disablePreviewButton();
        this.setState({customColumns: filteredColumns}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    generalAddColumnOnClick = () => {
        let that = this;
        if (this.state.customColumns.length > 7) {
            LRH.ShowToast("You can have a maximum of 8 links for this data view.", "error", 5000);
            return;
        }

        this.props.disablePreviewButton();
        this.setState({
            customColumns: [...this.state.customColumns, {
                id: LDH.GenerateGuid(),
                columnType: LDPH.DropdownSelectionColumnTypeSource[0].value,
                linkText: "",
                dataSourceId: "",
                showVisibleCountField: true,
                showEditFormFields: false,
                enableAdminUserOnly: true,
                showAPIGatewayPathField: false
            }]
        }, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    render() {
        let that = this;
        if (this.state.customColumns === null) return null;

        let result = this.state.customColumns.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.customColumns[i].linkText)
                                ? "Command Link " + (i + 1)
                                : that.state.customColumns[i].linkText}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.customColumnDeleteOnClick({
                                   e, id: that.state.customColumns[i].id
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"customColumnType_" + that.state.customColumns[i].id + "_help"}
                                    title={"Type of link"}
                                    text={"Specify a type of link for the data view."}/>
                                <span>Type of link:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionColumnTypeSource} displayExpr={'text'}
                                           valueExpr={'value'}
                                           value={that.state.customColumns[i].columnType}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e, id: that.state.customColumns[i].id, propertyName: "columnType"
                                           })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnLinkText_" + i + "_help"}
                                                        title={"Link text"}
                                                        text={"Specify a display text for the link."}/>
                                <span>Link text:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.customColumns[i].linkText} maxLength={20}
                                         onValueChanged={(e) => that.updateValueForCustomColumn({
                                             e, id: that.state.customColumns[i].id, propertyName: "linkText"
                                         })}
                                         disabled={
                                             !LDH.IsObjectNull(that.state.customColumns[i].disableLinkText) &&
                                             that.state.customColumns[i].disableLinkText ? true : false
                                         }/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnAvailableForAdminOnly_" + i + "_help"}
                                                        title={"Enable for admin users only"}
                                                        text={"Specify whether this feature is for admin users only."}/>
                                <span>Enable for admin users only:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo} displayExpr={'name'}
                                           valueExpr={'id'}
                                           value={that.state.customColumns[i].enableAdminUserOnly}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e, id: that.state.customColumns[i].id,
                                               propertyName: "enableAdminUserOnly"
                                           })}/>
                            </div>
                        </div>
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showAPIGatewayPathField) &&
                            !that.state.customColumns[i].showAPIGatewayPathField ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnAPIGatewayPath_" + i + "_help"}
                                                                title={"API gateway path"}
                                                                text={"Specify an API gateway path for this custom column."}/>
                                        <span>API gateway path:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.customColumns[i].apiGatewayPath} maxLength={255}
                                                 placeholder={"/users"}
                                                 onValueChanged={(e) => that.updateValueForCustomColumn({
                                                     e,
                                                     id: that.state.customColumns[i].id,
                                                     propertyName: "apiGatewayPath"
                                                 })}/>
                                    </div>
                                </div>
                        }
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnDataSourceId_" + i + "_help"}
                                                        title={"Data source ID"}
                                                        text={"Select a data source ID for this custom column."}/>
                                <span>Data source ID:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={that.props.columnFieldListForDropdown} displayExpr={'text'}
                                           valueExpr={'value'}
                                           value={that.state.customColumns[i].dataSourceId}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e, id: that.state.customColumns[i].id,
                                               propertyName: "dataSourceId"
                                           })}/>
                            </div>
                        </div>
                        {
                            !LDH.IsObjectNull(that.state.customColumns[i].showVisibleCountField) &&
                            !that.state.customColumns[i].showVisibleCountField ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnCountColumn_" + i + "_help"}
                                                                title={"Visible/Count field"}
                                                                text={"Select a visible/count field for this custom column. If it's a Count field, " +
                                                                "the link will be visible if the value is greater than 0. If it's a Visible field, " +
                                                                "the link will be visible based on the 'true' or 'false' value."}/>
                                        <span>Visible/Count field:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={that.props.columnFieldListForDropdown}
                                                   displayExpr={'text'}
                                                   valueExpr={'value'}
                                                   value={that.state.customColumns[i].countColumnName}
                                                   onValueChanged={(e) => that.updateValueForCustomColumn({
                                                       e, id: that.state.customColumns[i].id,
                                                       propertyName: "countColumnName"
                                                   })}/>
                                    </div>
                                </div>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showEditFormFields) ||
                            that.state.customColumns[i].showEditFormFields === false ? "" :
                                <React.Fragment>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"disabledFieldsForPopupEditor_" + i + "_help"}
                                                title={"Readonly fields"}
                                                text={"Specifies which columns on the grid view should be readonly when a user editing a data row."}/>
                                            <span>Readonly fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={that.props.columnFieldListForDropdown}
                                                fieldValue={that.state.customColumns[i].disabledFields}
                                                fieldType={"disabled-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => that.updateValueForCustomColumn({
                                                        e, id: that.state.customColumns[i].id,
                                                        propertyName: "disabledFields"
                                                    })
                                                }/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon
                                                elementId={"hiddenFieldsForPopupEditor_" + i + "_help"}
                                                title={"Invisible fields"}
                                                text={"Specifies which columns on the grid view should be invisible when a user editing a data row."}/>
                                            <span>Invisible fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={that.props.columnFieldListForDropdown}
                                                fieldValue={that.state.customColumns[i].hiddenFields}
                                                fieldType={"hidden-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => that.updateValueForCustomColumn({
                                                        e, id: that.state.customColumns[i].id,
                                                        propertyName: "hiddenFields"
                                                    })
                                                }/>
                                        </div>
                                    </div>
                                </React.Fragment>
                        }
                        {
                            LDH.IsObjectNull(that.state.customColumns[i].showEditFormFields) ||
                            that.state.customColumns[i].showEditFormFields === false ? "" :
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"customColumnGenericColumns_" + i + "_help"}
                                                                title={"Custom fields"}
                                                                text={"Specify custom fields for the Form Editor."}/>
                                        <span>Custom fields:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.customColumns[i].customFields} height={100}
                                                  className={"textbox_MiniButton"}
                                                  onValueChanged={(e) => that.updateValueForCustomColumn({
                                                      e, id: that.state.customColumns[i].id,
                                                      propertyName: "customFields"
                                                  })}>
                                            <TextBoxButton
                                                name={"customColumnGenericColumns_" + i + "_MiniButton"}
                                                location={'after'}
                                                options={{
                                                    icon: "fas fa-question",
                                                    type: 'default',
                                                    onClick: () => {
                                                        alert(
                                                            "<div style={{width: '100px'}}>[<br/>" +
                                                            "    &nbsp;&nbsp;&nbsp;{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"fieldName\": \"CustomField1\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"displayName\": \"Custom Field 1\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"fieldType\": \"string\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"readOnly\": true,<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"defaultValue\": \"\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"validationRules\": [{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"regex\": \"^[^0-9]+$\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"error\": \"Digits not allowed\"<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;},{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"regex\": \"^\\\\+\\\\s*1\\\\s*\\\\(\\\\s*[02-9]\\\\d{2}\\\\)\\\\s*\\\\d{3}\\\\s*-\\\\s*\\\\d{4}$\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"error\": \"Invalid USA phone format\"<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;},{<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"regex\": \"[required]\",<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\"error\": \"This field is required\"<br/>" +
                                                            "        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}]<br/>" +
                                                            "    &nbsp;&nbsp;&nbsp;}<br/>" +
                                                            "]</div>", "Example of custom fields")
                                                    }
                                                }}
                                            />
                                        </TextArea>
                                    </div>
                                </div>
                        }
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>General</span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.generalAddColumnOnClick(e)}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnOverallWidth_help"}
                                                        title={"Column width"}
                                                        text={"Specify the overall width for custom columns."}/>
                                <span>Column width:</span>
                            </div>
                            <div>
                                <NumberBox value={this.state.customColumnOverallWidth} showSpinButtons={true}
                                           min={10} max={9999} format={"#"}
                                           onValueChanged={(e) => this.customColumnOverallWidthOnChange(e)}/>
                            </div>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardCommandLinks;
