import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardChartEngine from '../foundation/LeopardChartEngine';
import LeopardChartDesigner from '../datashaping/LeopardChartDesigner';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardSecurity from "../security/LeopardSecurity";

class LeopardChartConfiguration extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false
        }
    }

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    chartDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeChartDesignerPanel() {
        return (
            <div className="leopard-chart-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardChartDesigner
                    dataViewId={this.props.dataViewId} definition={this.props.definition}
                    chartDesignerButtonOnClick={(e) => this.chartDesignerButtonOnClick(e)}
                    chartType={this.props.chartType}
                    chartDefinitionUpdateForPreview={this.props.chartDefinitionUpdateForPreview}>
                </LeopardChartDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar() {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"Chart_Admin_ConfigureChart_" + this.props.dataViewId}
                        labelText={"Configure Chart"} width={250} title={"Configure Chart"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.chartDesignerButtonOnClick({e})}
                        text={"This feature allows an admin user to configure the chart settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }
        let isExpanded = this.state.isExpandDesigner;
        return (
            <React.Fragment>
                {!(showGridViewAdminToolbar && !isExpanded) ? "" : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeChartDesignerPanel()}
                <LeopardChartEngine
                    dataSourceUrl={this.props.dataSourceUrl} palette={this.props.palette}
                    definition={this.props.definition}
                    dataInitializedOnControls={this.props.dataInitializedOnControls}
                    dataInitializedOnControlsUpdateRequest={this.props.dataInitializedOnControlsUpdateRequest}
                    setChartInstance={this.props.setChartInstance} useStateStore={false}
                    chartType={this.props.chartType} dataViewId={this.props.dataViewId}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                />
            </React.Fragment>
        );
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardChartConfiguration);
