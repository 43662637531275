import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import {connect} from "react-redux";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LDH from "../helpers/LeopardDataHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import $ from "jquery";
import momenttz from "moment-timezone";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardReportConfiguration extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.reportViewerInstance = null;
        this.reportDesignerInstance = null;
        this.reportLayout = null;
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    initializeReportWithDataset = (data, that, isLoadReportLayout) => {
        let report = new window.Stimulsoft.Report.StiReport();
        let dataSet = new window.Stimulsoft.System.Data.DataSet("ReportDataSet");
        let dataSetTimeZone = new window.Stimulsoft.System.Data.DataSet("ReportTimeZone");
        let timezones = LeopardDropdownHelper.DropdownSelectionTimeZone;

        if (LDH.IsObjectNull(data)) data = [];
        dataSet.readJson({data});
        dataSetTimeZone.readJson({timezones});

        report.dictionary.dataStore.clear();
        report.regData(dataSet.dataSetName, "", dataSet);
        report.regData(dataSetTimeZone.dataSetName, "", dataSetTimeZone);

        if (isLoadReportLayout && !LDH.IsObjectNull(that.reportLayout) &&
            !LDH.IsValueEmpty(that.reportLayout)) {
            report.load(that.reportLayout);

            let timezoneName = momenttz.tz.guess();
            if (!LDH.IsObjectNull(report.dictionary.variables.getByName("Input_TimezoneName")) &&
            LDH.IsValueEmpty(report.dictionary.variables.getByName("Input_TimezoneName").valueObject)){
                report.dictionary.variables.getByName("Input_TimezoneName").valueObject = timezoneName;
            }

            if (!LDH.IsObjectNull(report.dictionary.variables.getByName("Input_TimezoneOffset"))){
                let tz = momenttz().tz(timezoneName).format();
                let zone = momenttz.parseZone(tz);
                let offset = zone.utcOffset()/60;
                report.dictionary.variables.getByName("Input_TimezoneOffset").valueObject = offset;
            }

            let jsCode = that.props.reportDefinition.clientSideUILogicForReport;
            if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                jsCode = LDH.FilterMacro(jsCode);
                LRH.ExecuteClientScript(report, jsCode, "report", false);
            }

            let count = 0;
            let interval = setInterval(function () {
                $(".stiJsViewerFormButton.stiJsViewerFormButtonDefault").each(function () {
                    if ($("td.stiJsViewerClearAllStyles", $(this)).length > 0 &&
                        $("td.stiJsViewerClearAllStyles", $(this)).is(":visible") &&
                        $("td.stiJsViewerClearAllStyles", $(this)).text().trim() === "Reset") {
                        $(this).hide();
                        $(".stiJsViewerInnerParametersPanelSimple td").each(function(){
                            if ($(this).text().toString().trim().toLowerCase() === "input_timezoneoffset"){
                                $(this).hide();
                                $(this).next().hide();
                            }
                        });
                        clearInterval(interval);
                    }
                });
                if (count >= 300 && !LDH.IsObjectNull(interval)) {
                    clearInterval(interval);
                }
                count++;
            }, 10);
        }
        return {report: report, datasets: {dataSet, dataSetTimeZone}};
    };

    initializeReportViewerOnExit = (data, that) => {
        let result = that.initializeReportWithDataset(data, that, true);
        that.reportViewerInstance.report = result.report;
    };

    initializeReportViewerOnInteraction = (data, that, variables) => {
        let result = that.initializeReportWithDataset(data, that, true);
        that.reportViewerInstance.report = result.report;

        let dict = result.report.dictionary;
        let keys = Object.keys(variables);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = variables[keys[i]];
            let input = dict.variables.getByName(key);

            if (!LDH.IsObjectNull(input)) {
                input.valueObject = value;
            }
        }
    };

    initializeReportViewerOnDesign = (data, that, thisViewer) => {
        let result = that.initializeReportWithDataset(data, that, true);
        that.reportDesignerInstance.report = result.report;
        that.reportDesignerInstance.visible = true;
        thisViewer.visible = false;
    };

    initializeReportViewerOnCompDidMount = (data, that) => {
        if (!LDH.IsObjectNull(that.props.reportDefinition) &&
            !LDH.IsObjectNull(that.props.reportDefinition.reportLayout) &&
            !LDH.IsObjectNull(that.props.reportDefinition.reportLayout.ReportName)) {
            that.reportLayout = that.props.reportDefinition.reportLayout;
        }
        let result = that.initializeReportWithDataset(data, that, true);
        that.reportViewerInstance.report = result.report;
    };

    stiProgress = () => {
        try {
            let progressContainer = document.createElement("div");
            progressContainer.className = "leopard-report-loading-spinner";

            let progress = document.createElement("div");
            progressContainer.appendChild(progress);
            progress.className = "mobile_designer_loader";
            return progressContainer;
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    showProgress = (containerName) => {
        try {
            this.hideProgress();
            let rightPanel = document.getElementById(containerName);
            let progress = this.stiProgress();
            rightPanel.appendChild(progress);
            rightPanel.progress = progress;
            $(".leopard-report-loading-spinner-built-in").hide();
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    hideProgress = (containerName) => {
        try {
            let rightPanel = document.getElementById(containerName);
            if (rightPanel.progress) {
                rightPanel.removeChild(rightPanel.progress);
                rightPanel.progress = null;
            }
            $(".js_viewer_loader").each(function () {
                if ($(this).parent().hasClass("leopard-report-loading-spinner")) {
                    return;
                }
                $(this).parent().addClass("leopard-report-loading-spinner-built-in");
            });
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    createReportDesigner() {
        let that = this;
        let options = new window.Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportViewerAppearanceShowTooltipsHelp;
        options.appearance.fullScreenMode = true;
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportViewerToolbarShowAboutButton;
        options.toolbar.showFileMenuAbout = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuAbout;
        options.toolbar.showFileMenuHelp = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuHelp;
        options.toolbar.showFileMenuInfo = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuInfo;
        options.toolbar.showFileMenuExit = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuExit;

        this.reportDesignerInstance = new window.Stimulsoft.Designer.StiDesigner(
            options, "LeopardReportDesigner", false);

        this.reportDesignerInstance.onExit = function () {
            this.visible = false;
            that.reportViewerInstance.visible = true;

            let userProfile = window.userProfile;
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            that.createReportViewer();

            let reportDef = that.props.reportDefinition;

            let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForNormalReport) ?
                "" : "?" + reportDef.oDataParametersForNormalReport;

            parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
            parameters = LDH.FilterMacro(parameters);
            if (!LDH.IsObjectNull(reportDef.scriptShapingForReport) &&
                !LDH.IsValueEmpty(reportDef.scriptShapingForReport)) {
                eval(reportDef.scriptShapingForReport);
            }

            let tableNameWithParams = reportDef.tableName + parameters;
            if (reportDef.tableName === "custom-query") {
                parameters = parameters.replace("?", "");
                tableNameWithParams = parameters;
            }

            if (!LDH.IsValueEmpty(tableNameWithParams) && tableNameWithParams.indexOf("{") > -1 &&
                tableNameWithParams.indexOf("}") > -1) {
                that.initializeReportViewerOnExit(null, that);
                return;
            }

            that.showProgress("reportViewerContent");
            return LRH.SendAjaxRequestForCharts(window.ODataAPIGatewayUrl + "/" + tableNameWithParams,
                "get", "", function (data) {
                    let jsCode = reportDef.clientSideQueryForReport;
                    if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                        data = LRH.ExecuteClientScript(data, jsCode, "data", true);
                    }
                    that.initializeReportViewerOnExit(data, that);
                    that.hideProgress("reportViewerContent");
                }, function () {
                    that.initializeReportViewerOnExit(null, that);
                    that.hideProgress("reportViewerContent");
                }, null, null, null);
        };

        this.reportDesignerInstance.onSaveReport = function (args) {
            let jsonReport = args.report.saveToJsonString();
            let dataViewId = that.props.dataViewId;
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let reportDef = that.props.reportDefinition;

            reportDef.reportLayout = JSON.parse(jsonReport);
            that.reportLayout = jsonReport;

            LeopardAjaxHelper.UpdateDataViewDefinition(userId, organizationId, dataViewId, reportDef, function () {
                LRH.ShowToast("Your report has been successfully updated.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to update your report. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        };

        this.reportDesignerInstance.renderHtml("reportDesignerContent");
        LRH.TriggerWindowResizeEvent();
    }

    createReportViewer() {
        let that = this;
        let userProfile = window.userProfile;
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        let options = new window.Stimulsoft.Viewer.StiViewerOptions();
        options.appearance.interfaceType = window.Stimulsoft.Viewer.StiInterfaceType.Mouse;
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportDesignerAppearanceShowTooltipsHelp;

        if (this.props.state.permissions.AllowReportDesigner) {
            options.toolbar.showDesignButton = LeopardStaticUIConfig.ReportDesignerToolbarShowDesignButton;
        }
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportDesignerToolbarShowAboutButton;
        options.toolbar.showOpenButton = LeopardStaticUIConfig.ReportDesignerToolbarShowOpenButton;

        this.reportViewerInstance = new window.Stimulsoft.Viewer.StiViewer(
            options, "LeopardReportViewer", false);

        this.reportViewerInstance.onInteraction = function (args) {
            if (args.action.toLowerCase() === "variables") {
                window["report_input_variables"] = args;

                let reportDef = that.props.reportDefinition;
                let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForNormalReport) ?
                    "" : "?" + reportDef.oDataParametersForNormalReport;

                let keys = Object.keys(args.variables);
                let daylightSaving = 0;
                let timezone = null;
                let timezoneName = "";
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i].toLowerCase() === "input_timezone") {
                        timezone = args.variables[keys[i]];
                    }
                    if (keys[i].toLowerCase() === "input_daylightsaving") {
                        daylightSaving = args.variables[keys[i]];
                    }
                    if (keys[i].toLowerCase() === "input_timezonename") {
                        timezoneName = args.variables[keys[i]];
                    }
                }

                for (let i = 0; i < keys.length; i++) {
                    if (keys[i].toLowerCase() === "input_timezoneoffset" &&
                        !LDH.IsValueEmpty(timezoneName)) {
                        let tz = momenttz().tz(timezoneName).format();
                        let zone = momenttz.parseZone(tz);
                        let offset = zone.utcOffset() / 60;
                        args.variables[keys[i]] = offset;
                    }
                }

                window["cc_daylight_saving"] = daylightSaving;
                window["cc_timezone"] = timezone;
                window["cc_timezone_name"] = timezoneName;

                for (let i = 0; i < keys.length; i++) {
                    let replaceData = "";
                    parameters = LDH.ReplaceAll(parameters, "{" + keys[i] + "}", args.variables[keys[i]]);
                    replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-startdate\")}";

                    if (parameters.indexOf(replaceData) > -1) {
                        let dateVal = new Date(args.variables[keys[i]]);

                        if (LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                            let dateStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                            dateStr = dateStr + "T00:00:00.000";
                            dateStr = LDH.ConvertLocalDateToUTCDate(new Date(dateStr));
                            dateStr = LDH.GetLocalISODateString(dateStr);
                            parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                        }
                        else if (LDH.IsValueEmpty(timezone) && !LDH.IsValueEmpty(timezoneName)) {
                            let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                            let usDateStr = dateLocaleStr.split("-");
                            let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);

                            let tz = momenttz().tz(timezoneName).format();
                            let zone = momenttz.parseZone(tz);
                            let offset = zone.utcOffset();
                            let minutes = dateParse.getMinutes();
                            dateParse.setMinutes(minutes - offset);

                            let dateStr = LDH.GetLocalISODateString(dateParse);
                            parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                        }
                        else if (!LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                            let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                            let usDateStr = dateLocaleStr.split("-");
                            let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);
                            let offset = parseFloat(timezone) + parseFloat(daylightSaving);
                            let hours = dateParse.getHours();
                            dateParse.setHours(hours - offset);

                            let dateStr = LDH.GetLocalISODateString(dateParse);
                            parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                        }
                    }

                    replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-enddate\")}";
                    if (parameters.indexOf(replaceData) > -1) {
                        let dateVal = new Date(args.variables[keys[i]]);

                        if (LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                            let dateStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                            dateStr = dateStr + "T23:59:59.999";
                            dateStr = LDH.ConvertLocalDateToUTCDate(new Date(dateStr));
                            dateStr = LDH.GetLocalISODateString(dateStr);
                            parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                        }
                        else if (LDH.IsValueEmpty(timezone) && !LDH.IsValueEmpty(timezoneName)) {
                            let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                            let usDateStr = dateLocaleStr.split("-");
                            let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);

                            let tz = momenttz().tz(timezoneName).format();
                            let zone = momenttz.parseZone(tz);
                            let offset = zone.utcOffset();
                            let minutes = dateParse.getMinutes();
                            dateParse.setMinutes(minutes - offset);

                            let dateStr = LDH.GetLocalISODateString(dateParse);
                            parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                        }
                        else if (!LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                            let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                            let usDateStr = dateLocaleStr.split("-");
                            let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);
                            let offset = parseFloat(timezone) + parseFloat(daylightSaving);
                            let hours = dateParse.getHours();
                            dateParse.setHours(hours - offset);

                            let dateStr = LDH.GetLocalISODateString(dateParse);
                            parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                        }
                    }
                }

                parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
                parameters = LDH.FilterMacro(parameters);

                if (!LDH.IsObjectNull(reportDef.scriptShapingForReport) &&
                    !LDH.IsValueEmpty(reportDef.scriptShapingForReport)) {
                    eval(reportDef.scriptShapingForReport);
                }

                let tableNameWithParams = reportDef.tableName + parameters;
                if (reportDef.tableName === "custom-query") {
                    parameters = parameters.replace("?", "");
                    tableNameWithParams = parameters;
                }

                if (!LDH.IsValueEmpty(tableNameWithParams) && tableNameWithParams.indexOf("{") > -1 &&
                    tableNameWithParams.indexOf("}") > -1) {
                    that.initializeReportViewerOnInteraction(null, that, args.variables);
                    return;
                }

                that.showProgress("reportViewerContent");
                return LRH.SendAjaxRequestForCharts(window.ODataAPIGatewayUrl + "/" + tableNameWithParams,
                    "get", "", function (data) {
                        let jsCode = reportDef.clientSideQueryForReport;
                        if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                            data = LRH.ExecuteClientScript(data, jsCode, "data", true);
                        }
                        that.initializeReportViewerOnInteraction(data, that, args.variables);
                        that.hideProgress("reportViewerContent");
                    }, function () {
                        that.initializeReportViewerOnInteraction(null, that, args.variables);
                        that.hideProgress("reportViewerContent");
                    }, null, null, null);
            }
        };

        this.reportViewerInstance.onDesignReport = function () {
            that.createReportDesigner();

            let thisViewer = this;
            let reportDef = that.props.reportDefinition;
            let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForNormalReport) ?
                "" : "?" + reportDef.oDataParametersForNormalReport;

            let tableNameWithParams = that.executeScriptShaping(that, parameters, reportDef);
            if (!LDH.IsValueEmpty(tableNameWithParams) && tableNameWithParams.indexOf("{") > -1 &&
                tableNameWithParams.indexOf("}") > -1) {
                that.initializeReportViewerOnDesign(null, that, thisViewer);
                return;
            }

            that.showProgress("LeopardReportDesigner");
            return LRH.SendAjaxRequestForCharts(window.ODataAPIGatewayUrl + "/" +
                tableNameWithParams, "get", "", function (data) {
                    let jsCode = reportDef.clientSideQueryForReport;
                    if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                        data = LRH.ExecuteClientScript(data, jsCode, "data", true);
                    }
                    that.initializeReportViewerOnDesign(data, that, thisViewer);
                    that.hideProgress("LeopardReportDesigner");
                }, function () {
                    that.initializeReportViewerOnDesign(null, that, thisViewer);
                    that.hideProgress("LeopardReportDesigner");
                }, null, null, null);
        };

        this.reportViewerInstance.renderHtml("reportViewerContent");
    }

    executeScriptShaping = (thisComp, parameters, reportDef) =>{
        let userProfile = window.userProfile;
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
        parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
        parameters = LDH.FilterMacro(parameters);
        if (!LDH.IsObjectNull(reportDef.scriptShapingForReport) &&
            !LDH.IsValueEmpty(reportDef.scriptShapingForReport)) {
            eval(reportDef.scriptShapingForReport);
        }

        let tableNameWithParams = reportDef.tableName + parameters;
        if (reportDef.tableName === "custom-query") {
            parameters = parameters.replace("?", "");
            tableNameWithParams = parameters;
        }
        return tableNameWithParams;
    };

    componentWillUnmount() {
        window["report_input_variables"] = null;
    }

    componentDidMount() {
        let that = this;
        that.createReportViewer();

        let reportDef = that.props.reportDefinition;
        let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForNormalReport) ?
            "" : "?" + reportDef.oDataParametersForNormalReport;

        let tableNameWithParams = that.executeScriptShaping(that, parameters, reportDef);
        if (!LDH.IsValueEmpty(tableNameWithParams) && tableNameWithParams.indexOf("{") > -1 &&
            tableNameWithParams.indexOf("}") > -1) {
            that.initializeReportViewerOnCompDidMount(null, that);
            return;
        }

        that.showProgress("reportViewerContent");
        return LRH.SendAjaxRequestForCharts(window.ODataAPIGatewayUrl + "/" + tableNameWithParams,
            "get", "", function (data) {
                let jsCode = reportDef.clientSideQueryForReport;
                if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                    data = LRH.ExecuteClientScript(data, jsCode, "data", true);
                }
                that.initializeReportViewerOnCompDidMount(data, that);
                that.hideProgress("reportViewerContent");
            }, function () {
                that.initializeReportViewerOnCompDidMount(null, that);
                that.hideProgress("reportViewerContent");
            }, null, null, null);
    }

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                <div id={"reportViewerContent"}></div>
                <div id={"reportDesignerContent"}></div>
            </React.Fragment>
        );
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardReportConfiguration);
