import {Component} from 'react';
import $ from 'jquery';
import 'url-search-params-polyfill';
import LRH from './LeopardReactHelper';
import Auth, {API, Signer} from "aws-amplify";
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LDH from './LeopardDataHelper';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";

class LeopardAjaxHelper extends Component {
    static IsResponseContainsError = (response, callbackError) => {
        if (response === null || response === "null") {
            callbackError(response, false);
            return true;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.statusCode !== undefined &&
            response.message.statusCode === 400 &&
            response.message.error !== undefined && response.message.error !== null &&
            response.message.error.indexOf("Item not found") !== -1) {
            return false;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.statusCode !== undefined &&
            response.message.statusCode === 400) {
            callbackError(response, false);
            return true;
        } else if (response.errorMessage !== undefined && response.errorMessage !== null &&
            response.errorMessage !== "") {
            callbackError(response, false);
            return true;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.error !== undefined &&
            response.message.error !== "") {
            callbackError(response, false);
            return true;
        } else if (LeopardAjaxHelper.HandleSessionTimeoutEvent(response)) {
            callbackError(response, true);
            return true;
        }
        return false;
    };

    static HandleSessionTimeoutEvent = (error) => {
        try {
            let errorMessage = "";
            if (typeof error !== 'object' && !LDH.IsValueEmpty(error)) {
                errorMessage = error.toString().toLowerCase();
            } else if (error !== null && !LDH.IsObjectNull(error.message) &&
                !LDH.IsValueEmpty(error.message)) {
                errorMessage = error.message.toString().toLowerCase();
            }
            if (LDH.IsValueEmpty(errorMessage) === false && (
                errorMessage.indexOf("not authenticated") > -1 ||
                errorMessage.indexOf("security token") > -1 ||
                errorMessage.indexOf("request is expired") > -1 ||
                errorMessage.indexOf("null") > -1 ||
                errorMessage.indexOf("authentication Token") > -1 ||
                errorMessage.indexOf("is forbidden") > -1 ||
                errorMessage.indexOf("unauthenticated access") > -1)) {
                LeopardSecurity.UserLogout();
                return true;
            }
            return false;
        } catch (ex) {
            return false;
        }
    };

    static GenericRequestHeaders = () => {
        return {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
            "Expires": "Sat, 01 Jan 2000 00:00:00 GMT"
        };
    };

    static GetLeftMenuItems = (userId, callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_Main_LeftMenuItems";

                    if (profile.ParentGroup.Configs === undefined ||
                        profile.ParentGroup.Configs === null) {
                        profile.ParentGroup.Configs = [];
                    }

                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        config.sort(function (a, b) {
                            let orderA = LDH.IsValueEmpty(a.menuItemName) ? "" :
                                a.menuItemName.toString().toUpperCase();
                            let orderB = LDH.IsValueEmpty(b.menuItemName) ? "" :
                                b.menuItemName.toString().toUpperCase();

                            if (orderA < orderB) {
                                return -1;
                            }
                            if (orderA > orderB) {
                                return 1;
                            }
                            return 0;
                        });
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateLeftMenuItemForDashboard = (userId, organizationId, menuItemId, menuItemName,
                                             dashboardId, callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    menuItemsObj.push({
                        contentPageType: "dashboard-view",
                        menuItemName,
                        menuItemId,
                        dashboardId,
                        menuItemType: "dashboard",
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName: "Workspaces"
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuItemForDashboard = (userId, organizationId, menuItemName, dashboardId,
                                             callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }

                    let dashboardItem = null;
                    let menuItemsIndex = 0;
                    for (let i = 0; i < menuItemsObj.length; i++) {
                        if (menuItemsObj[i].menuItemType !== "dashboard") {
                            continue;
                        }
                        if (menuItemsObj[i].dashboardId === dashboardId) {
                            dashboardItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }

                    if (dashboardItem === null && callbackError !== undefined && callbackError !== null) {
                        callbackError({error: "Dashboard item not found"});
                    }
                    if (dashboardItem === null) return;

                    dashboardItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dashboardItem.menuItemName = menuItemName;
                    menuItemsObj[menuItemsIndex] = dashboardItem;
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(dashboardItem);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateLeftMenuItemForDataView = (userId, organizationId, menuItemId, menuItemName, menuItemType,
                                            dataViewType, dataViewNote, dataTableName, dataViewId,
                                            dataViewPersistentId, callbackSuccess, callbackError,
                                            userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let parentMenuItemName = "Data Views";
                    if (menuItemType === "system-view") {
                        parentMenuItemName = "System Views";
                    }
                    if (menuItemType === "default-view") {
                        parentMenuItemName = "Default Views";
                    }
                    if (menuItemType === "report") {
                        parentMenuItemName = "Reports";
                    }

                    menuItemsObj.push({
                        contentPageType: "single-data-view",
                        menuItemName,
                        menuItemId,
                        dataViewId,
                        dataTableName,
                        dataViewType,
                        dataViewNote,
                        menuItemType,
                        dataViewPersistentId,
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuItemForDataView = (userId, organizationId, menuItemName, dataViewId, dataViewPersistentId,
                                            callbackSuccess, callbackError, dataViewMenuCategory, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    let dataViewItem = null;
                    let menuItemsIndex = 0;
                    for (let i = 0; i < menuItemsObj.length; i++) {
                        if (!LDH.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                            menuItemsObj[i].menuItemType !== "data-view" &&
                            menuItemsObj[i].menuItemType !== "system-view" &&
                            menuItemsObj[i].menuItemType !== "default-view" &&
                            menuItemsObj[i].menuItemType !== "report") {
                            continue;
                        }

                        if (menuItemsObj[i].dataViewId === dataViewId) {
                            dataViewItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }
                    if (dataViewItem === null && callbackError !== undefined &&
                        callbackError !== null) {
                        callbackError({error: "Data view item not found"});
                    }
                    if (dataViewItem === null) return;

                    if (!LDH.IsValueEmpty(dataViewItem.menuItemType) &&
                        (dataViewItem.menuItemType === "data-view" ||
                            dataViewItem.menuItemType === "system-view" ||
                            dataViewItem.menuItemType === "default-view")) {
                        dataViewItem.menuItemType = dataViewMenuCategory;
                    }

                    dataViewItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dataViewItem.menuItemName = menuItemName;
                    dataViewItem.dataViewPersistentId = dataViewPersistentId;
                    menuItemsObj[menuItemsIndex] = dataViewItem;
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(dataViewItem);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteLeftMenuItemForDataView = (userId, organizationId, dataViewId, callbackSuccess,
                                            callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (let i = 0; i < menuItemsObj.length; i++) {
                            if (!LDH.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                                menuItemsObj[i].menuItemType !== "data-view" &&
                                menuItemsObj[i].menuItemType !== "system-view" &&
                                menuItemsObj[i].menuItemType !== "default-view" &&
                                menuItemsObj[i].menuItemType !== "report") {
                                continue;
                            }
                            if (menuItemsObj[i].dataViewId === dataViewId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                        API.put("aws-api-gateway", url, {
                            headers: LeopardAjaxHelper.GenericRequestHeaders(),
                            body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                        }).then(response => {
                            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                                return;
                            }
                            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                                callbackSuccess(response);
                            }
                        }).catch(error => {
                            if (callbackError !== undefined && callbackError !== null) {
                                let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                                callbackError(error, sessionTimeout);
                            }
                        });
                    }, function (error) {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    }, userProfileCache);
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteLeftMenuItemForDashboard = (userId, organizationId, dashboardId, callbackSuccess,
                                             callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (let i = 0; i < menuItemsObj.length; i++) {
                            if (menuItemsObj[i].menuItemType !== "dashboard") {
                                continue;
                            }
                            if (menuItemsObj[i].dashboardId === dashboardId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                        API.put("aws-api-gateway", url, {
                            headers: LeopardAjaxHelper.GenericRequestHeaders(),
                            body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                        }).then(response => {
                            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                                return;
                            }
                            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                                callbackSuccess(response);
                            }
                        }).catch(error => {
                            if (callbackError !== undefined && callbackError !== null) {
                                let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                                callbackError(error, sessionTimeout);
                            }
                        });
                    }, function (error) {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    }, userProfileCache);
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateDashboard = (organizationId, dashboardName, dashboardNote, dashboardId,
                              dataViewIds, dashboardLayout, relationships, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);

                let config = {
                    relationships,
                    dashboardName,
                    dashboardId,
                    dashboardLayout,
                    dashboardNote,
                    contentPageType: "dashboard-view",
                    dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                };

                API.put("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboard = (userId, organizationId, dashboardId, dashboardLayout, dashboardName,
                              callbackSuccess, callbackError, isResetRelationship, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    let dashboardLayoutFromDb = dashboard.dashboardLayout;

                    for (let i = 0; i < dashboardLayoutFromDb.length; i++) {
                        for (let j = 0; j < dashboardLayout.length; j++) {
                            if (dashboardLayoutFromDb[i].i === dashboardLayout[j].i) {
                                dashboardLayoutFromDb[i].definition = dashboardLayout[j].definition;
                                break;
                            }
                        }
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);
                    let relationships = isResetRelationship ? [] : dashboard.relationships;

                    let config = {
                        relationships,
                        dashboardName,
                        dashboardId: dashboard.dashboardId,
                        dashboardLayout: dashboardLayoutFromDb,
                        dashboardNote: dashboard.dashboardNote,
                        contentPageType: dashboard.contentPageType,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    };

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboardRelationship = (userId, organizationId, dashboardId, relationships,
                                          callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);
                    dashboard.relationships = relationships;

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(dashboard, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboardLayout = (userId, organizationId, dashboardId, dashboardLayout,
                                    callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    for (let i = 0; i < dashboard.dashboardLayout.length; i++) {
                        for (let j = 0; j < dashboardLayout.length; j++) {
                            if (dashboard.dashboardLayout[i].i === dashboardLayout[j].i) {
                                dashboardLayout[j].definition = dashboard.dashboardLayout[i].definition;
                                break;
                            }
                        }
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);

                    let config = {
                        relationships: dashboard.relationships,
                        dashboardName: dashboard.dashboardName,
                        dashboardId: dashboard.dashboardId,
                        dashboardLayout,
                        dashboardNote: dashboard.dashboardNote,
                        contentPageType: dashboard.contentPageType,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    };

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static SetControlCentreVersion = (organizationId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Version");
                let dataToSend = {currentVersion: LeopardStaticUIConfig.ControlCentreVersion};

                API.put("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(dataToSend, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateDataView = (organizationId, dataViewName, dataViewType, dataViewNote, dataSourceUrl,
                             dataViewId, dataViewPersistentId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                let dataToSend = {
                    dataSourceUrl,
                    dataViewName,
                    dataViewId,
                    dataViewType,
                    dataViewNote,
                    contentPageType: "single-data-view",
                    dataViewPersistentId,
                    dataViewConfigVersion: LeopardStaticUIConfig.DataViewConfigVersion
                };

                API.put("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(dataToSend, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDataViewDefinition = (userId, organizationId, dataViewId, definition,
                                       callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    config.dataViewNote = definition;
                    config.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDataView = (userId, organizationId, dataViewName, dataViewId, definition, callbackSuccess,
                             callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    config.dataViewName = dataViewName;
                    config.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;

                    if (config.dataViewType === "chart") {
                        let dataViewNote = config.dataViewNote;
                        dataViewNote.chartDefinition.clientSideQueryEditor =
                            definition.chartDefinition.clientSideQueryEditor;

                        dataViewNote.chartDefinition.dataViewPersistentId =
                            definition.chartDefinition.dataViewPersistentId;

                        dataViewNote.chartDefinition.dataViewCustomComments =
                            definition.chartDefinition.dataViewCustomComments;

                        config.dataViewNote = dataViewNote;
                    } else if (config.dataViewType === "photo") {
                        let dataViewNote = definition;
                        dataViewNote.photoDefinition.dataViewPersistentId =
                            definition.photoDefinition.dataViewPersistentId;

                        dataViewNote.photoDefinition.dataViewCustomComments =
                            definition.photoDefinition.dataViewCustomComments;

                        config.dataViewNote = dataViewNote;
                    } else if (config.dataViewType === "datagrid") {
                        let dataViewNote = config.dataViewNote;
                        dataViewNote.gridViewDefinition.clientSideQuery =
                            definition.gridViewDefinition.clientSideQuery;

                        dataViewNote.gridViewDefinition.dataViewPersistentId =
                            definition.gridViewDefinition.dataViewPersistentId;

                        dataViewNote.gridViewDefinition.dataViewCustomComments =
                            definition.gridViewDefinition.dataViewCustomComments;
                    } else if (config.dataViewType === "report") {
                        let dataViewNote = config.dataViewNote;
                        dataViewNote.reportName = dataViewName;
                        dataViewNote.tableName = definition.tableName;
                        dataViewNote.oDataParametersForNormalReport = definition.oDataParametersForNormalReport;
                        dataViewNote.oDataParametersForScheduledReport = definition.oDataParametersForScheduledReport;
                        dataViewNote.clientSideQueryForReport = definition.clientSideQueryForReport;
                        dataViewNote.clientSideUILogicForReport = definition.clientSideUILogicForReport;
                        dataViewNote.scriptShapingForReport = definition.scriptShapingForReport;
                        dataViewNote.cronExpression = definition.cronExpression;
                        dataViewNote.recipients = definition.recipients;
                        dataViewNote.reportFormat = "pdf";
                        dataViewNote.sender = LeopardStaticUIConfig.SourceEmailAddress;
                        dataViewNote.scheduledReportEnabled = definition.scheduledReportEnabled;
                        dataViewNote.timezone = definition.timezone;
                        dataViewNote.timezoneName = definition.timezoneName;
                        dataViewNote.daylightSaving = definition.daylightSaving;
                        dataViewNote.operation = !definition.scheduledReportEnabled ? "DELETE" : "MODIFY";
                        dataViewNote.dataViewCustomComments = definition.dataViewCustomComments;
                        config.dataViewNote = dataViewNote;
                    }

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDataViewDefinitionByType = (userId, organizationId, dataViewId, definition,
                                             callbackSuccess, callbackError, dataViewType) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }

                    let dataViewNoteObj = config.dataViewNote;
                    if (dataViewType === "gridview") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.gridViewDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.gridViewDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.gridViewDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.gridViewDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.gridViewDefinition = definition;
                    } else if (dataViewType === "chart") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.chartDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.chartDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.chartDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.chartDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.chartDefinition = definition;
                    }
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateGridViewStyle = (userId, organizationId, dataViewId, styleDefinition,
                                  callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.styleDefinition = styleDefinition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetUserProfile = (userId, callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                if (LDH.IsObjectNull(userProfileCache) === false) {
                    let versionData = [];
                    for (let i = 0; i < userProfileCache.ParentGroup.Configs.length; i++) {
                        if (userProfileCache.ParentGroup.Configs[i].Name === "CC_Version") {
                            versionData = userProfileCache.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (!LDH.IsControlCentreLatestVersion(versionData)) {
                        $(".leopard-application-loading-cover").empty().show();
                        $(".cc-update-notice-text").show();
                    }
                    callbackSuccess(userProfileCache);
                    return;
                }
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UserProfileUrlPath.replace("{userId}", userId);

                API.get("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        let msg = LDH.UnconvertJsonDataNullOrEmptyValues(response.message, false);

                        let versionData = [];
                        for (let i = 0; i < msg.ParentGroup.Configs.length; i++) {
                            if (msg.ParentGroup.Configs[i].Name === "CC_Version") {
                                versionData = msg.ParentGroup.Configs[i].AppConfig;
                                break;
                            }
                        }
                        if (!LDH.IsControlCentreLatestVersion(versionData)) {
                            $(".leopard-application-loading-cover").empty().show();
                            $(".cc-update-notice-text").show();
                        }
                        callbackSuccess(msg);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetAttachmentsByItemId = (itemId, allowErrorMessage, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                API.get('aws-api-gateway', window.AttachmentUrlPath + "/" + itemId, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (!LDH.IsObjectNull(response) && !LDH.IsObjectNull(response.message) &&
                        !LDH.IsObjectNull(response.message.statusCode) &&
                        response.message.statusCode === "404") {
                        response = {message: []};
                        if (allowErrorMessage === true) {
                            LRH.ShowToast("No attachment found on the server. " +
                                "The Reporting database might be out-of-sync with the Attachment service.", "error", 8000);
                        }
                    }
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }

                    response.message.sort(function (a, b) {
                        let orderA = LDH.IsValueEmpty(a.Order) ? "0" :
                            a.Order.toString().toUpperCase();

                        let orderB = LDH.IsValueEmpty(b.Order) ? "0" :
                            b.Order.toString().toUpperCase();

                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });

                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataSourceList = (menuItemType, callbackSuccess, callbackError) => {
        let url = window.ODataAPIGatewayUrl + "/$metadata&authentication=true";
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LRH.SendAjaxForRetriveXmlFromReportDB(url, "get", function (rawJSON) {
                    let response = [{
                        id: "custom-query",
                        name: "Custom Query",
                        group: "Other",
                        category: "data-table"
                    }];
                    for (let i = 0; i < rawJSON.elements[0].elements[0].elements.length; i++) {
                        let elemList = rawJSON.elements[0].elements[0].elements[i];
                        if (elemList.attributes.Namespace !== "OdataReportingService.Models" &&
                            elemList.attributes.Namespace !== "Leopard.Data.Models") {
                            continue;
                        }
                        for (let j = 0; j < elemList.elements.length; j++) {
                            if (LDH.IsObjectNull(elemList.elements[j].attributes) ||
                                LDH.IsObjectNull(elemList.elements[j].attributes.Name) ||
                                LDH.IsValueEmpty(elemList.elements[j].attributes.Name)) {
                                continue;
                            }
                            response.push({
                                id: elemList.elements[j].attributes.Name,
                                name: elemList.elements[j].attributes.Name,
                                group: "Reporting Services",
                                category: "data-table"
                            });
                        }
                    }

                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (callbackError !== undefined && callbackError !== null) {
                            callbackError(response);
                        }
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataViewById = (userId, dataViewId, callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetControlCentreVersion = (userId, callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === "CC_Version") {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDashboardById = (userId, dashboardId, callbackSuccess, callbackError, userProfileCache) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_Dashboard_" + dashboardId;
                    for (let i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                }, userProfileCache);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GridViewCRUD_InsertData = (apiUrlPath, jsonData, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let dataKeys = Object.keys(jsonData);
                for (let i = 0; i < dataKeys.length; i++) {
                    let index = dataKeys[i].indexOf("#");
                    if (index > -1) {
                        let newKeyName = dataKeys[i].substring(index + 3, dataKeys[i].length);
                        jsonData[newKeyName] = jsonData[dataKeys[i]];
                        delete jsonData[dataKeys[i]];
                    }
                }

                API.post('aws-api-gateway', apiUrlPath, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: {"userData": [jsonData]}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GridViewCRUD_UpdateData = (apiUrlPath, jsonData, keyId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let dataKeys = Object.keys(jsonData);
                for (let i = 0; i < dataKeys.length; i++) {
                    let index = dataKeys[i].indexOf("#");
                    if (index > -1) {
                        let newKeyName = dataKeys[i].substring(index + 3, dataKeys[i].length);
                        jsonData[newKeyName] = jsonData[dataKeys[i]];
                        delete jsonData[dataKeys[i]];
                    }
                }

                API.put('aws-api-gateway', apiUrlPath + "/" + keyId, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: jsonData
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GridViewCRUD_DeleteData = (apiUrlPath, keyId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                API.del('aws-api-gateway', apiUrlPath + "/" + keyId, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GridViewCRUD_ResetPassword = (jsonData, keyId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                API.put('aws-api-gateway', window.APIGatewayUsersUrl + "/" + keyId + "/password", {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: jsonData
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteDataView = (organizationId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                API.del('aws-api-gateway', url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteDashboard = (organizationId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);

                API.del('aws-api-gateway', url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataTableColumnsByDataSourceUrl = (
        tableName, limitDataColumns, callbackSuccess, callbackError, dataSourceCustomQueryTableSchema,
        customComputedColumns) => {
        let url = window.ODataAPIGatewayUrl + "/$metadata&authentication=true";
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LRH.SendAjaxForRetriveXmlFromReportDB(url, "get", function (rawJSON) {
                    let response = [];
                    for (let i = 0; i < rawJSON.elements[0].elements[0].elements.length; i++) {
                        let elemList = rawJSON.elements[0].elements[0].elements[i];
                        if (elemList.attributes.Namespace !== "OdataReportingService.Models" &&
                            elemList.attributes.Namespace !== "Leopard.Data.Models") {
                            continue;
                        }
                        let tableNameArray = [tableName];
                        if (LDH.IsValueEmpty(dataSourceCustomQueryTableSchema) === false) {
                            let schemas = dataSourceCustomQueryTableSchema.split("|");
                            tableNameArray = [];
                            for (let s = 0; s < schemas.length; s++) {
                                let schemaTableName = schemas[s].trim();
                                let schemaPrefix = "";
                                if (schemaTableName.indexOf("{") > -1 &&
                                    schemaTableName.indexOf("}") > -1) {
                                    let start = schemaTableName.indexOf("{");
                                    let end = schemaTableName.indexOf("}") + 1;
                                    schemaPrefix = schemaTableName.substring(start, end);
                                    schemaPrefix = schemaPrefix.replace("{", "");
                                    schemaPrefix = schemaPrefix.replace("}", "");

                                    let length = schemaTableName.length;
                                    schemaTableName = schemaTableName.substring(end, length);
                                }
                                tableNameArray.push({table: schemaTableName, prefix: schemaPrefix});
                            }
                        }

                        for (let k = 0; k < tableNameArray.length; k++) {
                            let tableNameInSchema = tableName;
                            let prefixName = "";
                            if (LDH.IsObjectNull(tableNameArray[k].table) === false &&
                                LDH.IsValueEmpty(tableNameArray[k].table) === false) {
                                tableNameInSchema = tableNameArray[k].table.trim();
                                prefixName = tableNameArray[k].prefix.trim();
                            }

                            for (let j = 0; j < elemList.elements.length; j++) {
                                if (elemList.elements[j].attributes.Name !== tableNameInSchema) {
                                    continue;
                                }
                                for (let k = 0; k < elemList.elements[j].elements.length; k++) {
                                    let propertyElem = elemList.elements[j].elements[k];
                                    let attrName = "";
                                    if (propertyElem.name === "Key") {
                                        attrName = propertyElem.elements[0].attributes.Name;
                                    }
                                    if (propertyElem.name === "Property") {
                                        attrName = propertyElem.attributes.Name;
                                    }
                                    if (LDH.IsValueEmpty(attrName)) {
                                        continue;
                                    }
                                    let foundElem = false;
                                    for (let n = 0; n < response.length; n++) {
                                        let attrLower = attrName.toLowerCase();
                                        if (response[n].toLowerCase() === attrLower) {
                                            foundElem = true;
                                            break;
                                        }
                                    }
                                    if (foundElem === false) {
                                        attrName = prefixName === "" ? attrName : prefixName + "#" + attrName;
                                        response.push(attrName);
                                    }
                                }
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(customComputedColumns) && customComputedColumns.length > 0) {
                        for (let k = 0; k < customComputedColumns.length; k++) {
                            response.push(customComputedColumns[k].name);
                        }
                    }

                    let newData = [];
                    if (limitDataColumns !== undefined && limitDataColumns !== null &&
                        limitDataColumns.length > 0) {
                        for (let c = 0; c < response.length; c++) {
                            let found = false;
                            for (let v = 0; v < limitDataColumns.length; v++) {
                                if (limitDataColumns[v] === response[c]) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found === false) {
                                newData.push(response[c]);
                            }
                        }
                    } else {
                        newData = response;
                    }

                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (callbackError !== undefined && callbackError !== null) {
                            callbackError({fullColumns: response, limitedColumns: newData}, false);
                        }
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess({fullColumns: response, limitedColumns: newData});
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static AddSecurityHeadersToAjaxRequestForOdata() {
        XMLHttpRequest.prototype.open = (function (open) {
            return function (method, url, async) {
                try {
                    if (!LDH.IsObjectNull(method) && !LDH.IsObjectNull(url) &&
                        url.toString().toLowerCase().indexOf("authentication=true") > -1) {
                        let resultUrl = LDH.FilterMacro(url);
                        let resultUrlSplit = resultUrl.split("?");

                        if (resultUrlSplit.length > 1) {
                            let urlParams = resultUrlSplit[1];
                            let params = new URLSearchParams("?" + urlParams);
                            let newUrlParams = "";
                            let paramCount = 0;
                            for (let key of params.keys()) {
                                let dkey = key;
                                let dvalue = encodeURIComponent(params.get(key));

                                let symbol = "";
                                if (paramCount > 0 && dkey.toString().indexOf("$") === 0) {
                                    symbol = "&";
                                }
                                dkey = encodeURIComponent(dkey);
                                newUrlParams += symbol + dkey + "=" + dvalue;
                                paramCount++;
                            }
                            let finalUrl = resultUrlSplit[0] + "?" + newUrlParams;
                            let argumentUrl = finalUrl.replace("?authentication=true", "");
                            argumentUrl = argumentUrl.replace("&authentication=true", "");
                            argumentUrl = argumentUrl.replace("authentication=true", "");
                            arguments[1] = argumentUrl;
                            window.httpRequestCache = {method, url: finalUrl};
                        } else {
                            let finalUrl2 = resultUrlSplit[0];
                            let argumentUrl2 = finalUrl2.replace("?authentication=true", "");
                            argumentUrl2 = argumentUrl2.replace("&authentication=true", "");
                            argumentUrl2 = argumentUrl2.replace("authentication=true", "");
                            arguments[1] = argumentUrl2;
                            window.httpRequestCache = {method, url: finalUrl2};
                        }
                    }
                    open.apply(this, arguments);
                } catch (ex) {

                }
            };
        })(XMLHttpRequest.prototype.open);

        let send = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function (data) {
            try {
                if (!LDH.IsObjectNull(window.userCredential) && !LDH.IsObjectNull(window.httpRequestCache) &&
                    window.httpRequestCache.url.toLowerCase().indexOf("authentication=true")) {
                    let argumentUrl = window.httpRequestCache.url.replace("?authentication=true", "");
                    argumentUrl = argumentUrl.replace("&authentication=true", "");
                    argumentUrl = argumentUrl.replace("authentication=true", "");

                    let cred = {
                        accessKeyId: window.userCredential.accessKeyId,
                        region: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
                        secretAccessKey: window.userCredential.secretAccessKey,
                        token: window.userCredential.sessionToken,
                        url: argumentUrl,
                        method: window.httpRequestCache.method.toUpperCase()
                    };
                    window.httpRequestCache = null;

                    let signedByAws = Signer.sign({
                        method: cred.method,
                        url: cred.url,
                        data: {},
                        headers: LeopardAjaxHelper.GenericRequestHeaders()
                    }, {
                        access_key: cred.accessKeyId,
                        secret_key: cred.secretAccessKey,
                        session_token: cred.token
                    }, {
                        service: "execute-api",
                        region: cred.region
                    });

                    this.setRequestHeader('Content-Type', "application/json");
                    this.setRequestHeader('Cache-Control', "no-cache");
                    this.setRequestHeader('Pragma', "no-cache");
                    this.setRequestHeader('Expires', "Sat, 01 Jan 2000 00:00:00 GMT");

                    if (!LDH.IsObjectNull(signedByAws.headers["Authorization"]) &&
                        !LDH.IsValueEmpty(signedByAws.headers["Authorization"])) {
                        window.awsAuthorization = signedByAws.headers["Authorization"];
                    } else {
                        console.log("Header value Authorization is blank.");
                    }

                    if (!LDH.IsObjectNull(signedByAws.headers["x-amz-date"]) &&
                        !LDH.IsValueEmpty(signedByAws.headers["x-amz-date"])) {
                        window.awsAmzDate = signedByAws.headers["x-amz-date"];
                    } else {
                        console.log("Header value x-amz-date is blank.");
                    }

                    if (!LDH.IsObjectNull(signedByAws.headers["X-Amz-Security-Token"]) &&
                        !LDH.IsValueEmpty(signedByAws.headers["X-Amz-Security-Token"])) {
                        window.awsSecurityToken = signedByAws.headers["X-Amz-Security-Token"];
                    } else {
                        console.log("Header value X-Amz-Security-Token is blank.");
                    }

                    // Use the previous value if the header does not contain data.
                    // Use the latest value if the header contains data.
                    this.setRequestHeader('Authorization', window.awsAuthorization);
                    this.setRequestHeader('x-amz-date', window.awsAmzDate);
                    this.setRequestHeader('X-Amz-Security-Token', window.awsSecurityToken);
                }
                send.call(this, data);
            } catch (ex) {

            }
        };
    }
}

export default LeopardAjaxHelper;
